import React from 'react';
import ForgetPasswordCard from '../../components/ForgetPasswordCard';
import "./forgetpasswordPage.scss";
const ForgetPassword = () => {
  return (
    <div className="forgetpassword-page">
      <img
        src="assets/icons/mcyclo-logo.png"
        width="auto"
        height={30}
        alt="brand"
      />
      <ForgetPasswordCard/>
    </div>
  );
};

export default ForgetPassword;