import { postMethod, putMethod, getMethod } from "../Network/NetworkManager";
import {
  AUTH_USER,
  CHANGE_PASSWORD,
  RENEW_PASSWORD,
  LOGOUT,
  VERIFY_USER,
  SEND_FORGET_PASSWORD_CODE,
  REGISTER_USER,
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  GET_TRANSLATIONS,
  UPDATE_LOGISTIC_PROFILE,
} from "../constants/ApiUrls";
import { environment } from "../Environments/Environment";
export const registerUser = async (requestPayload) => {
  let requestStatus = await postMethod(REGISTER_USER, requestPayload);
  return requestStatus;
};
export const authenticateUser = async (requestPayload) => {
  let requestStatus = await postMethod(AUTH_USER, requestPayload);
  return requestStatus;
};
export const changePassword = async (requestPayload) => {
  let requestStatus = await postMethod(CHANGE_PASSWORD, requestPayload);
  return requestStatus;
};
export const renewPassword = async (requestPayload) => {
  let requestStatus = await postMethod(RENEW_PASSWORD, requestPayload);
  return requestStatus;
};
export const logout = async (requestPayload) => {
  let requestStatus = await postMethod(LOGOUT, requestPayload);
  return requestStatus;
};
export const verifyUserAccount = async (requestPayload) => {
  let requestStatus = await postMethod(VERIFY_USER, requestPayload);
  return requestStatus;
};
export const sendForgetPasswordCode = async (requestPayload) => {
  let requestStatus = await postMethod(
    SEND_FORGET_PASSWORD_CODE,
    requestPayload
  );
  return requestStatus;
};

export const getUserProfile = async (requestPayload) => {
  let data;
  let result = await getMethod(environment.BASE_URL + GET_USER_PROFILE);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};

export const updateUserProfile = async (requestPayload) => {
  let data;
  let result = await putMethod(UPDATE_USER_PROFILE, requestPayload);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};
export const getTranslations = async (language) => {
  let data;
  let result = await getMethod(
    environment.BASE_URL + GET_TRANSLATIONS + "/" + language
  );
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};

export const updateLogisticProfile = async (id, requestPayload) => {
  let data;
  let URL = `${UPDATE_LOGISTIC_PROFILE}/${id}`;
  let result = await putMethod(URL, requestPayload);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};

// export const changePassword = async (requestPayload) => {
//   let requestStatus = await putMethod(CHANGE_PASSWORD, requestPayload);

//   return requestStatus;
// };
// export const forgetPassword = async (requestPayload) => {
//   let requestStatus = await putMethod(FORGET_PASSWORD, requestPayload);

//   return requestStatus;
// };

// export const verifyPassword = async (requestPayload) => {
//   let requestStatus = await postMethod(VERIFY_PASSWORD, requestPayload);
//   return requestStatus;
// };

// export const updateProfile = async (requestPayload) => {
//   let requestStatus = await putMethod(UPDATE_PROFILE, requestPayload);

//   return requestStatus;
// };

// export const getProfile = async (requestPayload) => {
//   let data;
//   let result = await getMethod(environment.BASE_URL + GET_PROFILE);
//   if (result.status === 200) {
//     data = result.data;
//   } else {
//     data = {
//       error: true,
//       status: result.status,
//       statusText: result.data?.message,
//     };
//   }

//   return data;
// };
