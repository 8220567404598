import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import Homepage from "./pages/homepage/Homepage";
import LogInPage from "./pages/login/LogInPage";
import RegisterPage from "./pages/register/RegisterPage";
import JobsPage from "./pages/jobs/JobsPage";
import JobsCollectionPage from "./pages/jobscollection/JobsCollectionPage";
import ForgetPassword from "./pages/forgetpassword/ForgetPassword";
import ResetPassword from "./pages/resetpassword/ResetPassword";
import Profile from "./components/Profile";
import Redirection from "./pages/forgetpassword/Redirection";
import LinkExpired from "./pages/forgetpassword/LinkExired";
// import { RequireAuth } from "./constants/middlewares/RequireAuth";
import PrivateRoute from "./common/utils/PrivateRoutes";
import Imprint from "./pages/footerlinks/Imprint";
import TermsOfUse from "./pages/footerlinks/TermsOfUse";
import Policy from "./pages/footerlinks/Policy";
import LogisticProfilePage from "./pages/logisticProfile/LogisticProfilePage";
function App() {
  const roles = {
    Distributor: "Distributor",
    User: "Construction Site",
    Logistics: "Logistic",
  };

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/LogisticProfilePage" element={<LogisticProfilePage />} />
        <Route path="/login" element={<LogInPage />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/create-account" element={<RegisterPage />} />
        <Route path="/linkexpired" element={<LinkExpired />} />
        <Route path="/key/:id" element={<Redirection />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/termsofuse" element={<TermsOfUse />} />
        <Route path="/privatepolicy" element={<Policy />} />
        <Route
          path="/jobs"
          element={
            <PrivateRoute role={roles.Logistics} path="/jobs">
              <JobsPage />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/collection"
          element={
            <PrivateRoute role={roles.Distributor} path="/collection">
              <JobsCollectionPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute role={roles.User} path="/profile">
              <Profile />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
