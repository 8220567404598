import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import "./components.scss";
import { Logistic_User_Role } from "../constants/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faEnvelope, faCog } from "@fortawesome/free-solid-svg-icons";
import { Navbar } from "react-bootstrap";
import { logout } from "../services/AuthService";
import { getTranslatedMessage } from "../common/utils/Utilities";
import { useTranslation } from "react-i18next";
import GearIcon from "../assets/icons/GearIcon.png";
const NavHome = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [buttonLoading, setButtonLoading] = useState(false);
  const handleLogout = async () => {
    let result = await logout({
      userId: localStorage.getItem("userId"),
      refreshToken: localStorage.getItem("refreshToken"),
    });
    if (result?.success) {
      toast.success(getTranslatedMessage(result.message));
      localStorage.clear();
      setTimeout(() => {
        window.location = "/";
      }, 2500);
    } else {
      setButtonLoading(false);
    }
  };

  const handleCompanyNavigate = () => {
    let role = localStorage.getItem("role");
    if (role) {
      if (role === "Distributor") {
        navigate("/collection");
      } else if (role === "Logistic") {
        navigate("/jobs");
      } else if (role === "Construction Site") {
        navigate("/profile");
      }
    }
  };
  const handleSubmit = async () => {
    let result = await logout({
      userId: localStorage.getItem("userId"),
      refreshToken: localStorage.getItem("refreshToken"),
    });
    if (result?.data?.success) {
      toast.success(getTranslatedMessage(result?.data?.message));
    }
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <div className="sticky Navhome">
        <Navbar className="custom-navbar">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className="navbar p-0">
              <div className="left">
                <div className="home d-flex">
                  <FontAwesomeIcon
                    style={{ width: "30px", height: "30px", cursor: "pointer" }}
                    icon={faHouse}
                    onClick={() => (window.location = "/")}
                  />
                  {localStorage.getItem("role") === Logistic_User_Role ? (
                    <img
                      src={GearIcon}
                      style={{
                        height: "30px",
                        marginLeft: "15px",
                        marginTop: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() => (window.location = "/logisticProfilePage")}
                    />
                  ) : null}
                </div>

                <div className="settings"></div>
                <div className="list d-md-flex d-none">
                  {JSON.parse(localStorage.getItem("menuStructure"))?.map(
                    (item, index) => {
                      return (
                        <Link
                          key={index}
                          to={item.value}
                          className={`${
                            navigate.location?.pathname?.includes(item.value) &&
                            "active"
                          } links`}
                        >
                          {item.name}
                        </Link>
                      );
                    }
                  )}
                </div>
                <a
                  className="d-md-flex d-none"
                  href="mailto:mcyclo@basf.com"
                  style={{ fontWeight: "bold" }}
                >
                  mcyclo@basf.com
                </a>
              </div>
              <div className="right nav-left2">
                <img src={"/assets/icons/user.png"} />
                <p
                  className="nav-btn"
                  style={{
                    margin: "0",
                    borderRight: "2px solid white",
                    paddingRight: "3px",
                  }}
                >
                  {localStorage.getItem("companyName") ? (
                    <span
                      className="user-title"
                      style={{
                        cursor: `${
                          location.pathname === "/" ? "pointer" : "default"
                        }`,
                      }}
                      onClick={handleCompanyNavigate}
                    >
                      {localStorage.getItem("companyName")}
                    </span>
                  ) : (
                    <Link to="/create-account">
                      <button
                        className="nav-btn"
                        style={{ paddingLeft: "0.5px" }}
                      >
                        {t("register-nav-button-label")}
                      </button>
                    </Link>
                  )}
                </p>
                <span>
                  {!localStorage.getItem("userId") ? (
                    <Link to="/login">
                      <button className="nav-btn">
                        {t("login-nav-button-label")}
                      </button>
                    </Link>
                  ) : buttonLoading && localStorage.getItem("userId") ? (
                    <button
                      onClick={handleLogout}
                      disabled
                      className="disable-btn nav-btn"
                    >
                      {t("logout")}
                    </button>
                  ) : (
                    <button className="nav-btn" onClick={handleSubmit}>
                      {t("logout")}
                    </button>
                  )}
                </span>
              </div>
            </div>
          </Navbar.Collapse>
        </Navbar>
        <div className="logo">
          <img
            src={`assets/icons/mcyclo-logo.png`}
            width="auto"
            height={30}
            alt="brand"
          />
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default NavHome;
