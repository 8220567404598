import React, { useEffect, useState } from "react";
const MaterialDropDown = (props) => {
  const { width, list, onClickFun, prompt, value } = props;
  const [open, setOpen] = useState(false);
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    const arr = new Array(4);
    const groupList = list.reduce((acc, { group, name }) => {
      if (!acc[group]) acc[group] = [];
      acc[group].push(name);
      return acc;
    }, {});
    for (let elem in groupList) {
      let obj = {
        name: elem,
        items: groupList[elem],
      };
      if (elem === "Unbekannt") {
        arr[0] = obj;
      }
      if (elem === "Perimeterdämmung") {
        arr[1] = obj;
      }
      if (elem === "Flachdachdämmung") {
        arr[2] = obj;
      }
      if (elem === "Wärmebrückendämmung") {
        arr[3] = obj;
      }
      if (elem === "Sonstiges") {
        arr[4] = obj;
      }
      
    }
    setGroupList(arr);
  }, []);

  const handleOption = (ele) => {
    onClickFun(ele);
    setOpen(false);
  };
  const groupOption = () => {
    return groupList?.map((elem) => {
      return (
        <>
          <div
            style={{
              fontSize: "Max(12px, 0.8vw)",
              paddingTop: "1rem",
              paddingLeft: "0.5rem",
              fontWeight: "bold",
              color: "#bababa",
            }}
          >
            {elem.name}
          </div>
          {elem.items?.map((ele, index) => {
            return (
              <div
                className="options"
                key={index}
                onClick={() => {
                  handleOption(ele);
                }}
              >
                <p className="m-0">{ele}</p>
              </div>
            );
          })}
        </>
      );
    });
  };
  return (
    <div className={"custom-dropdown-1 d-flex position-relative"}>
      <div
        className="position-relative"
        style={{
          width: width ? width : "75%",
        }}
      >
        <div
          className="dropdown-control"
          onClick={() => setOpen((prev) => !prev)}
        >
          <div className="selected-value">
            <p className="m-0">{value ? value : prompt}</p>
          </div>
        </div>
        <div className={`${open ? "d-block" : "d-none"} option-container`}>
          {groupOption()}
        </div>
      </div>
    </div>
  );
};

export default MaterialDropDown;
