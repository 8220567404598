import React from "react";
import LogisticProfile from "../../components/LogisticProfile";
import NavHome from "../../components/Navbar";
const LogisticProfilePage = () => {
  return (
    <div>
      <NavHome />
      <LogisticProfile />
    </div>
  );
};

export default LogisticProfilePage;
