import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SubmitButton } from "../common/Button.style";
import { TextInputGroup } from "../common/InputGroup.style";
import { updateUserProfile } from "../services/AuthService";
import "./components.scss";
import Spinner from "react-bootstrap/Spinner";

import { toast, Toaster } from "react-hot-toast";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getTranslatedMessage } from "../common/utils/Utilities";
const EditProfile = ({ userProfileInfo }) => {
  const [btn_loading, setbtn_loading] = useState(false);
  const { t } = useTranslation();

  const updateProfile = async (payload) => {
    const result = await updateUserProfile(payload);
    if (result?.errors === null) {
      //toast.success(result?.message);
      toast.success(getTranslatedMessage(result?.message));
      setTimeout(() => {
        window.location = "/profile";
      }, 2500);
    }
    setbtn_loading(false);
  };
  const handleSubmit = (values) => {
    setbtn_loading(true);
    const payload = {
      name: values.firstName,
      surname: values.lastName,
      email: values.email,
      roleID: userProfileInfo.role.roleId,
      companyID: userProfileInfo.companyId,
    };
    const formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key]);
    }
    updateProfile(formData);
  };
  const containsNumber = (str) => {
    return /\d/.test(str);
  };
  const containSpecialChar = (str) => {
    return /[-!$%^&*()£_+|~=`{}\[\]:\/;<>?,.@#]/.test(str);
  };

  const formik = useFormik({
    initialValues: {
      firstName: userProfileInfo?.name,
      lastName: userProfileInfo?.surname,
      email: userProfileInfo?.email,
      companyName: userProfileInfo?.company?.name,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .label("First Name")
        .required(t(t("required-err")))
        .matches(/[A-Za-zÄäÆæß ]+/, t("ft-name-field-err"))
        .test(
          "Firstname",
          t("invalid-ft-name"),
          (value) => !containsNumber(value) && !containSpecialChar(value)
        ),
      lastName: Yup.string()
        .label("Last Name")
        .required(t(t("required-err")))
        .matches(/[A-Za-zÄäÆæß ]+/, t("lt-name-field-err"))
        .test(
          "Lastname",
          t("invalid-lt-name"),
          (value) => !containsNumber(value) && !containSpecialChar(value)
        ),
      email: Yup.string().email().label("Email").required(t("required-err")),
      // companyName: Yup.string().label("Company Name").required(t('required-err')).matches(/[A-Za-zÄäÆæß ]+/, "Must be alphatbates."),
    }),
    enableReinitialize: true,
    onSubmit: () => handleSubmit(formik.values),
  });
  return (
    <div className="edit-profile-container">
      <Toaster position="top-center" />
      <form onSubmit={formik.handleSubmit} className="login-form">
        <div className=" edit-section d-flex flex-column justify-content-center container-lg">
          <TextInputGroup
            width="100%"
            className="input-group"
            label={t("first-name")}
            labelWeight="500"
            id="firstName"
            maxLength="15"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            onBlur={formik.handleBlur}
            error={
              formik.errors.firstName && formik.touched.firstName ? true : false
            }
          />
          {formik.errors.firstName && formik.touched.firstName ? (
            <div className="Error-message align-self-start">
              {formik.errors.firstName}{" "}
            </div>
          ) : null}
          <TextInputGroup
            width="100%"
            className="input-group"
            label={t("last-name")}
            labelWeight="500"
            maxLength="15"
            id="lastName"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            onBlur={formik.handleBlur}
            error={
              formik.errors.lastName && formik.touched.lastName ? true : false
            }
          />
          {formik.errors.lastName && formik.touched.lastName ? (
            <div className="Error-message align-self-start">
              {formik.errors.lastName}{" "}
            </div>
          ) : null}
          <TextInputGroup
            width="100%"
            className="input-group"
            label={t("email")}
            labelWeight="500"
            id="email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            disabled
          />
          {formik.errors.email && formik.touched.email ? (
            <div className="Error-message align-self-start">
              {formik.errors.email}{" "}
            </div>
          ) : null}
          <TextInputGroup
            width="100%"
            className="input-group"
            label={t("company-name")}
            labelWeight="500"
            id="companyName"
            name="companyName"
            onChange={formik.handleChange}
            value={formik.values.companyName}
            onBlur={formik.handleBlur}
            disabled
          />
          {/* ............................ */}
          {/* {formik.errors.companyName ? (
            <div className="Error-message align-self-start">
              {formik.errors.companyName}{" "}
            </div>
          ) : null} */}
          {/* ............................ */}
        </div>
        <div className="d-flex justify-content-center">
          <div className="submit-btn-container">
            {!btn_loading ? (
              <SubmitButton
                className="submit-btn"
                padding="0.5rem 1rem"
                onclick={handleSubmit}
              >
                {t("save-changes-button-label")}
              </SubmitButton>
            ) : (
              <SubmitButton
                disabled
                className="submit-btn"
                padding="0.5rem 1rem"
              >
                <Spinner animation="border" />
              </SubmitButton>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
EditProfile.propTypes = {
  userProfileInfo: PropTypes.any,
};
export default EditProfile;
