import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DeactiveButton, SubmitButton } from "../common/Button.style";
import { TextInputGroup } from "../common/InputGroup.style";
import "./components.scss";
import { sendForgetPasswordCode } from "../services/AuthService";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
// import { values } from "ramda";
const ForgetPasswordCard = () => {
  const [btn_loading, setbtn_loading] = useState(false);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [error, seterror] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("invalid-email"))
        .label("Email")
        .required(t("password-rq")),
    }),
    onSubmit: async (values) => {
      setbtn_loading(true);
      let result = await sendForgetPasswordCode(values);
      if (result.status === 200) {
        setShow(true);
      } else {
        seterror(true);
      }
      setbtn_loading(false);
    },
  });
  return (
    <>
      {!show ? (
        <div className="forgotpassword-container">
          <h5>{t("login-forgot-password-heading")}</h5>
          <form
            onSubmit={formik.handleSubmit}
            className="forgotpassword-form d-flex flex-column align-items-center"
          >
            <div>
              <TextInputGroup
                className="input-group"
                label="Email"
                labelWeight="500"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onKeyDown={(e) => e.keyCode === 8 && seterror("")}
                value={formik.values.email}
              />
              {formik.errors.email ? (
                <div className="Error-message">{formik.errors.email} </div>
              ) : null}
              {error && (
                <div className="Error-message">{t("Email-not-Registered")}</div>
              )}
            </div>
            {formik.values.email && !formik.errors.email ? (
              <div className="submit-btn mt-4">
                {!btn_loading ? (
                  <SubmitButton color={"#fff"} padding="0.5rem 2.5rem">
                    {t("continue-button-label")}
                  </SubmitButton>
                ) : (
                  <SubmitButton color={"#fff"} padding="0.5rem 4rem">
                    <Spinner animation="border" />
                  </SubmitButton>
                )}
              </div>
            ) : (
              <div className="submit-btn mt-4">
                <DeactiveButton color={"#fff"} padding="0.5rem 2.5rem">
                  {t("continue-button-label")}
                </DeactiveButton>
              </div>
            )}
          </form>
        </div>
      ) : (
        <div className="forgotpassword-container mw-50">
          <h5>{t("login-forgot-password-heading")}</h5>
          <p className="fs-5">
            {t("forget-password-msg1") +
              " " +
              formik.values.email +
              " " +
              t("forget-password-msg2")}
          </p>
        </div>
      )}
    </>
  );
};
export default ForgetPasswordCard;
