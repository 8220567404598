import React from "react";
import { Modal } from "react-bootstrap";
import CrossIcon from "../../assets/icons/CrossIcon";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const MaterialListModal = (props) => {
  const { show, itemsList, setShow, handleAddItem } = props;
  const { t } = useTranslation();

  return (
    <Modal
      contentClassName="custom-list-modal"
      backdropClassName="custom-list-modal-drop-back"
      show={show}
      centered
      size="md"
    >
      <div className="list-modal">
        <div className="list-modal-body">
          <p className="list-modal-body-p">
            {t("material-option-sub-heading")}
          </p>
          {itemsList?.map((item, index) => {
            return (
              <span
                key={index}
                className="list-modal-body-item"
                onClick={() => handleAddItem(item)}
              >
                <span className="list-modal-body-item-p">{item.name}</span>
              </span>
            );
          })}
        </div>
        <div className="list-modal-footer">
          <span onClick={() => setShow(false)} className="footer-icon">
            <CrossIcon style={{ width: "14px", fill: "#626C83" }} />
          </span>
        </div>
      </div>
    </Modal>
  );
};
MaterialListModal.propTypes = {
  show: PropTypes.any,
  itemsList: PropTypes.any,
  setShow: PropTypes.any,
  handleAddItem: PropTypes.any,
};
export default MaterialListModal;
