import React from "react";
import "./footerlinks.scss";
import Footer from "../../common/ui/Footer";
import NavHome from "../../components/Navbar";

import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Imprint = () => {
  const { t } = useTranslation();
  return (
    <div className="imprint-section">
      <NavHome />
      <div className="main-div container">
        <div className="d-flex link">
          <p>Start</p>
          <p>&nbsp;/ {t("imprint-link")}</p>
        </div>
        <h1 className="fw-bold">{t("imprint-main-h")}</h1>
        <div className="py-5">
          <Row>
            <Col lg={6} sm={12}>
              <p>{t("imprint-p1")}</p>
              <h2 className="fw-bold">{t("imprint-h1")}</h2>
              <p>
                {t("imprint-p2")}
                <br />
                <br />
                {t("imprint-p3")}
                <br />
                <br />
                {t("imprint-p4")}
                <br />
                <br />
                {t("imprint-p5")}
              </p>
            </Col>
            <Col lg={6} sm={12}>
              <p className="fw-bold">{t("imprint-p6")}</p>
              <p>{t("imprint-p7")}</p>
              <div className="d-flex">
                <p className="fw-bold">{t("imprint-p8")}</p>
                <p>{t("imprint-p9")}</p>
              </div>
              <p>{t("imprint-p10")}</p>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={12}>
              <h2 className="fw-bold">{t("imprint-h2")}</h2>
              <p>
                {t("imprint-p11")}
                <br />
                <br />
                {t("imprint-p12")}
                <br />
                <br />
                {t("imprint-p13")}
                <br />
                <br />
                {t("imprint-p14")}
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Imprint;
