// export const AUTH_USER = 'api/v1/user/Authenticate';
// export const REGISTER_USER = 'api/v1/user/Register';
export const ADD_PERMISSION = "api/v1/user/AddPermission";
export const ADD_ROLE = "api/v1/user/addRole";
export const VERIFY_USER = "api/v1/user/verifyUserAccount";
export const USER_PROFILE = "api/v1/user/profile";
// export const CHANGE_PASSWORD = 'api/v1/user/changePassword';
// export const RENEW_PASSWORD = 'api/v1/user/RenewPassword';
// export const LOGOUT = 'api/v1/user/Logout';
// export const VERIFY_FORGET_PASSWORD_CODE =
//   'api/v1/user/Get/Verify/ForgetPasswordCode/';
//export const VERIFY_TOKEN_URL = 'api/v1/user/Get/Verify/ForgetPasswordCode/';

// export const SEND_FORGET_PASSWORD_CODE =
//   'api/v1/user/SendForgotPasswordCodeByEmail';
// export const GET_ROLES_LIST = 'api/v1/user/getRole';
// export const GET_USER_PROFILE = 'api/v1/user/getprofile';

// export const UPDATE_USER_PROFILE = 'api/v1/user/updateprofile';

// export const GET_MATERIAL = 'api/v1/Material/getAll';

// export const GET_MATERIAL_LIST = 'api/v1/MaterialUsage/getAll';

// export const GET_DISTRIBUTOR_LIST =
//   "api/v1/DistributorCompany/getAll?PageNumber=1&PageSize=100";

// export const CREATE_JOB = 'api/v1/Job/create';
// export const GET_ALLJOBS_URL = 'api/v1/Job/getAll?';

// export const GET_ALLCOMPANIES_URL = 'api/v1/LogisticsCompany/getAll';

// export const GET_ALLORDERS_URL = 'api/v1/Order/getAll';
// export const GET_ALLORDERS_COLLECTION_URL = 'api/v1/Order/getAll?';
// export const GET_COLLECTED_URL = 'api/v1/Order/getAll';

// export const GET_LOGISTICS_ALLORDERS_COLLECTION_URL =
//   'api/v1/Order/getAllLogisticsOrders?';

// export const CREATE_ORDER_URL = 'api/v1/Order/create';

// export const GET_ORDER_BYID_URL = 'api/v1/Order/getById?OrderId=';

// export const ADD_ORDER_PICKUP_URL = 'api/v1/Order/update?OrderId=';

export const GET_COMPANIES_BYROLEID_URL =
  "api/v1/Company/getCompaniesByRoleId?RoleId=";

// export const GET_DASHBOARD_DATA_URL = 'api/v1/Dashboard';

// export const GET_TRANSLATIONS = 'api/v1/Translation/getAll';

// export const GET_ORDER_ITEMS = 'api/v1/Order/getOrderItems/';
/*
-----------------------------------------------------------------------------------------------------
-----------------------------------------------------------------------------------------------------


     ///////////////////////////////          NEW URL       /////////////////////////////////////


-----------------------------------------------------------------------------------------------------
-----------------------------------------------------------------------------------------------------
*/

//--------------------->              Dashboard

export const GET_DASHBOARD_DATA_URL = "api/v1/dashboard";

//--------------------->              Common

export const GET_JOB_Image_URL = "api/v1/common/file-by-key";

//--------------------->              Distributer Company

export const GET_DISTRIBUTOR_LIST =
  "api/v1/distributor-company?PageNumber=1&PageSize=100";

//--------------------->              Job

export const CREATE_JOB = "api/v1/job";
export const GET_ALLJOBS_URL = "api/v1/job?";

//--------------------->              Logistics Company

export const GET_ALLCOMPANIES_URL = "api/v1/logistics-company ";

//--------------------->              Material

export const GET_MATERIAL = "api/v1/material";

//--------------------->              Material Usage

export const GET_MATERIAL_LIST = "api/v1/material-usage";

//--------------------->              Order

export const GET_ALLORDERS_URL = "api/v1/order";
export const GET_ALLORDERS_COLLECTION_URL = "api/v1/order?";
export const GET_COLLECTED_URL = "api/v1/order";

export const GET_ORDER_ITEMS = "api/v1/order/order-items/";

export const GET_LOGISTICS_ALLORDERS_COLLECTION_URL =
  "api/v1/order/all-logistics-orders?";

export const GET_ORDER_BYID_URL = "api/v1/order/";

export const CREATE_ORDER_URL = "api/v1/order";

export const ADD_ORDER_PICKUP_URL = "api/v1/order/";

//--------------------->              Translation

export const GET_TRANSLATIONS = "api/v1/translation";

//--------------------->              User   (Suggested Name : Authentication)

export const REGISTER_USER = "api/v1/user/register";
export const GET_ROLES_LIST = "api/v1/user/role";
export const AUTH_USER = "api/v1/user/authenticate";
export const CHANGE_PASSWORD = "api/v1/user/change-password";
export const RENEW_PASSWORD = "api/v1/user/renew-password";
export const LOGOUT = "api/v1/user/logout";
export const GET_USER_PROFILE = "api/v1/user/profile";
export const UPDATE_USER_PROFILE = "api/v1/user/profile";
export const VERIFY_FORGET_PASSWORD_CODE =
  "api/v1/user/verify/forget-password/";
export const VERIFY_TOKEN_URL = "api/v1/user/verify/forget-password";
export const SEND_FORGET_PASSWORD_CODE = "api/v1/user/send-forgot-password";
export const UPDATE_LOGISTIC_PROFILE = "api/v1/logistics-company";
