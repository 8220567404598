import React, { useState, useEffect } from "react";
import EditProfile from "./EditProfile";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SubmitButton } from "../common/Button.style";
import EditProfileIcon from "../assets/icons/EditProfileIcon.png";
import { logout } from "../services/AuthService";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";
import { getUserProfile } from "../services/AuthService";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getTranslatedMessage } from "../common/utils/Utilities";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
const ProfileInfo = (props) => {
  const [btn_loading, setbtn_loading] = useState(false);
  const { show, handleShow } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [userProfileInfo, setUserProfileInfo] = useState({});
  useEffect(() => {
    getProfile();
  }, []);
  const getProfile = async () => {
    const result = await getUserProfile();
    setUserProfileInfo(result?.payLoad);
    localStorage.setItem("companyId", result?.payLoad?.companyId);
  };
  const handleSignout = async () => {
    setbtn_loading(true);
    let result = await logout({
      userId: localStorage.getItem("userId"),
      refreshToken: localStorage.getItem("refreshToken"),
    });
    if (result?.data?.success) {
      toast.success(getTranslatedMessage(result?.data?.message));
      localStorage.clear();
      navigate("/");
    }
    setbtn_loading(false);
  };
  return (
    <div
      className="profile-container"
      style={{ height: show ? "auto" : `calc(100% - 130px)` }}
    >
      <Toaster position="top-center" />
      <div className="d-flex justify-content-between  mb-5 edit-profile-btn-container">
        <div
          style={{ backgroundColor: "#ceaf0b" }}
          onClick={() => (window.location = "/")}
        >
          <FontAwesomeIcon
            style={{ height: "20px", color: "white", padding: "8px" }}
            icon={faHouse}
          />
        </div>
        <button className="edit-profile-btn" onClick={handleShow}>
          <img src={EditProfileIcon} alt="profileicon" />
        </button>
      </div>
      {!show ? (
        <div className="profile-subcontainer">
          <h1 className="fw-bold">
            {userProfileInfo?.name} {userProfileInfo?.surname}
          </h1>
          <div>
            <p className="mb-0">{t("company-name")}</p>
            <h3 className="fw-bold ">{userProfileInfo?.company?.name}</h3>
          </div>
          {!btn_loading ? (
            <SubmitButton
              className="submit-btn"
              minWidth="max-content"
              padding={"0.3rem 0.3rem"}
              onClick={handleSignout}
            >
              {t("sign-out-button-label")}
            </SubmitButton>
          ) : (
            <SubmitButton
              disabled
              className="submit-btn"
              minWidth="max-content"
              padding={"0.3rem 2rem"}
            >
              <Spinner animation="border" height="100px" />
            </SubmitButton>
          )}
        </div>
      ) : (
        <EditProfile
          getProfile={getProfile}
          userProfileInfo={userProfileInfo}
        />
      )}
    </div>
  );
};
ProfileInfo.propTypes = {
  show: PropTypes.any,
  handleShow: PropTypes.any,
};
export default ProfileInfo;
