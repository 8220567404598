import React, { useEffect } from "react";
import { Circles } from "react-loader-spinner";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router";
import { verifyToken } from "../../services/CommonServices";

const Redirection = ({ match }) => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const checkLink = async () => {
      let result = await verifyToken(params.id);
      if (result?.success) {
        localStorage.setItem("ResetPasswordId", params.id);
        navigate("/resetpassword");
      } else {
        navigate("/linkexpired");
      }
    };
    checkLink();
  }, [params.id]);

  return (
    <div className="d-flex justify-content-center align-items-center redirection">
      <Circles heigth="100" width="100" color="white" ariaLabel="loading" />
    </div>
  );
};
Redirection.propTypes = {
  match: PropTypes.any,
};
export default Redirection;
