export const batteryForm = (props) => {
  return [
    {
      id: 1,
      name: "BMW/MINI/RR Teile-Nr. (AW)",
      value: props?.itemNumber,
    },
    {
      id: 2,
      name: "Batterie verbaut in",
      value: props?.productUsed,
    },

    {
      id: 5,
      name: "Kategorie",
      value: props?.categoryName,
    },
    {
      id: 6,
      name: "Zellchemie",
      value: props?.cellChemistry,
    },

    {
      id: 12,
      name: "Spannung [V]",
      value: props?.voltageRange,
    },
    {
      id: 13,
      name: "Kapazität [Ah]",
      value: props?.capacity,
    },
    {
      id: 14,
      name: "Max. Leistung [kW]",
      value: props?.maxPower,
    },
    {
      id: 15,
      name: "Netto Gewicht [kg]",
      value: props?.weight,
    },
    {
      id: 16,
      name: "Maße Länge x Breite x Höhe [mm]",
      value: props?.dimensions,
    },
  ];
};

export const getThemeContent = () => {
  let service = localStorage.getItem("serviceType");
  let obj = {
    bgColor: "blue-side-bar",
    color: "#024A96",
    brandIcon: "brand.png",
    cartIcon: "cart.svg",
    batteryCollectionModal: false,
    searchIcon: "search.png",
    orderBg: "order-bg.png",
  };
  if (service?.length > 0 && service !== "pickUp") {
    obj.bgColor = "orange-side-bar";
    obj.color = "#f39500";
    obj.brandIcon = "brand-orange.png";
    obj.cartIcon = "cart-orange.png";
    obj.batteryCollectionModal = true;
    obj.searchIcon = "search-black.png";
    obj.orderBg = "order-bg-orange.png";
  }
  return obj;
};
export const getTranslatedMessage = (identifier) => {
  let translations = JSON.parse(localStorage.getItem("translations"));
  var translatedMessage = translations.find(function (message) {
    return message.identifier == identifier;
  });
  return translatedMessage.title;
};
export const getServiceType = () => {
  return localStorage.getItem("serviceType");
};

export const S3_BASE_URL =
  "https://basf-mycyclo-storage.s3.eu-central-1.amazonaws.com/";
