//import { BASE_URL } from "../constant/ApiUrls";
//import { REFRESH_TOKEN_URL } from '../constant/ApiUrls';
import axios from "axios";
axios.defaults.withCredentials = true;
axios.interceptors.request.use((request) => {
  let token = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  request.headers = headers;
  return request;
});

axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (err) => {
    try {
      if (err.response.status === 403) {
        localStorage.clear();
        window.location = "/";
      } /* else if (
        err.config.url.includes(REFRESH_TOKEN_URL) &&
        err.response.status === 401
      ) {
        localStorage.clear();
        window.location = '/';
      } else if (
        !err.config.url.includes('Users') &&
        err.response.status === 401
      ) {
        let request_data = err.response.config;
        let data = {
          jwtToken: localStorage.getItem('token'),
          refreshToken: localStorage.getItem('RefreshToken'),
        };
        const refreshTokenApiheaders = {
          'Content-Type': 'application/json',
          "x-api-version": "1",
        };
        let apiResult = await axios.post(
          BASE_URL + REFRESH_TOKEN_URL,
          data,
          { headers: refreshTokenApiheaders }
        );
        if (apiResult && apiResult.status === 200) {
          localStorage.setItem('token', apiResult.data.jwtToken);
          localStorage.setItem('RefreshToken', apiResult.data.refreshToken);
          let headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiResult.data.jwtToken}`,
            "x-api-version": "1",
          };
          return await axios({
            url: request_data.url,
            method: request_data.method,
            data: request_data.data,
            headers: headers,
          });
        } else if (
          (apiResult && apiResult.status === 500) ||
          apiResult.status === 400
        ) {
          localStorage.clear();
          window.location = '/login';
        }
      } */ else if (err.response.status === 406) {
        return await err.response;
      } else {
        return err.response;
      }
    } catch (error) {
      return error.response;
    }
  }
);
