import { environment } from "../Environments/Environment";
import axios from "axios";
const token = localStorage.getItem("token");

const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
};

export const getMethod = async (apiUrl) => {
  try {
    return await axios.get(apiUrl, {
      headers: headers,
    });
  } catch (error) {
    return error.response;
  }
};

export const postMethod = async (apiUrl, data) => {
  try {
    return await axios.post(environment.BASE_URL + apiUrl, data, {
      header: headers,
    });
  } catch (error) {
    return error.response;
  }
};
export const uploadFile = async (apiUrl, data, config) => {
  try {
    return await axios.post(environment.BASE_URL + apiUrl, data, config, {
      header: headers,
    });
  } catch (error) {
    return error.response;
  }
};
export const deleteMethod = async (apiUrl) => {
  try {
    return await axios.delete(environment.BASE_URL + apiUrl);
  } catch (error) {
    return error.response;
  }
};
export async function putMethod(apiUrl, data) {
  try {
    return await axios.put(environment.BASE_URL + apiUrl, data);
  } catch (error) {
    return error.response;
  }
}
