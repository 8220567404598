import React from "react";
import { ErrorMessage, useField, Field, useFormikContext } from "formik";
import PropTypes from "prop-types";

const TextFieldWithUnit = ({
  min,
  max,
  label,
  width,
  padding,
  marginTop,
  showLabel,
  marginLeft,
  as,
  ...props
}) => {
  const { setFieldValue } = useFormikContext(props);
  let [field, meta] = useField(props);

  return (
    <>
      <div
        className="border-text-field-with-unit"
        style={{
          marginTop: marginTop,
          marginLeft: marginLeft,
        }}
      >
        {showLabel === "false" ? null : (
          <label htmlFor={field.name} className="field-label">
            {label}
          </label>
        )}

        <div className="input-field">
          <Field
            className={`form-control shadow-none field-input ${
              meta.touched && meta.error && "is-invalid"
            }`}
            autoComplete="off"
            {...field}
            {...props}
            style={{
              width: width,
              padding: padding,
            }}
            min={min}
            max={max}
            as={as}
            onChange={(e) =>
              setFieldValue(field.name, e.target.value.replaceAll(/\s/g, ""))
            }
          />
          {field?.value && <span> €</span>}
        </div>

        <ErrorMessage
          component="p"
          name={field.name}
          style={{
            fontSize: "11px",
            color: "red",
            marginBottom: "0",
          }}
        />
      </div>
    </>
  );
};
TextFieldWithUnit.propTypes = {
  elementType: PropTypes.any,
  min: PropTypes.any,
  max: PropTypes.any,
  label: PropTypes.any,
  width: PropTypes.any,
  padding: PropTypes.any,
  marginTop: PropTypes.any,
  cardWidth: PropTypes.any,
  showLabel: PropTypes.any,
};
export default TextFieldWithUnit;
