import React, { useState, useEffect } from "react";
import ScanIcon from "../assets/icons/ScanIcon.png";
import ListIcon from "../assets/icons/ListIcon.png";
import ProfileIcon from "../assets/icons/ProfileIcon.png";
import "./components.scss";
import { toast } from "react-hot-toast";
import ProfileInfo from "./ProfileInfo";
import MaterialList from "./MaterialList";
import { getAllMaterial } from "../services/JobService";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [tabId, setTabId] = useState(1);
  const [materialSelectionList, setMaterialSelectionList] = useState([]);
  const [itemsList, setItemList] = useState("");
  useEffect(() => {
    AllMaterial();
  }, [show]);

  const AllMaterial = async () => {
    const result = await getAllMaterial();
    setItemList(result.payLoad.items);
  };
  const handleShow = () => {
    setShow(!show);
  };
  const componentFormatter = () => {
    if (tabId === 1) {
      return <ProfileInfo show={show} handleShow={handleShow} />;
    } else if (itemsList && tabId === 2) {
      return (
        <MaterialList
          handleAddItem={handleAddItem}
          handleDeleteItem={handleDeleteItem}
          setMaterialSelectionList={setMaterialSelectionList}
          materialSelectionList={materialSelectionList}
          itemsList={itemsList}
        />
      );
    } else {
      return (
        <div className="barcode-txt">
          Die QR Code Funktion wird aktuell entwickelt.
          <br />
          Sie wird bald hier zur Verfügung stehen.
        </div>
      );
    }
  };
  const handleAddItem = (item) => {
    const isExisted = materialSelectionList.some(
      (elem) => elem.materialId === item.materialId
    );
    if (isExisted) {
      toast.error(t("material-already-exists-error"));
    } else {
      setMaterialSelectionList([...materialSelectionList, item]);
    }
  };
  const handleDeleteItem = (id) => {
    setMaterialSelectionList(removeItem(id));
  };
  const removeItem = (id) => {
    return materialSelectionList.filter((item) => item.materialId !== id);
  };
  return (
    <div className="main-container">
      {componentFormatter()}
      <div className={`${!show ? "d-flex" : "d-none"} footer-profile `}>
        <div className="bottom-tabs">
          <div className="tab">
            <span
              className="tab-btn"
              onClick={() => setTabId(1)}
              style={{ height: "2.5rem" }}
            >
              <img src={ProfileIcon} alt="profileIcon" />
            </span>
            <div
              className={`${tabId === 1 ? "d-inline" : "d-none"} bar-container`}
            >
              <div className="bar mb-2"></div>
              <h6 className="text-center fw-bold text-white">
                {t("user-tab1")}
              </h6>
            </div>
          </div>
          <div className="tab">
            <span
              className="tab-btn position-relative"
              onClick={() => setTabId(2)}
              style={{ height: "2.5rem" }}
            >
              <img src={ListIcon} alt="listIcon" />
              <span
                className={`${
                  materialSelectionList.length > 0 ? "d-flex" : "d-none"
                } icon-batch`}
              >
                {materialSelectionList.length}
              </span>
            </span>
            <div
              className={`${tabId === 2 ? "d-inline" : "d-none"} bar-container`}
            >
              <div className="bar mb-2"></div>
              <h6 className="text-center fw-bold text-white">
                {t("user-tab2")}
              </h6>
            </div>
          </div>
          <div className="tab">
            <span
              className="tab-btn"
              onClick={() => setTabId(3)}
              style={{ height: "2.5rem" }}
            >
              <img src={ScanIcon} alt="scanicon" />
            </span>
            <div
              className={`${tabId === 3 ? "d-inline" : "d-none"} bar-container`}
            >
              <div className="bar mb-2"></div>
              <h6 className="text-center fw-bold text-white">
                {t("user-tab3")}
              </h6>
            </div>
          </div>
        </div>
        <div className="bottom-tab-bg"></div>
      </div>
    </div>
  );
};

export default Profile;
