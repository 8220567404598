import React, { useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { StyledButton } from "../Button.style";
import { Row, Col, Modal, Spinner } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
import "./modal1.scss";
import TextField from "../ui/TextField";
import { addOrderPickUpDate } from "../../services/OrderServices";
import PropTypes from "prop-types";
import { DAY, YEAR } from "../../constants/Constants";
import { useTranslation } from "react-i18next";
import { getTranslatedMessage } from "../../common/utils/Utilities";
const ActualPickup = (props) => {
  const {
    show,
    close,
    orderId,
    estimationCollectionDate,
    setCheckChangesTrue,
  } = props;
  const [btn_loadin, setbtn_loadin] = useState(false);
  const { t } = useTranslation();
  const validate = Yup.object().shape({
    month: Yup.string()
      .matches(/^([0-9]{1,2})$/, t("invalid-month"))
      .test("month", t("invalid-month"), (value) => {
        if (value < 1 || value > 12) {
          return false;
        }
        return true;
      })
      .min(1, "Invalid")
      .max(2, "Invalid")
      .required(t("required-err")),
    year: Yup.string()
      .test(YEAR, t("enter-current-year"), (value) => {
        const today = new Date();
        const adultYear = today.getFullYear();
        if (value >= adultYear) {
          return true;
        }
        return false;
      })
      .matches(/^([0-9]{4})$/, t("enter-current-year"))
      .min(4, "Must be 4 digits")
      .max(4, "Must be 4 digits")
      .required(t("enter-current-year")),
    day: Yup.string()
      .test(DAY, t("invalid-day"), function (day) {
        const month = this.options.parent.month;
        const year = this.options.parent.year;
        // February
        if (month === 2) {
          const isLeapYear =
            year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

          if (day > 29 || (day === 29 && !isLeapYear)) {
            return false;
          }
        }
        return true;
      })
      .test(DAY, t("invalid-day"), function (day) {
        const month = this.options.parent.month;
        if (
          (month === 4 || month === 6 || month === 9 || month === 11) &&
          day === 31
        ) {
          return false;
        }
        return true;
      })
      .test(DAY, t("invalid-day"), (day) => {
        if (day < 1 || day > 31) {
          return false;
        }
        return true;
      })
      .matches(/^([0-9]{1,2})$/, "Digits Only")
      .min(1, t("invalid-day"))
      .max(2, t("invalid-day"))
      .required(t("required-err")),
  });

  let currentDate = new Date();

  const handleSubmit = async (formik) => {
    let day = formik.values.day;
    let month = formik.values.month;
    let year = formik.values.year;

    let currentYear = new Date().getFullYear();
    if (year >= currentYear) {
      if (Number(day) < 10) {
        day = "0" + Number(day);
      }
      if (Number(month) < 10) {
        month = "0" + Number(month);
      }

      let date = month + "." + day + "." + year;
      let estimatedDate =
        estimationCollectionDate?.estimatesCollectionDate.substring(3, 5) +
        "." +
        estimationCollectionDate?.estimatesCollectionDate.substring(0, 2) +
        "." +
        estimationCollectionDate?.estimatesCollectionDate.substring(6);
      setbtn_loadin(true);
      let result = await addOrderPickUpDate({
        orderId: orderId,
        estimatesCollectionDate: estimatedDate,
        collectionDate: date,
      });
      if (result?.success) {
        toast.success(getTranslatedMessage(result?.message));
        setCheckChangesTrue();
        close();
      } else {
        toast.error(getTranslatedMessage(result?.statusText));
      }
      setbtn_loadin(false);
    } else {
      toast.error("Year must be greater than or equal to current year.");
    }
  };
  return (
    <Modal
      contentClassName="actual-pickup-custom-modal"
      backdropClassName="actual-pickup-custom-modal-backdrop"
      show={show}
      centered
      size="lg"
    >
      <div className="close-icon-div d-flex justify-content-end">
        <img
          src="/assets/icons/cross-icon1.png"
          alt="cross-btn"
          onClick={close}
          className="custom-cursor"
        />
      </div>
      <Formik
        initialValues={{
          day: estimationCollectionDate?.collectionDate
            ? estimationCollectionDate?.collectionDate.substring(0, 2)
            : currentDate.getDate(),
          month: estimationCollectionDate?.collectionDate
            ? estimationCollectionDate?.collectionDate.substring(3, 5)
            : currentDate.getMonth() + 1,
          year: estimationCollectionDate?.collectionDate
            ? estimationCollectionDate?.collectionDate.substring(6)
            : currentDate.getFullYear(),
        }}
        validationSchema={validate}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formik) => (
          <>
            <div className="actual-pickup-modal custom-modal-body bg-white p-5">
              <h4>{t("heading-modal3")}</h4>

              <Row style={{ marginTop: "-20px" }}>
                <Col lg={6}>
                  <Form>
                    <div className="d-flex tatsch-section">
                      <div>
                        <TextField
                          type="text"
                          name="day"
                          placeholder="Day"
                          value={formik.values.day}
                          maxLength={2}
                          max={31}
                          min={1}
                          onChange={(e) => {
                            let value = e.target.value;
                            const currentValue = value.replace(/[^\d]/g, "");
                            formik.setFieldValue("day", currentValue);
                          }}
                          width="70px"
                          padding="0.375rem 0.3rem"
                        />
                      </div>
                      <div className="ms-1">
                        <TextField
                          type="text"
                          name="month"
                          placeholder="Month"
                          value={formik.values.month}
                          maxLength={2}
                          max={12}
                          min={1}
                          width="100px"
                          padding="0.375rem 0.3rem"
                          onChange={(e) => {
                            let value = e.target.value;
                            const currentValue = value.replace(/[^\d]/g, "");
                            formik.setFieldValue("month", currentValue);
                          }}
                        />
                      </div>
                      <div className="ms-1">
                        <TextField
                          type="text"
                          name="year"
                          placeholder="Year"
                          value={formik.values.year}
                          maxLength={4}
                          min={2022}
                          width="110px"
                          padding="0.375rem 0.3rem"
                          onChange={(e) => {
                            let value = e.target.value;
                            const currentValue = value.replace(/[^\d]/g, "");
                            formik.setFieldValue("year", currentValue);
                          }}
                        />
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
              {formik.touched && formik.isValid ? (
                !btn_loadin ? (
                  <button
                    type="submit"
                    className="submit-btn mt-4"
                    onClick={() => handleSubmit(formik)}
                  >
                    {t("confirm-button-label")}
                  </button>
                ) : (
                  <button disabled className="submit-btn mt-4">
                    <Spinner animation="border" />
                  </button>
                )
              ) : (
                <button
                  disabled
                  type="submit"
                  className="submit-btn mt-4 btn-disabled"
                >
                  {t("confirm-button-label")}
                </button>
              )}
            </div>
          </>
        )}
      </Formik>
      <Toaster />
    </Modal>
  );
};
ActualPickup.propTypes = {
  show: PropTypes.any,
  close: PropTypes.any,
  orderId: PropTypes.any,
  estimationCollectionDate: PropTypes.any,
  orderDetails: PropTypes.any,
  setCheckChangesTrue: PropTypes.any,
};
export default ActualPickup;
