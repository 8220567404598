import { ErrorMessage } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import "./datePicker.css";

import DatePicker from "react-multi-date-picker";
const DatePickerCustom = ({ isError, errorMessage, onChange }) => {
  const [value, setValue] = useState();

  const { t } = useTranslation();
  const months = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli ",
    "August",
    "September",
    "Oktober",
    "November ",
    "Dezember",
  ];
  const weekDays = ["SO", "MO", "DI", "WE", "DO", "FR", "SA"];
  return (
    <div className="date-picker-container">
      {" "}
      <label>
        <b>{t("date")}</b>
      </label>
      <br />
      <DatePicker
        className="green"
        name="dropDate"
        months={months}
        weekDays={weekDays}
        multiple={false}
        value={value}
        // color="#F39500"
        onChange={(v) => onChange(v)}
        format="DD/MM/YYYY"
        placeholder="dd/mm/yyyy"
        inputClass={` date-picker-input form-control shadow-none field-input ${
          isError && "error-case"
        } `}
      />
      <p className="error-case1">{isError && errorMessage} </p>
    </div>
  );
};

export default DatePickerCustom;
