import React from "react";
import { Modal } from "react-bootstrap";
import Backdrop from "../../assets/icons/Backdrop.png";
import TickIcon from "../../assets/icons/TickIcon.png";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ThankingNote = ({ show, close }) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      centered
      size="md"
      contentClassName="custom-thanking-modal"
      onHide={close}
    >
      <div className="d-flex flex-column align-items-center thanking-modal-conatiner">
        <div className="position-relative">
          <img src={Backdrop} alt="backdropicon" />
          <img
            src={TickIcon}
            alt="tickicon"
            className="position-absolute tick-img"
          />
        </div>
        <h3 className="fw-bold text-center mt-2">
          {t("success-message-modal5")}
        </h3>
      </div>
    </Modal>
  );
};
ThankingNote.propTypes = {
  show: PropTypes.any,
  close: PropTypes.any,
};
export default ThankingNote;
