import React from "react";
import ResetPasswordCard from "../../components/ResetPasswordCard";
import "./resetpasswordPage.scss";
const ResetPassword = () => {
  return (
    <div className="resetpassword-page">
      <img
        src="assets/icons/mcyclo-logo.png"
        width="auto"
        height={30}
        alt="brand"
      />
      <ResetPasswordCard/>
    </div>
  );
};

export default ResetPassword;