import React, { forwardRef } from "react";

const CheckBox = (props) => {
  const {
    name,
    label,
    checked,
    onChange,
    isDisabled,
    marginLeft,
    padding,
    fontWeight,
    marginBottom,
    marginTop,
    inputClassname,
    inputLabelClassname,
    ...rest
  } = props;
  return (
    <div
      className={`${
        marginBottom ? marginBottom : ""
      } my-checkbox d-flex align-items-center`}
      style={{
        marginLeft: marginLeft,
        marginTop: marginTop || null,
      }}
    >
      <input
        className={inputClassname}
        disabled={isDisabled}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        style={{
          padding: padding,
          opacity: `${isDisabled && ".5"} `,
        }}
        {...rest}
      />
      <label
        className={inputLabelClassname}
        style={{
          fontWeight: fontWeight,
        }}
      >
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
