import React, { useEffect, useState } from "react";
import { Accordion, Col, Row, Spinner, Table } from "react-bootstrap";
import { AnchorButton } from "./../../common/Button.style";
import InfoIcon from "../../assets/icons/InfoIcon";
import InfoCard from "../../components/InfoCardJob";
import OpeningHours from "../../common/modals/OpeningHours";
import OrderInfo from "../../common/modals/OrderInfo";
import ActualPickup from "../../common/modals/ActualPickup";
import Navbar from "./../../components/Navbar";
import "./jobpage.scss";
import ReactPaginate from "react-paginate";
import FilterCard from "../../common/ui/FilterCard";
import { getAllLogisticsOrders } from "../../services/JobService";
import { useTranslation } from "react-i18next";
import { getJobImage } from "../../services/CommonServices";
import { getOrderItems } from "../../services/OrderServices";
import toast from "react-hot-toast";

const JobsPage = () => {
  const { t } = useTranslation();
  const [openingHoursModal, setOpeningHoursModal] = useState(false);
  const [actualPickupModal, setActualPickupModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [orderInfoModalShow, setOrderInfoModalShow] = useState(false);
  const [estimationCollectionDate, setestimationCollectionDate] = useState("");
  const [openingHoursData, setopeningHoursData] = useState({});
  const [pageNumber, setpageNumber] = useState(1);
  const [orderId, setorderId] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [ApiResult, setApiResult] = useState([]);
  const [CheckChanges, setCheckChanges] = useState(false);
  const [ApiLoading, setApiLoading] = useState(false);
  const [orderItems, setorderItems] = useState([]);
  const [loading, setloading] = useState(false);
  const [orderDetailId, setorderDetailId] = useState();
  const [firstload, setfirstload] = useState(true);

  const dashBoadList = JSON.parse(localStorage.getItem("dashboardData"));

  useEffect(() => {
    GetOrdersList();
  }, [pageNumber, CheckChanges, selectValue]);

  useEffect(() => {
    if (firstload) {
      return setfirstload(false);
    } else {
      handleOrderDetail();
    }
  }, [orderDetailId]);

  const GetOrdersList = async () => {
    setApiLoading(true);
    let result = await getAllLogisticsOrders({
      pageNumber,
      OrderBy: selectValue,
    });
    if (result?.success) {
      let arr = [];
      // for (let i = 0; i < result.payLoad?.items.length; i++) {
      //   let elementI = result.payLoad?.items[i];
      //   let temp = [];
      //   for (let j = 0; j < elementI.orderItems.length; j++) {
      //     let elementJ = elementI.orderItems[j];
      //     let result = await getJobImage(elementJ?.job?.jobPicture);
      //     if (result.status) {
      //       elementJ.job.jobPicture = result.blob;
      //       temp.push(elementJ);
      //     }
      //   }
      //   elementI.orderItems = temp;
      //   arr.push(elementI);
      // }
      setApiResult(result.payLoad);
    }
    setApiLoading(false);
  };
  const handleChange = (e, key) => {
    let value = e.target.value;
    if (key === "selectValue") {
      setSelectValue(value);
    } else if (key === "searchInput") {
      setSearchInput(value);
    }
  };
  const handleActualPickUpButtonClick = (e, item) => {
    e.stopPropagation();
    setorderId(item.orderId);
    setestimationCollectionDate(item);
    setActualPickupModal(true);
  };
  const handleOpeningHoursButtonClick = async (e, item) => {
    e.stopPropagation();
    setopeningHoursData(item);
    setorderId(item.orderId);
    setOpeningHoursModal(true);
  };
  const list = dashBoadList?.orderCollectionRequest;
  const handleNextPage = async (page) => {
    let pageNumber = page.selected + 1;
    setpageNumber(pageNumber);
  };
  const handleCallback = () => {
    setCheckChanges(!CheckChanges);
  };
  const handleOrderInfoModal = (event, item) => {
    event.stopPropagation();
    setopeningHoursData(item);
    setOrderInfoModalShow(true);
  };

  const handleImageCall = (data) => {
    (async () => {
      const arr = data;
      // for (let i = 0; i < arr.length; i++) {
      //   let img = await getJobImage(arr[i]?.job?.jobPicture);
      //   if (img.status) {
      //     arr[i].job.jobPicture = img.blob;
      //   }
      // }
      setloading(false);
      return setorderItems(arr);
    })();
  };

  const handleOrderDetail = async () => {
    setloading(true);
    const result = await getOrderItems(orderDetailId);
    if (result?.success) {
      handleImageCall(result?.payLoad);
    } else {
      toast.error(result?.message);
      setloading(false);
    }
  };
  return (
    <>
      <OpeningHours
        setCheckChangesTrue={handleCallback}
        orderId={orderId}
        data={openingHoursData}
        show={openingHoursModal}
        close={() => setOpeningHoursModal(false)}
      />
      <OrderInfo
        data={openingHoursData}
        show={orderInfoModalShow}
        close={() => setOrderInfoModalShow(false)}
      />
      <ActualPickup
        setCheckChangesTrue={handleCallback}
        orderId={orderId}
        estimationCollectionDate={estimationCollectionDate}
        show={actualPickupModal}
        close={() => setActualPickupModal(false)}
      />
      <Navbar />
      <div className="my-page jobs-page mt-5">
        <h1>{t("main-heading")}</h1>
        <FilterCard
          handleChange={(e) => handleChange(e, "selectValue")}
          handleSearch={(e) => handleChange(e, "searchInput")}
          value={searchInput}
          dropdownValue={selectValue}
          list={list}
        />
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={ApiResult?.totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={handleNextPage}
          containerClassName={`pagination ${"pagination-orange"}`}
          activeClassName={"active"}
          activeLinkClassName={"active-link"}
          forcePage={pageNumber - 1}
        />
        {!ApiLoading ? (
          <Table
            responsive
            borderless
            className="table-data my-custom-table mt-5 table-data-orange"
          >
            <thead className="custom-table-head">
              <tr>
                <th>{t("table-col1")}</th>
                <th>{t("table-col6")}</th>
                <th>{t("table-col2")}</th>
                <th>{t("table-col3")}</th>
                <th>{t("table-col4")}</th>
                <th>{t("table-col5")}</th>
              </tr>
            </thead>
            <tbody className="custom-table-body">
              <tr>
                <td colSpan={6} className="main-td">
                  <Accordion defaultActiveKey="-111">
                    {ApiResult?.items?.map((item, index) => (
                      <Accordion.Item
                        onClick={() => setorderDetailId(item.orderId)}
                        eventKey={index}
                      >
                        <Accordion.Header
                        // onClick={() => handleOrderDetail(item.orderId)}
                        >
                          <Table borderless className="w-100 m-0">
                            <tbody>
                              <tr>
                                <td>{item.orderCode}</td>
                                <td>{item.type ? item.type : "N/A"}</td>
                                <td>
                                  {item.createdAt.includes("0001")
                                    ? "N/A"
                                    : item.createdAt
                                        .substring(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("-")}
                                </td>
                                <td>
                                  <span
                                    onClick={(e) =>
                                      handleOpeningHoursButtonClick(e, item)
                                    }
                                  >
                                    {item.estimatesCollectionDate ? (
                                      item.estimatesCollectionDate
                                        .substring(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("-")
                                    ) : (
                                      <AnchorButton color="#0980FD">
                                        <u>Enter</u>
                                      </AnchorButton>
                                    )}
                                  </span>
                                </td>
                                <td>
                                  {item?.estimatesCollectionDate ? (
                                    <span
                                      onClick={(e) =>
                                        handleActualPickUpButtonClick(e, item)
                                      }
                                    >
                                      {item.collectionDate ? (
                                        item.collectionDate
                                          .substring(0, 10)
                                          .split("-")
                                          .reverse()
                                          .join("-")
                                      ) : (
                                        <AnchorButton color="#0980FD">
                                          <u>Enter</u>
                                        </AnchorButton>
                                      )}
                                    </span>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>

                                <td>
                                  <InfoIcon
                                    style={{ width: "16px", fill: "#f39500" }}
                                    onClick={(event) =>
                                      handleOrderInfoModal(event, item)
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Accordion.Header>
                        <Accordion.Body>
                          <>
                            <div className="accordion-details-card">
                              <Row>
                                <Col lg={12}>
                                  {!loading ? (
                                    orderItems?.map((item, index) => (
                                      <div
                                        key={index}
                                        className="left-side bg-white"
                                      >
                                        <div className="head" />
                                        <div className="body">
                                          <InfoCard
                                            cardItem={item.job}
                                            handleSelectItem={() => ""}
                                            tabId={1}
                                            selected={false}
                                          />
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <div
                                      className="d-flex justify-content-center align-items-center"
                                      style={{
                                        height: "40vh",
                                      }}
                                    >
                                      <Spinner animation="border" />
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: "60vh",
            }}
          >
            <Spinner animation="border" />
          </div>
        )}
      </div>
    </>
  );
};

export default JobsPage;
