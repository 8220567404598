import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { toast, Toaster } from "react-hot-toast";
import * as Yup from "yup";
import { Row, Col, Spinner } from "react-bootstrap";
import {
  AnchorButton,
  DeactiveButton,
  SubmitButton,
} from "../common/Button.style";
import { TextInputGroup, EmailInputGroup } from "../common/InputGroup.style";
import "./components.scss";
import { Link } from "react-router-dom";
import { registerUser } from "../services/AuthService";
import CustomDropdown from "../common/ui/CustomDropDown";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getTrackingParams } from "../common/utils/PageDetails";

import { getCompaniesByRoleId, getRoleslist } from "../services/OrderServices";
import TimeKeeper from "react-timekeeper";
import Time from "../assets/icons/Time";
import CheckBox from "../common/ui/CheckBox";

const RegisterCard = ({ back }) => {
  const { t } = useTranslation();
  const [rolesList, setroleslist] = useState([]);
  const [roleErr, setroleErr] = useState("");
  const [selectedRole, setselectedRole] = useState();
  const [companiesList, setcompaniesList] = useState([]);
  const [companyId, setcompanyId] = useState("");
  const [companyErr, setcompanyErr] = useState("");
  const [openingHours, setopeningHours] = useState("00:00");
  const [closingHours, setclosingHours] = useState("00:00");
  const [showOpeningHours, setshowOpeningHours] = useState(false);
  const [showClosingHours, setshowClosingHours] = useState(false);
  const [openingHoursErr, setopeningHoursErr] = useState("");
  const [closingHoursErr, setclosingHoursErr] = useState("");
  const [disableAll, setDisableAll] = useState(true);
  const [disableFew, setDisableFew] = useState(true);
  const [infoMsg, setInfoMsg] = useState("");
  const [btn_loading, setbtn_loading] = useState(false);
  const [agree, setAgree] = useState(false);
  const Navigate = useNavigate();
  const tracking = (item) => {
    let params = getTrackingParams(item);
    window.utag.view(params);
  };
  useEffect(() => {
    GetDropdownList();
  }, []);
  const GetDropdownList = async () => {
    let result = await getRoleslist();
    setroleslist(result.payLoad);
  };
  const containsNumber = (str) => {
    return /\d/.test(str);
  };
  const containSpecialChar = (str) => {
    return /[-!$%^&*()£_+|~=`{}\[\]:\/;<>?,.@#]/.test(str);
  };

  const validEmail = (str) => {
    const filter = /^[a-zA-Z0-9.+_]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return filter.test(str);
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      sirName: "",
      email: "",
      role: "",
      company: "",
      street: "",
      nr: "",
      zipCode: "",
      city: "",
      openingHours: "",
      endingHours: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .label("Name")
        .required(t("required-err"))
        .matches(/[A-Za-zÄäÆæß ]+/, t("name-field-err"))
        .test(
          "Name",
          t("invalid-name"),
          (value) => !containsNumber(value) && !containSpecialChar(value)
        ),
      sirName: Yup.string()
        .label("Sur Name")
        .required(t("required-err"))
        .matches(/[A-Za-zÄäÆæß ]+/, t("sirname-field-err"))
        .test(
          "Sir name",
          t("invalid-sirname"),
          (value) => !containsNumber(value) && !containSpecialChar(value)
        ),
      street: Yup.string()
        .label("Street")
        // .required(t('required-err'))
        .matches(/[A-Za-zÄäÆæß ]+/, "Street number contains characters only.")
        .test(
          "Street",
          "Invalid street number",
          (value) => !containsNumber(value) && !containSpecialChar(value)
        ),
      company: Yup.string().label("Company").required(t("required-err")),
      // .matches(/^[a-zA-Z0-9 ]*$/, t("invalid-company"))
      // .test(
      //   "Company",
      //   t("invalid-company"),
      //   (value) => !containSpecialChar(value)
      // )
      nr: Yup.string()
        .label("Nr")
        // .required(t('required-err'))
        .matches(
          /^[-_a-zA-Z0-9.]+$/,
          "Nr contains numbers and characters only."
        ),
      zipCode: Yup.string()
        .label("Zip code")
        // .required()
        .min(5, t("city-field-err2"))
        .max(10, t("city-field-err2")),
      city: Yup.string()
        .label("City")
        // .required(t('required-err'))
        .matches(/[A-Za-zÄäÆæß ]+/, "City contains characters only.")
        .test(
          "City",
          "Invaild city",
          (value) => !containsNumber(value) && !containSpecialChar(value)
        ),
      email: Yup.string().required(t("required-err")).email(t("invalid-email")),
      // .test("Email", t("invalid-email"), (value) => validEmail(value)),
    }),
    onSubmit: async (values) => {
      setbtn_loading(true);
      const formdata = new FormData();
      formdata.append("city", values.city);
      formdata.append("CompanyName", values.company);
      formdata.append("Email", values.email);
      formdata.append("endingHours", closingHours);
      formdata.append("Name", values.name);
      formdata.append("streetNo", values.nr);
      formdata.append("openingHours", openingHours);
      formdata.append("RoleId", selectedRole);
      formdata.append("Surname", values.sirName);
      formdata.append("street", values.street);
      formdata.append("zipCode", values.zipCode);
      // if (
      //   !selectedRole ||
      //   !companyId
      //   // || openingHours === "00:00" ||
      //   // closingHours === "00:00"
      // ) {
      //   if (!selectedRole) {
      //     setroleErr("Role is Required");
      //   }
      //   if (!companyId) {
      //     setcompanyErr("Company is Required");
      //   }
      //   // if (openingHours === "00:00") {
      //   //   setopeningHoursErr("Required");
      //   // }
      //   // if (closingHours === "00:00") {
      //   //   setclosingHoursErr("Required");
      //   // }
      // } else {
      let result = await registerUser(formdata);
      if (result?.data?.success) {
        toast.success(t("registration_message"));
        Navigate("/login");
      } else {
        let [firstValue, ...rest] = t("taken-user").split(" ");
        let errorMsg = [firstValue, `"${values.name}"`, ...rest].join(" ");
        toast.error(result?.data?.message);
      }
      setbtn_loading(false);
      // }
    },
  });

  const handleChange = async (e, key, formik) => {
    let roleName = e.target.options[e.target.selectedIndex].text;

    if (roleName === t("Distributor") || roleName === t("Logistic")) {
      setDisableAll(true);
      setInfoMsg(t("contact-mcyclo"));
    } else {
      setDisableAll(false);
      setInfoMsg("");
    }
    let value = e.target.value.replace(/['"]+/g, "");

    if (key === "Roles") {
      setroleErr("");
      setselectedRole(value);
      // let result = await getCompaniesByRoleId(value);
      // setcompaniesList(result.payLoad);
    } else if (key === "Company") {
      setcompanyErr("");
      setcompanyId(value);
    }
  };

  const checkboxRef = useRef(null);

  return (
    <div className="register-container">
      <Toaster />
      <h1>{t("register-heading")}</h1>
      <div className="d-flex flex-wrap mb-5">
        <p className="d-inline-flex ">{t("register-sub-heading")}</p>
        <Link to="/login" onClick={() => tracking({ value: "/login" })}>
          <AnchorButton
            className="ms-1"
            as="a"
            // onClick={() => back()}
          >
            {t("register-sub-heading-link")}
          </AnchorButton>
        </Link>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Row className="register-form">
          <Col lg={4} md={12} sm={12}>
            <div className="feilds-column d-flex flex-wrap w-100">
              <div className="custom-select-field">
                <CustomDropdown
                  component="registerCard"
                  id="roleSelect"
                  padding="0.28rem !important"
                  name="role"
                  label={t("role")}
                  width="99.9%"
                  list={rolesList}
                  onChange={(e) => handleChange(e, "Roles")}
                  onKeyDown={
                    disableAll
                      ? (e) => {
                          if (e.key === "Tab") {
                            e.preventDefault();
                          }
                        }
                      : null
                  }
                />
                {roleErr && <div className="Error-message">{roleErr} </div>}
              </div>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} className="d-flex align-items-end">
            <h6 style={{ color: "#FFB100" }}>{infoMsg}</h6>
          </Col>
          <Col
            lg={4}
            md={12}
            sm={12}
            style={{
              pointerEvents: disableAll ? "none" : "",
              opacity: disableAll ? "0.4" : "1",
            }}
          >
            <TextInputGroup
              className="input-group"
              id="name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              label={t("name")}
              maxLength="15"
              error={formik.errors.name && formik.touched.name ? true : false}
            />
            {formik.errors.name && formik.touched.name ? (
              <div className="Error-message">{formik.errors.name} </div>
            ) : null}

            <TextInputGroup
              className="input-group"
              id="sirName"
              name="sirName"
              maxLength="15"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.sirName}
              label={t("sirname")}
              error={
                formik.errors.sirName && formik.touched.sirName ? true : false
              }
            />
            {formik.errors.sirName && formik.touched.sirName ? (
              <div className="Error-message">{formik.errors.sirName} </div>
            ) : null}
            <EmailInputGroup
              className="input-group"
              id="email"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              label={t("email")}
              error={formik.errors.email && formik.touched.email ? true : false}
            />
            {formik.errors.email && formik.touched.email ? (
              <div className="Error-message">{formik.errors.email} </div>
            ) : null}
          </Col>
          <Col
            lg={8}
            md={8}
            sm={12}
            style={{
              pointerEvents: disableAll ? "none" : "",
              opacity: disableAll ? "0.4" : "1",
            }}
          >
            <div className="feilds-column d-flex flex-wrap">
              <div
                className="custom-select-field mb-2"
                style={{
                  width: "33ch",
                  pointerEvents: disableAll ? "none" : "",
                }}
              >
                <TextInputGroup
                  className="input-group"
                  id="company"
                  name="company"
                  width="100%"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.company}
                  label={t("company")}
                  maxLength="60"
                  error={
                    formik.errors.company && formik.touched.company
                      ? true
                      : false
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Tab") {
                      e.preventDefault();
                    }
                  }}
                />
                {formik.errors.company && formik.touched.company ? (
                  <div className="Error-message">{formik.errors.company} </div>
                ) : null}
              </div>
            </div>
            <div className="feilds-column d-flex flex-wrap">
              <div
                style={{
                  pointerEvents: disableFew ? "none" : "",
                  opacity: disableFew ? "0.4" : "1",
                }}
              >
                <TextInputGroup
                  className="input-group"
                  id="street"
                  name="street"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.street}
                  label={t("street")}
                  width="23ch"
                  maxLength="25"
                  error={
                    formik.errors.street && formik.touched.street ? true : false
                  }
                />
                {formik.errors.street && formik.touched.street ? (
                  <div className="Error-message">{formik.errors.street} </div>
                ) : null}
              </div>
              <div className="ms-2"></div>
              <div
                style={{
                  pointerEvents: disableFew ? "none" : "",
                  opacity: disableFew ? "0.4" : "1",
                }}
              >
                <TextInputGroup
                  className="input-group"
                  id="nr"
                  name="nr"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.nr}
                  width="8ch"
                  maxLength="10"
                  label={t("nr")}
                  error={formik.errors.nr && formik.touched.nr ? true : false}
                />
                {formik.errors.nr && formik.touched.nr ? (
                  <div className="Error-message">{formik.errors.nr} </div>
                ) : null}
              </div>
            </div>
            <div className=" feilds-column d-flex flex-wrap">
              <div
                style={{
                  pointerEvents: disableFew ? "none" : "",
                  opacity: disableFew ? "0.4" : "1",
                }}
              >
                <TextInputGroup
                  className="input-group  "
                  id="zipCode"
                  name="zipCode"
                  maxLength="10"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    let value = e.target.value;
                    const currentValue = value.replace(/[^\d]/g, "");
                    formik.setFieldValue("zipCode", currentValue);
                  }}
                  value={formik.values.zipCode}
                  width="11ch"
                  label={t("zipcode")}
                  error={
                    formik.errors.zipCode && formik.touched.zipCode
                      ? true
                      : false
                  }
                />
                {formik.errors.zipCode && formik.touched.zipCode ? (
                  <div className="Error-message">{formik.errors.zipCode} </div>
                ) : null}
              </div>
              <div className="ms-2"></div>
              <div
                style={{
                  pointerEvents: disableFew ? "none" : "",
                  opacity: disableFew ? "0.4" : "1",
                }}
              >
                <TextInputGroup
                  className="input-group"
                  id="city"
                  name="city"
                  maxLength="25"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  label={t("city")}
                  error={
                    formik.errors.city && formik.touched.city ? true : false
                  }
                />
                {formik.errors.city && formik.touched.city ? (
                  <div className="Error-message">{formik.errors.city} </div>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
        <Row
          className="d-flex justify-content-between"
          lg={12}
          style={{
            pointerEvents: disableAll ? "none" : "",
            opacity: disableAll ? "0.4" : "1",
          }}
        >
          <Col
            lg={5}
            md={12}
            style={{
              pointerEvents: disableFew || disableAll ? "none" : "",
              opacity: disableFew || disableAll ? "0.4" : "1",
            }}
          >
            <div>
              <div className=" feilds-column d-flex flex-wrap">
                <div className="timekeeper">
                  <label>{t("openinghours")}</label>
                  <div
                    className="timeVal"
                    onClick={() => setshowOpeningHours(true)}
                  >
                    <span>{openingHours}</span>
                    <Time style={{ width: "15px" }} />
                  </div>
                  {showOpeningHours && (
                    <TimeKeeper
                      hour24Mode
                      time={openingHours}
                      onChange={(data) => {
                        return (
                          setopeningHours(data.formatted24),
                          setopeningHoursErr("")
                        );
                      }}
                      doneButton={(newTime) => (
                        <div
                          style={{
                            textAlign: "center",
                            padding: "10px 0",
                            cursor: "pointer",
                          }}
                          onClick={() => setshowOpeningHours(false)}
                        >
                          Close
                        </div>
                      )}
                    />
                  )}
                  {/* {openingHoursErr !== "" ? (
                    <div className="Error-message">{openingHoursErr} </div>
                  ) : null} */}
                </div>
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "400",
                    padding: "0 20px",
                    marginTop: "20px",
                  }}
                >
                  -
                </span>
                <div className="timekeeper">
                  <label>{t("closinghours")}</label>
                  <div
                    className="timeVal"
                    onClick={() => setshowClosingHours(true)}
                  >
                    <span>{closingHours}</span>
                    <Time style={{ width: "15px" }} />
                  </div>
                  {showClosingHours && (
                    <TimeKeeper
                      hour24Mode
                      time={closingHours}
                      onChange={(data) => {
                        return (
                          setclosingHours(data.formatted24),
                          setclosingHoursErr("")
                        );
                      }}
                      doneButton={(newTime) => (
                        <div
                          style={{
                            textAlign: "center",
                            padding: "10px 0",
                            cursor: "pointer",
                          }}
                          onClick={() => setshowClosingHours(false)}
                        >
                          Close
                        </div>
                      )}
                    />
                  )}
                  {/* {closingHoursErr !== "" ? (
                    <div className="Error-message">{closingHoursErr} </div>
                  ) : null} */}
                </div>
              </div>
            </div>
            <div className="info-container">
              <div className="info-icon">
                <p className="fw-bold fs-6">i</p>
              </div>
              <div>
                {t("info")
                  .split("\n")
                  .map((c) => {
                    return <p className="mb-0"> {c} </p>;
                  })}
              </div>
            </div>
          </Col>
          <div className="create-account-btn">
            <p>
              <a href="/termsofuse" target="_blank">
                AGB
              </a>
            </p>
            <div className="checkbox-conatiner">
              <CheckBox
                label={t("registration-consent")}
                marginBottom="mb-2"
                className="checkBox"
                onChange={() => setAgree(!agree)}
                ref={checkboxRef}
              />
            </div>
            {agree ? (
              !btn_loading ? (
                <SubmitButton> {t("create-account-button-label")}</SubmitButton>
              ) : (
                <SubmitButton>
                  {" "}
                  <Spinner animation="border" />
                </SubmitButton>
              )
            ) : (
              <DeactiveButton pointerEvent="none">
                {t("create-account-button-label")}
              </DeactiveButton>
            )}
          </div>
        </Row>
      </form>
    </div>
  );
};
RegisterCard.propTypes = {
  back: PropTypes.any,
};
export default RegisterCard;
