import React, { useState } from "react";
import PulseIcon from "../assets/icons/PulseIcon.png";
import IndicationIcon from "../assets/icons/IndicationIcon.png";
import BarCode from "../assets/icons/BarCode.png";
import Rectangle1 from "../assets/icons/Rectangle1.png";
import Rectangle2 from "../assets/icons/Rectangle2.png";
import MaterialListModal from "../common/modals/MaterialList";
import DeleteIcon from "../assets/icons/DeleteIcon";
import { StyledButton } from "../common/Button.style";
import ItemQuantity from "../common/modals/ItemQuantity";
import ThankingNote from "../common/modals/ThankingNote";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
const MaterialList = (props) => {
  const {
    handleAddItem,
    handleDeleteItem,
    setMaterialSelectionList,
    materialSelectionList,
    itemsList,
  } = props;
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [dropOffShow, setDropOffShow] = useState(false);
  const [distributorShow, setDistributorShow] = useState(false);
  const [thanksNoteShow, setThanksNoteShow] = useState(false);
  const [dropOffItem, setDropOffItem] = useState({});
  const [quantity, setQuantity] = useState(1);
  const handleDropOff = (item) => {
    setDropOffItem(item);
    setDropOffShow(true);
  };
  return (
    <div className="material-list-container">
      <div className="header">
        <div className="select-item-tab">
          <p className="m-0 select-item-tab-p">
            {t("material-option-heading")}
          </p>
          <span className="select-item-tab-btn" onClick={() => setShow(true)}>
            <img src={PulseIcon} alt="pulseicon" />
          </span>
        </div>
        <div
          className={`${
            materialSelectionList.length === 0 ? "d-flex" : "d-none"
          } suggestion-container`}
        >
          <h6 className="suggestion-container-h">
            {t("choose-material-sub-heading1")}
          </h6>
          <div className="w-75">
            <p className="suggestion-container-p">
              {t("choose-material-sub-heading2")}
            </p>
          </div>
          <img
            src={IndicationIcon}
            alt="indicationicon"
            className="indicator-img"
          />
        </div>
      </div>
      <div
        className={`${
          materialSelectionList.length === 0 ? "d-flex" : "d-none"
        } bar-code-container`}
      >
        <img src={BarCode} alt="barcodeicon" className="bar-code" />
        <img src={Rectangle1} alt="rectangleicon" className="rectangle-1" />
        <img src={Rectangle2} alt="rectangle2icon" className="rectangle-2" />
      </div>
      <div
        className={`${
          materialSelectionList.length === 0 ? "d-flex flex-column" : "d-none"
        } mt-2 align-items-center`}
      >
        <h2 className="text-center fw-bold">
          {t("choose-material-sub-heading3")}
        </h2>
        <div style={{ width: "170px" }}>
          <p className="text-center" style={{ fontSize: "12px" }}>
            {t("choose-material-sub-heading4")}
          </p>
        </div>
      </div>
      <div
        className={`${
          materialSelectionList.length > 0 ? "d-flex" : "d-none"
        } selected-items-container`}
      >
        {materialSelectionList?.map((item, index) => {
          return (
            <div key={index} className="selected-item">
              <div className="item-content">
                <span className="item-content-h1">
                  {t("choose-material-heading")}
                </span>
                <span className="item-content-h2">{item.name}</span>
                <span className="item-content-p">
                  {item.properties + "," + item.materialType}
                </span>
              </div>
              <div className="item-icon">
                <div
                  className="delete-icon"
                  onClick={() => handleDeleteItem(item.materialId)}
                >
                  <DeleteIcon style={{ width: "20px", fill: "#626C83" }} />
                </div>
              </div>
              <div className="d-flex justify-content-end w-100">
                <StyledButton
                  padding={"0.5rem 1rem"}
                  onClick={() => handleDropOff(item)}
                >
                  {t("dropoff-button-label")}
                </StyledButton>
              </div>
            </div>
          );
        })}
      </div>
      <ItemQuantity
        dropOffItem={dropOffItem}
        show={dropOffShow}
        quantity={quantity}
        distributorShow={distributorShow}
        materialSelectionList={materialSelectionList}
        setQuantity={setQuantity}
        setDropOffShow={setDropOffShow}
        setThanksNoteShow={setThanksNoteShow}
        setDistributorShow={setDistributorShow}
        setMaterialSelectionList={setMaterialSelectionList}
      />
      <ThankingNote
        show={thanksNoteShow}
        close={() => {
          setThanksNoteShow(false);
        }}
      />
      <MaterialListModal
        handleAddItem={handleAddItem}
        show={show}
        itemsList={itemsList}
        setShow={setShow}
        className="icon"
      />
    </div>
  );
};
MaterialList.propTypes = {
  handleAddItem: PropTypes.any,
  handleDeleteItem: PropTypes.any,
  setMaterialSelectionList: PropTypes.any,
  materialSelectionList: PropTypes.any,
  itemsList: PropTypes.any,
};
export default MaterialList;
