import React from "react";
import "../common.scss";
import { useTranslation } from "react-i18next";
import "../common.scss";

const Footer = () => {
  const { t } = useTranslation();
  const footerLinks = [
    { title: t("footer-link-one"), link: "/imprint" },
    { title: t("footer-link-two"), link: "/termsofuse" },
    { title: t("footer-link-three"), link: "/privatepolicy" },
    { title: t("footer-link-four"), link: "mailto:mcyclo@basf.com" },
  ];
  return (
    <div className="footer">
      <ul>
        {footerLinks.map((el, i) => {
          return (
            <li key={i} style={{ listStyleType: "none" }}>
              <a href={el.link} target="_blank">
                {el.title}
              </a>
            </li>
          );
        })}
        <li className="optanon-toggle-display cookies">Cookies</li>
      </ul>
    </div>
  );
};

export default Footer;
