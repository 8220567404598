import React, { useState } from "react";
import { StyledButton } from "../common/Button.style";
//import { S3_BASE_URL } from "../common/utils/Utilities";
import TickIcon from "../assets/icons/tick.png";
import "./components.scss";
import { environment } from "../Environments/Environment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { GET_JOB_Image_URL } from "../constants/ApiUrls";

const InfoCard = (props) => {
  const {
    jobPicture,
    jobId,
    companyName,
    dropDate,
    amountOfBags,
    materialName,
    distributorCompanyNameLocation,
    dropOffLocation,
    DeleteSelectItem,
    isAdded,
  } = props;
  const [loaded, setLoaded] = useState(false);

  const { handleSelectItem, tabId } = props;

  const { t } = useTranslation();

  const handleAddSelectedItem = (
    companyName,
    amountOfBags,
    materialName,
    jobId
  ) => {
    const item = {
      companyName,
      amountOfBags,
      materialName,
      jobId,
      isAdded: true,
    };
    handleSelectItem(item);
  };
  return (
    <div className="info-card-container row m-0 gx-0">
      <div
        className={`${isAdded || tabId !== 1 ? "right-triangle" : "d-none"}`}
      >
        <img src={TickIcon} className="tick-image" alt="tick" />
      </div>
      <div className="info-card-image-container col-lg-4 col-md-12">
        <img
          src={environment.BASE_URL + `${GET_JOB_Image_URL}?Key=${jobPicture}`}
          loading="lazy"
          //alt="info"
          className="info-card-image"
        />
      </div>
      <div className="info-card-subcontainer row col gx-0">
        <div className="info-card-item-row row">
          <div className="info-card-item col-lg-4 col-md-6 col-sm-12">
            <p className="info-card-item-label">{t("company")}</p>
            <p className="fw-bold info-card-item-property">{companyName}</p>
          </div>
          <div className="info-card-item col-lg-4 col-md-6 col-sm-12">
            <p className="info-card-item-label">{t("dropdate")}</p>
            <p className="fw-bold info-card-item-property">
              {" "}
              {dropDate?.substring(0, 10).split("-").reverse().join(".")}
            </p>
          </div>
        </div>
        <div className="info-card-item-row row">
          <div className="info-card-item col-lg-4 col-md-6 col-sm-12">
            <p className="info-card-item-label">{t("amount")}</p>
            <p className="info-card-item-property">
              {amountOfBags} {amountOfBags > 1 ? t("bags") : t("bag")}
            </p>
          </div>
          <div className="info-card-item col-lg-6 col-md-6 col-sm-12">
            <p className="info-card-item-label">{t("material")}</p>
            <p className="info-card-item-property">{materialName}</p>
          </div>
        </div>
        <div className="info-card-item-row row gx-0">
          <div className="info-card-item col-lg-8 col-md-12">
            <p className="info-card-item-label">{t("dropoff-location")}</p>
            <p className="info-card-item-property">
              {distributorCompanyNameLocation != null
                ? distributorCompanyNameLocation
                : dropOffLocation}
            </p>
          </div>
          <div className="btn-container col-lg-4 col-md-12 d-flex align-items-end">
            {tabId === 1 ? (
              isAdded === true ? (
                <StyledButton
                  borderRadius={"0"}
                  color={"#fff"}
                  padding={"0.5rem 2.5rem"}
                  onClick={() => DeleteSelectItem(jobId)}
                >
                  {t("unselect-button-label")}
                </StyledButton>
              ) : (
                <StyledButton
                  borderRadius={"0"}
                  color={"#fff"}
                  padding={"0.5rem 2.5rem"}
                  onClick={() =>
                    handleAddSelectedItem(
                      companyName,
                      amountOfBags,
                      materialName,
                      jobId
                    )
                  }
                >
                  {t("select-button-label")}
                </StyledButton>
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
InfoCard.propTypes = {
  jobPicture: PropTypes.any,
  jobId: PropTypes.any,
  companyName: PropTypes.any,
  dropDate: PropTypes.any,
  amountOfBags: PropTypes.any,
  materialName: PropTypes.any,
  dropOffLocation: PropTypes.any,
  isAdded: PropTypes.any,
  handleSelectItem: PropTypes.any,
  tabId: PropTypes.any,
};
export default InfoCard;
