import React from "react";
import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const LinkExpired = () => {
  return (
    <Container>
      <Card className="mt-5 link-expired not-found">
        <Card.Header className="text-white">Link is Expired</Card.Header>
        <Card.Body className="mt-5">
          <Card.Text>
            <Link to="/">Go to Home page</Link>
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default LinkExpired;
