import {
  GET_DASHBOARD_DATA_URL,
  VERIFY_TOKEN_URL,
  GET_JOB_Image_URL,
} from '../constants/ApiUrls';
import { environment } from '../Environments/Environment';
import { getMethod } from '../Network/NetworkManager';
import axios from 'axios';

export const getDashboardData = async () => {
  let data;
  let result = await getMethod(environment.BASE_URL + GET_DASHBOARD_DATA_URL);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};
export const verifyToken = async (id) => {
  let data;
  let result = await getMethod(
    environment.BASE_URL + VERIFY_TOKEN_URL + '/' + id
  );
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};
export const getJobImage = async (image) => {
  let mainResult = '';
  let result = await axios
    .get(environment.BASE_URL + `${GET_JOB_Image_URL}?Key=${image}`, {
      responseType: 'blob',
    })
    .then((response) => {
      let base64String = '';
      let type = '';
      type = 'image/png, image/gif, image/jpeg';
      const file = new Blob([response.data], { type: type });
      mainResult = { blob: URL.createObjectURL(file), status: response.status };
    });

  return mainResult;
};
