import React, { useState, useEffect } from "react";
import { StyledButton } from "../Button.style";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const TabButton = ({ activeId, name, id, setVarient }) => (
  <StyledButton
    variant={`${activeId === id ? "" : "outline"}`}
    borderRadius="0"
    fs="max(12px,0.6vh)"
    minWidth="160px"
    padding="3px 6px"
    color={`${activeId === id ? "white" : "black"}`}
    onClick={setVarient}
    margin="auto"
  >
    {name}
  </StyledButton>
);
const TabsHeader = ({ setTabId }) => {
  const [activeId, setActiveId] = useState(1);

  const { t } = useTranslation();

  const tabsButttons = [
    { name: `${t("distributor-tab1")}`, id: 1 },
    { name: `${t("distributor-tab2")}`, id: 2 },
    { name: `${t("distributor-tab3")}`, id: 3 },
  ];

  useEffect(() => setTabId(activeId), [activeId]);
  const tabsButtonsArray = tabsButttons.map((el, index) => (
    <div key={index} style={{ margin: "1px" }}>
      <TabButton
        activeId={activeId}
        key={el.id}
        name={el.name}
        id={el.id}
        setVarient={() => {
          setActiveId(el.id);
        }}
      ></TabButton>
    </div>
  ));
  return <div className="tabs-container d-flex">{tabsButtonsArray}</div>;
};
TabsHeader.propTypes = {
  setTabId: PropTypes.any,
};
TabButton.propTypes = {
  activeId: PropTypes.any,
  name: PropTypes.any,
  id: PropTypes.any,
  setVarient: PropTypes.any,
};

export default TabsHeader;
