import React, { useEffect, useState } from "react";
import { Form, Formik, Field } from "formik";
import { StyledButton } from "../../common/Button.style";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useCallback } from "react";

import CompaniesDropDown from "../../common/ui/CompaniesDropDown";
// import * as Yup from "yup";
// import InfoCard from "../../components/InfoCard";
import OpeningHours from "../../common/modals/OpeningHours";
import ActualPickup from "../../common/modals/ActualPickup";
import Navbar from "./../../components/Navbar";
import TabsHeader from "../../common/ui/TabsHeader";
import OpenJobsCollection from "../../components/OpenJobsCollection";
import "./jobcollectionpage.scss";
import FilterCard from "../../common/ui/FilterCard";
import QuantityCard from "../../components/QuantityCard";
import JobsCollection from "./../../components/JobsCollection";
import InfoOfDrop from "../../common/modals/InfoOfDrop";
import { getAllusers, getAllCompanies } from "../../services/JobService";
import { CreateOrder } from "../../services/OrderServices";
import PropTypes from "prop-types";
//import { getAllOrdersCollection } from "../../services/OrderServices";
import { useTranslation } from "react-i18next";
import { getTranslatedMessage } from "../../common/utils/Utilities";
import { includes } from "ramda";
// import ReactPaginate from "react-paginate";
// import { getJobImage } from "../../services/CommonServices";
const JobsCollectionPage = () => {
  const { t } = useTranslation();
  const [openingHoursModal, setOpeningHoursModal] = useState(false);
  const [actualPickupModal, setActualPickupModal] = useState(false);
  const [infoOfDropModal, setInfoOfDropModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [apiResult, setapiResult] = useState([]);
  const [selectionList, setSelectionList] = useState([]);
  const [tabId, setTabId] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [infoCardList, setInfoCardList] = useState([]);
  const [checkChanges, setcheckChanges] = useState(false);
  const [apiLoading, setapiLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [company, setCompany] = useState({});
  const PICK_UP = "Abholung";
  const DROP_OFF = "Anlieferung";
  const [btn_loading, setbtn_loading] = useState(false);
  useEffect(() => {
    if (tabId === 1) {
      getUsers();
    }
  }, [tabId, checkChanges, pageNumber, selectValue]);

  useEffect(() => {
    // getCompanies();
  }, []);

  // const getListing = async () => {
  //   // setapiLoading(true);

  //   if (tabId === 1) {
  //     let result = await getAllOrdersCollection({
  //       pageNumber,
  //       OrderBy: selectValue,
  //     });
  //     if (result?.success) {
  //       setapiResult(result.payLoad);
  //       // setapiLoading(false);
  //     }
  //   }
  // };

  const getUsers = async () => {
    setapiLoading(true);
    const Users = await getAllusers({
      OrderBy: selectValue,
      PageNumber: pageNumber,
    });
    if (Users?.success) {
      setapiResult(Users?.payLoad);
      setInfoCardList(Users.payLoad?.items);
      setapiLoading(false);
    } else {
      toast.error(Users?.message);
      setapiLoading(false);
    }
    if (Users.payLoad?.items < 1 && pageNumber !== 1) {
      setPageNumber(pageNumber - 1);
    } else {
      setPageNumber(pageNumber);
    }

    // let arr = [];
    // for (let i = 0; i < Users.payLoad?.items.length; i++) {
    //   let elementI = Users.payLoad?.items[i];
    //   let result = await getJobImage(elementI?.jobPicture);
    //   if (result.status) {
    //     elementI.jobPicture = result.blob;
    //     arr.push(elementI);
    //   }
    // }

    //setSelectionList([]);
  };
  // useEffect(() => {
  //   setapiResult([]);
  //   setPageNumber(1);
  // }, [tabId]);
  const changeTab = (tabId) => {
    setapiResult([]);
    setPageNumber(1);
    setTabId(tabId);
  };
  const handleNextPage = async (page) => {
    let pageNumber = page.selected + 1;
    setPageNumber(pageNumber);
  };

  const getCompanies = async () => {
    const companies = await getAllCompanies();
    setAllCompanies(companies.payLoad.items);
  };
  const RenderTab = ({ tabId }) => {
    if (tabId === 1) {
      //  return (
      // <div className="accordion-details-card">
      //   <Row>
      //     <Col lg={12}>
      //       <ReactPaginate
      //         previousLabel={"<"}
      //         nextLabel={">"}
      //         breakLabel={"..."}
      //         breakClassName={"break-me"}
      //         pageCount={ApiResult?.totalPages}
      //         marginPagesDisplayed={2}
      //         pageRangeDisplayed={2}
      //         onPageChange={handleNextPage}
      //         containerClassName={`pagination ${"pagination-orange"}`}
      //         activeClassName={"active"}
      //         activeLinkClassName={"active-link"}
      //         forcePage={pageNumber - 1}
      //       />

      //       {!ApiLoading ? (
      //         infoCardList?.map((item, index) => {
      //           return (
      //             <div key={index} className="left-side bg-white">
      //               <div className="head" />
      //               <div className="body">
      //                 <InfoCard
      //                   tabId={tabId}
      //                   {...item}
      //                   handleSelectItem={handleSelectItem}
      //                   DeleteSelectItem={DeleteSelectItem}
      //                   isAdded={
      //                     selectionList.findIndex(
      //                       (el) => el.jobId === item.jobId
      //                     ) === -1
      //                       ? false
      //                       : true
      //                   }
      //                 />
      //               </div>
      //             </div>
      //           );
      //         })
      //       ) : (
      //         <div
      //           className="d-flex justify-content-center align-items-center"
      //           style={{
      //             height: "60vh",
      //           }}
      //         >
      //           <Spinner animation="border" />
      //         </div>
      //       )}
      //     </Col>
      //   </Row>
      // </div>
      return (
        <OpenJobsCollection
          handleSelectItem={handleSelectItem}
          selectedValue={selectValue}
          tabId={tabId}
          selectionList={selectionList}
          setSelectionList={setSelectionList}
          apiResult={apiResult}
          handleNextPage={handleNextPage}
          pageNumber={pageNumber}
          apiLoading={apiLoading}
        />
      );
      //  );
    } else if (tabId === 2) {
      return <JobsCollection selectedValue={selectValue} tabId={tabId} />;
    } else if (tabId === 3) {
      return <JobsCollection selectedValue={selectValue} tabId={tabId} />;
    }
    return <JobsCollection selectedValue={selectValue} tabId={tabId} />;
  };
  RenderTab.propTypes = {
    tabId: PropTypes.any,
  };
  const handleChange = (e, key) => {
    let value = e.target.value;
    if (key === "selectValue") {
      setSelectValue(value);
    } else if (key === "searchInput") {
      setSearchInput(value);
    }
  };
  const list = JSON.parse(localStorage.getItem("dashboardData"));
  const handleSelectItem = useCallback((item) => {
    if (!selectionList.find((el) => el.jobId === item.jobId)) {
      setSelectionList([...selectionList, item]);
    } else {
      DeleteSelectItem(item);
    }
    //handleChangeStatus(item);
  }, []);
  const DeleteSelectItem = (id) => {
    setSelectionList(selectionList.filter((obj) => obj.jobId !== id));
    // handleDelStatus(item);
  };

  const handleChangeStatus = (item) => {
    const updatedList = infoCardList?.map((ele) => {
      if (ele.jobId === item.jobId) {
        return Object.assign(ele, item);
      } else {
        return ele;
      }
    });
    setInfoCardList(updatedList);
  };
  const handleDelStatus = (item) => {
    const updatedList = infoCardList?.map((ele) => {
      if (ele.jobId === item) {
        const itemObj = {
          ...ele,
          isAdded: false,
        };
        return Object.assign(ele, itemObj);
      } else {
        return ele;
      }
    });
    setInfoCardList(updatedList);
  };
  const handleSubmit = async () => {
    setbtn_loading(true);
    //setInfoOfDropModal(true);
    // selectionList.items.filter
    let payLoadItems = [];
    selectionList?.map((item) =>
      payLoadItems.push({
        jobId: item.jobId,
        totalAmountOfBags: item.amountOfBags,
        quantity: 0,
      })
    );
    let obj = {
      orderItems: payLoadItems,
      logisticsCompanyId: company.companyId,
      type: company.name.includes(DROP_OFF)
        ? DROP_OFF
        : company.name.includes(PICK_UP)
        ? PICK_UP
        : "",
    };
    setapiLoading(true);
    let create = await CreateOrder(obj);
    if (create?.success) {
      toast.success(getTranslatedMessage(create.message));
      setSelectionList([]);
      setcheckChanges(!checkChanges);
      setCompany({});
    } else if (create?.error) {
      toast.success(getTranslatedMessage(create.statusText));
      setapiLoading(false);
    }
    setShow(false);
    setbtn_loading(false);
  };
  const handleCollection = () => {
    getCompanies();
    setShow(true);
  };
  const handleClose = () => {
    setInfoOfDropModal(false);
    setSelectionList([]);
    setInfoCardList(resetInfoCardList());
  };

  const resetInfoCardList = () => {
    const arr = [];
    for (let cardListItem in infoCardList) {
      if (infoCardList[cardListItem].isSelected === true) {
        arr.push({ ...infoCardList[cardListItem], isSelected: false });
      } else {
        arr.push(infoCardList[cardListItem]);
      }
    }

    return arr;
  };

  const renderCompanyInfo = () => {
    const {
      name = "",
      isPickUp = null,
      isDropOff = null,
      streetNo = "",
      street = "",
      dropOffPrice = "",
      pickUpPrice = "",
      city = "",
      zipCode = "",
      isDropOffPriceRequest,
      dropOffEmailOrNumber,
      isPickUpPriceRequest,
      pickUpEmailOrNumber,
    } = company;

    return name.includes(PICK_UP) && isPickUpPriceRequest ? (
      <p className="mb-0 mt-2 w-75 " style={{ textAlign: "center" }}>
        {`Preis unter ${pickUpEmailOrNumber} anfragen.`}
      </p>
    ) : name.includes(PICK_UP) && isPickUp ? (
      <p className="mb-0 mt-2 w-75 " style={{ textAlign: "center" }}>
        {`${t("fixed-cost-per-pickup")}: ${pickUpPrice} €`}
      </p>
    ) : name.includes(DROP_OFF) && isDropOffPriceRequest ? (
      <p className="mb-0 mt-2 w-75 " style={{ textAlign: "center" }}>
        {`Preis unter ${dropOffEmailOrNumber} anfragen.`}
      </p>
    ) : name.includes(DROP_OFF) && isDropOff ? (
      <p className="mb-0 mt-2 w-75" style={{ textAlign: "center" }}>
        {`${t("fixed-cost-per-dropoff")}: ${dropOffPrice} €`}
      </p>
    ) : null;
  };

  return (
    <>
      <OpeningHours
        show={openingHoursModal}
        close={() => setOpeningHoursModal(false)}
      />
      <ActualPickup
        show={actualPickupModal}
        close={() => setActualPickupModal(false)}
      />
      <Navbar />
      <div className="my-page my-page-margin-custom mt-5">
        <h1>{t("main-heading")}</h1>
        <Row>
          <Col lg={8}>
            <div className="filter-container">
              <FilterCard
                handleChange={(e) => handleChange(e, "selectValue")}
                handleSearch={(e) => handleChange(e, "searchInput")}
                value={searchInput}
                dropdownValue={selectValue}
                list={
                  tabId === 1 ? list?.jobRequest : list?.orderCollectionRequest
                }
              />
              <TabsHeader setTabId={(id) => changeTab(id)} />
            </div>
            <RenderTab tabId={tabId} />
          </Col>
          <Col lg={4}>
            <QuantityCard
              selectionList={selectionList}
              handleCollection={handleCollection}
              apiLoading={apiLoading}
              handleSubmit={handleSubmit}
            />
          </Col>
        </Row>
        <InfoOfDrop
          show={infoOfDropModal}
          close={handleClose}
          selectionList={selectionList}
        />
      </div>
      {show && (
        <Modal
          contentClassName="company-custom-modal"
          backdropClassName="company-custom-modal-backdrop"
          show={show}
          centered
          size="md"
        >
          <div className="close-icon-div d-flex justify-content-end">
            <img
              src="/assets/icons/cross-icon1.png"
              alt="cross-btn"
              onClick={() => {
                setCompany({});
                setShow(false);
              }}
              className="custom-cursor"
            />
          </div>
          <Formik
            initialValues={{
              companies: "",
            }}
          >
            {(formik) => (
              <>
                <div className="actual-pickup-modal custom-modal-body bg-white p-5">
                  <h5 className="text-center" style={{ fontSize: "14px" }}>
                    {t("heading-modal1")}
                  </h5>

                  <Row>
                    <Col lg={12}>
                      <Form>
                        <div className="d-flex justify-content-center">
                          <div className=" col-md-7 justify-content-center">
                            <CompaniesDropDown
                              width="100%"
                              list={allCompanies}
                              onClickFun={(company) => {
                                setCompany(company);
                              }}
                              prompt={t("select-button-label")}
                              value={company?.name}
                            />
                            {/* <Field
                              as="select"
                              name="companies"
                              className="w-100"
                              value={
                                company?.companyId + "&Name=" + company?.name
                              }
                              onChange={(e) => {
                                const [id, name] =
                                  e.target.value.split("&Name=");
                                const selectedCompanay = allCompanies.find(
                                  (el) => el.companyId == id && el.name == name
                                );
                                setCompany(selectedCompanay);
                              }}
                            >
                              <option disabled defaultValue value="">
                                {t("Select")}
                              </option>
                              {allCompanies?.map((elem) => {
                                return (
                                  <option
                                    key={elem?.companyId}
                                    value={
                                      elem?.companyId + "&Name=" + elem?.name
                                    }
                                  >
                                    {elem?.name}
                                  </option>
                                );
                              })}
                            </Field> */}
                          </div>
                        </div>
                        <div className="d-flex justify-content-center">
                          {!company.hasOwnProperty("companyId")
                            ? null
                            : renderCompanyInfo()}
                        </div>
                      </Form>
                    </Col>
                  </Row>
                  {company.hasOwnProperty("companyId") && formik.isValid ? (
                    <div className="text-center">
                      {!btn_loading ? (
                        <StyledButton
                          style={{ border: "2px solid #ffb100" }}
                          type="submit"
                          className="submit-btn mt-4"
                          onClick={() => handleSubmit(formik)}
                        >
                          {t("confirm-button-label")}
                        </StyledButton>
                      ) : (
                        <button
                          style={{ padding: "0.8rem 3.4rem" }}
                          disabled
                          className="submit-btn mt-4"
                        >
                          <Spinner animation="border" />
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="text-center">
                      <button
                        disabled
                        type="submit"
                        className="submit-btn mt-4 btn-disabled"
                      >
                        {t("confirm-button-label")}
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};

export default JobsCollectionPage;
