import React from "react";
import "./footerlinks.scss";

import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NavHome from "../../components/Navbar";
import Footer from "../../common/ui/Footer";

const Policy = () => {
  const { t } = useTranslation();
  return (
    <div className="private-policy">
      <NavHome />
      <div className="main-div container">
        <div className="d-flex link">
          <p>Start </p>
          <p>&nbsp;/ {t("policy-link")}</p>
        </div>
        <div className="d-flex align-items-center w-100">
          <h1 className="fw-bold me-5">{t("policy-main-heading")}</h1>
        </div>
        <div>
          <Row className="custom-row">
            <Col lg={6} sm={12} id="content">
              <p>
                {t("policy-p1")}
                <br />
                {t("policy-p2")}
                <br />
                <br />
                {t("policy-p3")}
                <br />
                {t("policy-p4")}
                <br />
                {t("policy-p5")}
                <br />
                {t("policy-p6")}
                <br />
                {t("policy-p7")}
                <br />
                {t("policy-p8")}
                <br />
                {t("policy-p9")}
                <br />
                {t("policy-p10")}
                <br />
                <br />
                <b>{t("policy-p11")}</b>
                <br />
                <br />
                {t("policy-p12")}
                <br />
                <br />
                {t("policy-p13")}
                <br />
                {t("policy-p14")}
                <br />
                {t("policy-p15")}
                <br />
                {t("policy-p16")}
                <br />
                {t("policy-p17")}
                <br />
                {t("policy-p51")}
                <br /> <br />
                <b>{t("policy-p18")}</b>
                <br />
                {t("policy-p19")}
                <br />
                {t("policy-p20")}
                <br />
                {t("policy-p21")}
                <br />
                {t("policy-p22")}
                <br />
                {t("policy-p23")}
                <br />
                {t("policy-p24")}
                <br />
                {t("policy-p25")}
                <br />
                {t("policy-p26")}
                <br />
                {t("policy-p27")}
                <br />
                {t("policy-p28")}
                <br />
                {t("policy-p29")}
                <br />
                {t("policy-p30")}
                <br />
                {t("policy-p31")}
                <br />
                {t("policy-p32")}
                <br />
                {t("policy-p33")}
                <br />
                {t("policy-p34")}
                <br />
                {t("policy-p35")}
                <br />
                {t("policy-p36")}
                <br />
                {t("policy-p37")}
                <br />
                {t("policy-p38")}
                <br />
                {t("policy-p39")}
                <br />
                {t("policy-p40")}
                <br />
                {t("policy-p41")}
                <br />
                {t("policy-p42")}
                <br />
                {t("policy-p43")}
                <br />
                {t("policy-p44")}
                <br />
                {t("policy-p45")}
                <br />
                {t("policy-p46")}
                <br />
                {t("policy-p47")}
                <br />
                {t("policy-p48")}
                <br />
                {t("policy-p49")}
                <br />
                {t("policy-p50")}
                <br />
                {t("policy-p52")}
                <br />
                {t("policy-p53")}
                <br />
                {t("policy-p54")}
                <br />
                {t("policy-p55")}
                <br />
                {t("policy-p56")}
                <br />
                {t("policy-p57")}
                <br />
                {/* {t("policy-p58")} */}
                
                {t("policy-p59")}
                <div id="ot-sdk-cookie-policy"></div>
                <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
                <br />
                <br />
                {t("policy-p60")}
                
                <br />
                {t("policy-p61")}
                <br />
                {t("policy-p62")}
                <br />
                {t("policy-p63")}
                <br />
                {t("policy-p64")}
                <br />
                {t("policy-p65")}
                <br />
                {t("policy-p66")}
                <br />
                {t("policy-p67")}
                <br />
                {t("policy-p68")}
                <br />
                {t("policy-p69")}
                <br />
                {t("policy-p70")}
                <br />
                <br />
                {t("policy-p71")}
                <br />
                <br />
                {t("policy-p72")}
                <br />
                <br />
                {t("policy-p73")}
                <br />
                <br />
                {t("policy-p74")}
                <br />
                <br />
                {t("policy-p75")}
                <br />
                <br />
                {t("policy-p76")}
                <br />
                <br />
                {t("policy-p77")}
                <br />
                <br />
                {t("policy-p78")}
                <br />
                <br />
                {t("policy-p79")}
                <br />
                {t("policy-p80")}
                <br />
                {t("policy-p81")}
                <br />
                {t("policy-p82")}
                <br />
                {t("policy-p83")}
                <br />
                {t("policy-p84")}
                <br />
                {t("policy-p85")}
                <br />
                {t("policy-p86")}
                <br />
                {t("policy-p87")}
                <br />
                {t("policy-p88")}
                <br />
                {t("policy-p89")}
                <br />
                <br />
                <b>{t("policy-p90")}</b>
                <br />
                {t("policy-p91")}
                <br />
                {t("policy-p92")}
                <br />
                {t("policy-p93")}
                <br />
                {t("policy-p94")}
                <br />
                {t("policy-p95")}
                <br />
                {t("policy-p96")}
                <br />
                {t("policy-p97")}
                <br />
                {t("policy-p98")}
                <br />
                <br />
                <b>{t("policy-p99")}</b>
                <br />
                {t("policy-p99-2")}
                <br />
                {t("policy-p100")}
                <br />
                {t("policy-p101")}
                <br />
                {t("policy-p102")}
                <br />
                {t("policy-p103")}
                <br />
                {t("policy-p104")}
                <br />
                {t("policy-p105")}
                <br />
                {t("policy-p106")}
                <br />
                {t("policy-p107")}
                <br />
                {t("policy-p108")}
                <br />
                {t("policy-p109")}
                <br />
                {t("policy-p110")}
                <br />
                <br />
                <b>{t("policy-p111")}</b>
                <br />
                {t("policy-p111-2")}
                <br />
                <br />
                <b>{t("policy-p112")}</b>
                <br />
                {t("policy-p112-2")}
                <br />
                {t("policy-p113")}
                <br />
                {t("policy-p114")}
                <br />
                {t("policy-p115")}
                <br />
                {t("policy-p116")}
                <br />
                {t("policy-p117")}
                <br />
                <br />
                {t("policy-p118")}
                <br />
                {t("policy-p119")}
                <br />
                <br />
                <b>{t("policy-p120")}</b>
                <br />
                {t("policy-p121")}
                <br />
                {t("policy-p122")}
                <br />
                {t("policy-p123")}
                <br />
                {t("policy-p124")}
                <br />
                {t("policy-p125")}
                <br />
                <br />
                <b>{t("policy-p126")}</b>
                <br />
                {t("policy-p126-2")}
              </p>
            </Col>
            <Col lg={6}>
              <img src={"/assets/images/basf-logo2.png"} id="image" />
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Policy;
