import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const FilterCard = (props) => {
  const { handleChange, dropdownValue, list, marginRight } = props;
  const { t } = useTranslation();
  return (
    <div className="filters d-flex align-items-center">
      <div className="sorting__section">
        <label
          className="mt-2"
          style={{
            fontSize: "16px",
            width: "60px",
          }}
        >
          {t("option-label")}
        </label>
        <select
          className="mt-2"
          value={dropdownValue}
          onChange={handleChange}
          style={{
            border: `1px solid #f39500`,
            marginRight: marginRight,
          }}
        >
          <option disabled defaultValue value="">
            {t("Select")}
          </option>
          {list?.map((item) => (
            <option key={item.key} value={item?.key}>
              {t(item.value.replace(/\s+/g, "-").toLowerCase())}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
FilterCard.propTypes = {
  handleChange: PropTypes.any,
  handleSearch: PropTypes.any,
  dropdownValue: PropTypes.any,
  value: PropTypes.any,
  list: PropTypes.any,
  filterTable: PropTypes.any,
  newFieldInFilter: PropTypes.any,
  marginRight: PropTypes.any,
  noexporticon: PropTypes.any,
};
export default FilterCard;
