import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AnchorButton, SubmitButton } from "../common/Button.style";
import { TextInputGroup, PasswordInputGroup } from "../common/InputGroup.style";
import "./components.scss";
import { authenticateUser, getTranslations } from "../services/AuthService";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { getDashboardData } from "../services/CommonServices";
import { useTranslation } from "react-i18next";
import { getTrackingParams } from "../common/utils/PageDetails";
import { getTranslatedMessage } from "../common/utils/Utilities";
import { Spinner } from "react-bootstrap";

const LoginCard = () => {
  const [btn_loading, setbtn_loading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  let role = localStorage.getItem("role");
  const tracking = (item) => {
    let params = getTrackingParams(item);
    window.utag.view(params);
  };
  useEffect(() => {
    if (role) {
      if (role === "Distributor") {
        navigate("/collection");
        tracking({ value: "/collection" });
      } else if (role === "Logistic") {
        navigate("/jobs");
        tracking({ value: "/jobs" });
      } else if (role === "Construction Site") {
        navigate("/profile");
        tracking({ value: "/profile" });
      }
    }
  }, [role]);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("invalid-email"))
        .label("Email")
        .required(t("email-rq")),
      password: Yup.string()
        .label("Password")
        .required(t("password-rq"))
        .min(8, t("password-field-min-err"))
        .max(35, t("password-field-max-err")),
    }),
    onSubmit: async (values) => {
      setbtn_loading(true);
      let result = await authenticateUser(values);

      if (result.status === 200) {
        localStorage.setItem("userId", result.data.payLoad.userId);
        localStorage.setItem("email", result.data.payLoad.email);
        localStorage.setItem("name", result.data.payLoad.name);
        localStorage.setItem("token", result.data.payLoad.token);
        localStorage.setItem("role", result.data.payLoad.role.name);
        localStorage.setItem("roleId", result.data.payLoad.role.roleId);
        localStorage.setItem("companyName", result.data.payLoad.company.name);
        let translations = await getTranslations("de");
        localStorage.setItem("translations", JSON.stringify(translations));
        if (result.data.payLoad.role.name === "Distributor Admin") {
          navigate("/collection");
        } else if (result.data.payLoad.role.name === "Logistics Admin") {
          navigate("/jobs");
        } else if (result.data.payLoad.role.name === "User") {
          navigate("/profile");
        }
        toast.success(getTranslatedMessage(result.data.message));

        getDashboardList();
      } else if (!result.data.success) {
        toast.error(t("incorrect-email"));
        //toast.error(getTranslatedMessage(result.data.message));
      }
      setbtn_loading(false);
    },
  });
  const getDashboardList = async () => {
    let dashboardData = await getDashboardData();
    if (dashboardData?.success) {
      localStorage.setItem(
        "dashboardData",
        JSON.stringify(dashboardData?.payLoad)
      );
    }
  };

  return (
    <div className="login-container">
      <Toaster position="top-center" />
      <h1>{t("login-heading")}</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="login-form">
          <TextInputGroup
            width="100%"
            className="input-group"
            label={t("email")}
            labelWeight={500}
            id="email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            error={formik.errors.email && formik.touched.email ? true : false}
          />
          {formik.errors.email && formik.touched.email ? (
            <div className="Error-message align-self-start">
              {formik.errors.email}{" "}
            </div>
          ) : null}
          <div className="mt-4"></div>
          <PasswordInputGroup
            width="100%"
            className="input-group"
            label={t("password")}
            labelWeight={500}
            id="password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            onBlur={formik.handleBlur}
            error={
              formik.errors.password && formik.touched.password ? true : false
            }
          />
          {formik.errors.password && formik.touched.password ? (
            <div className="Error-message d-flex align-self-start">
              {formik.errors.password}{" "}
            </div>
          ) : null}
          {!btn_loading ? (
            <SubmitButton className="input-group" minWidth="100%">
              {t("login-button-label")}
            </SubmitButton>
          ) : (
            <SubmitButton disabled className="input-group" minWidth="100%">
              <Spinner animation="border" />
            </SubmitButton>
          )}
        </div>
      </form>
      <p>{t("login-new-user-heading")}</p>
      <Link
        to="/create-account"
        onClick={() => tracking({ value: "/create-account" })}
      >
        <AnchorButton as="a">{t("login-new-user-link")}</AnchorButton>
      </Link>
      <p>{t("login-forgot-password-heading")}</p>
      <Link
        to="/forgetpassword"
        onClick={() => tracking({ value: "/forgetpassword" })}
      >
        <AnchorButton as="a">{t("login-forgot-password-link")}</AnchorButton>
      </Link>
    </div>
  );
};

export default LoginCard;
