import React, { useEffect, useState } from "react";
//import { getAllusers } from "../services/JobService";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";

import InfoCard from "./InfoCard";
import LogisticProfile from "./LogisticProfile";

const OpenJobsCollection = (props) => {
  // const [pageNumber, setPageNumber] = useState(1);
  const [tabId, setTabId] = useState(1);
  //const [checkChanges, setcheckChanges] = useState(false);
  //const [infoCardList, setInfoCardList] = useState([]);
  const {
    selectionList,
    setSelectionList,
    pageNumber,
    selectedValue,
    apiResult,
    handleNextPage,
    apiLoading,
  } = props;

  const handleSelectItem = (item) => {
    if (!selectionList.find((el) => el.jobId === item.jobId)) {
      setSelectionList([...selectionList, item]);
    } else {
      DeleteSelectItem(item);
    }
  };

  const DeleteSelectItem = (id) => {
    setSelectionList(selectionList.filter((obj) => obj.jobId !== id));
  };
  // const getUsers = async () => {
  //   setApiLoading(true);
  //   const Users = await getAllusers({
  //     OrderBy: selectedValue,
  //     PageNumber: pageNumber,
  //   });

  //   setApiResult(Users?.payLoad);
  //   setInfoCardList(Users.payLoad?.items);
  //   setApiLoading(false);
  // };
  return (
    <div className="accordion-details-card">
      <Row>
        <Col lg={12}>
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={apiResult?.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handleNextPage}
            containerClassName={`pagination ${"pagination-orange"}`}
            activeClassName={"active"}
            activeLinkClassName={"active-link"}
            forcePage={pageNumber - 1}
          />

          {!props.apiLoading ? (
            apiResult?.items?.map((item, index) => {
              return (
                <div key={index} className="left-side bg-white">
                  <div className="head" />
                  <div className="body">
                    <InfoCard
                      tabId={tabId}
                      {...item}
                      handleSelectItem={handleSelectItem}
                      DeleteSelectItem={DeleteSelectItem}
                      isAdded={
                        selectionList.findIndex(
                          (el) => el.jobId === item.jobId
                        ) === -1
                          ? false
                          : true
                      }
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                height: "60vh",
              }}
            >
              <Spinner animation="border" />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OpenJobsCollection;
