import { getMethod, postMethod } from "../Network/NetworkManager";
import {
  GET_MATERIAL,
  GET_MATERIAL_LIST,
  GET_DISTRIBUTOR_LIST,
  CREATE_JOB,
  GET_ALLJOBS_URL,
  GET_ALLORDERS_COLLECTION_URL,
  GET_LOGISTICS_ALLORDERS_COLLECTION_URL,
  GET_ALLCOMPANIES_URL,
} from "../constants/ApiUrls";
import { environment } from "../Environments/Environment";

export const getAllMaterial = async (requestPayload) => {
  let data;
  let result = await getMethod(environment.BASE_URL + GET_MATERIAL);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};

export const getMaterialList = async (requestPayload) => {
  let data;
  let result = await getMethod(environment.BASE_URL + GET_MATERIAL_LIST);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};
export const getAllusers = async (requestPayload) => {
  let data;
  let url =
    GET_ALLJOBS_URL +
    `OrderBy=${requestPayload.OrderBy}&PageNumber=${requestPayload.PageNumber}&PageSize=10`;
  let result = await getMethod(environment.BASE_URL + url);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};

export const getAllCompanies = async (requestPayload) => {
  let data;
  let result = await getMethod(environment.BASE_URL + GET_ALLCOMPANIES_URL);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};

export const getAllLogisticsusers = async (requestPayload) => {
  let data;
  let url =
    GET_ALLORDERS_COLLECTION_URL +
    `PageNumber=${requestPayload.pageNumber}&PageSize=10&SearchString=${requestPayload.companyId}&OrderBy=${requestPayload.OrderBy}`;
  let result = await getMethod(environment.BASE_URL + url);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};
export const getAllLogisticsOrders = async (requestPayload) => {
  let data;
  let url =
    GET_LOGISTICS_ALLORDERS_COLLECTION_URL +
    `PageNumber=${requestPayload.pageNumber}&PageSize=10&OrderBy=${requestPayload.OrderBy}`;
  let result = await getMethod(environment.BASE_URL + url);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};
export const getDistributorList = async (requestedPayload) => {
  let data;
  let result = await getMethod(environment.BASE_URL + GET_DISTRIBUTOR_LIST);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};

export const createJob = async (requestedPayload) => {
  let data;
  let result = await postMethod(CREATE_JOB, requestedPayload);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};
