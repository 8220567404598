import React, { useEffect, useState } from "react";
import { Accordion, Col, Row, Spinner, Table } from "react-bootstrap";
import InfoCard from "./InfoCard";
import {
  getAllOrdersCollected,
  getAllOrdersCollection,
  getOrderItems,
} from "../services/OrderServices";
//import { getJobImage } from "../services/CommonServices";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const JobsCollection = ({ tabId, selectedValue }) => {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [ApiResult, setApiResult] = useState();
  const [selectionList, setSelectionList] = useState([]);
  const [infoCardList, setInfoCardList] = useState();
  const [apiLoading, setapiLoading] = useState(true);
  const [orderItems, setorderItems] = useState([]);
  const [loading, setloading] = useState(false);
  const [orderDetailId, setorderDetailId] = useState("");
  const [firstload, setfirstload] = useState(true);

  useEffect(() => {
    getListing();
  }, [pageNumber, selectedValue, tabId]);

  useEffect(() => {
    if (firstload) {
      return setfirstload(false);
    } else {
      handleOrderDetail();
    }
  }, [orderDetailId]);

  const getListing = async () => {
    setapiLoading(true);
    if (tabId === 2) {
      let result = await getAllOrdersCollection({
        pageNumber,
        OrderBy: selectedValue,
      });

      if (result?.success) {
        // let arr = [];
        // for (let i = 0; i < result.payLoad?.items.length; i++) {
        //   let elementI = result.payLoad?.items[i];
        //   let temp = [];
        //   for (let j = 0; j < elementI.orderItems.length; j++) {
        //     let elementJ = elementI.orderItems[j];
        //     let result = await getJobImage(elementJ?.job?.jobPicture);
        //     if (result.status) {
        //       elementJ.job.jobPicture = result.blob;
        //       temp.push(elementJ);
        //     }
        //   }
        //   elementI.orderItems = temp;
        //   arr.push(elementI);
        // }
        setApiResult(result.payLoad);
        // setapiLoading(false);
      }
    } else if (tabId === 3) {
      let result = await getAllOrdersCollected({
        pageNumber,
        OrderBy: selectedValue,
      });

      if (result?.success) {
        // let arr = [];
        // for (let i = 0; i < result.payLoad?.items.length; i++) {
        //   let elementI = result.payLoad?.items[i];
        //   let temp = [];
        //   for (let j = 0; j < elementI.orderItems.length; j++) {
        //     let elementJ = elementI.orderItems[j];
        //     let result = await getJobImage(elementJ?.job?.jobPicture);
        //     if (result.status) {
        //       elementJ.job.jobPicture = result.blob;
        //       temp.push(elementJ);
        //     }
        //   }
        //   elementI.orderItems = temp;
        //   arr.push(elementI);
        // }
        setApiResult(result.payLoad);
      }
    }
    setapiLoading(false);
  };

  const handleSelectItem = (item) => {
    setSelectionList([...selectionList, item]);
    handleChangeStatus(item);
  };

  const handleChangeStatus = (item) => {
    const updatedList = infoCardList.map((ele) => {
      if (ele.id === item.id) {
        return Object.assign(ele, item);
      } else {
        return ele;
      }
    });
    setInfoCardList(updatedList);
  };
  const handleNextPage = async (page) => {
    let pageNumber = page.selected + 1;
    setPageNumber(pageNumber);
  };

  const handleImageCall = (data) => {
    (async () => {
      const arr = data;
      // for (let i = 0; i < arr.length; i++) {
      //   let img = await getJobImage(arr[i]?.job?.jobPicture);
      //   if (img.status) {
      //     arr[i].job.jobPicture = img.blob;
      //   }
      // }
      setloading(false);
      return setorderItems(arr);
    })();
  };

  const handleOrderDetail = async () => {
    setloading(true);
    const result = await getOrderItems(orderDetailId);
    if (result?.success) {
      handleImageCall(result?.payLoad);
    } else {
      toast.error(result?.message);
      setloading(false);
    }
  };

  return (
    <>
      <Col lg={12}>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={ApiResult?.totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={handleNextPage}
          containerClassName={`pagination ${"pagination-orange"}`}
          activeClassName={"active"}
          activeLinkClassName={"active-link"}
          forcePage={pageNumber - 1}
        />
        {!apiLoading ? (
          <Table
            responsive
            borderless
            className="table-data my-custom-table mt-5 table-data-orange"
          >
            <thead className="custom-table-head">
              <tr>
                <th>{t("table-col1")}</th>
                <th>{t("table-col2")}</th>
                <th>{t("table-col3")}</th>
                <th>{t("table-col4")}</th>
                <th>{t("table-col7")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="custom-table-body">
              <tr>
                <td colSpan={6} className="main-td">
                  <Accordion defaultActiveKey="-111">
                    {ApiResult?.items?.map((item, index) => (
                      <Accordion.Item
                        onClick={() => setorderDetailId(item?.orderId)}
                        eventKey={index}
                      >
                        <Accordion.Header>
                          <Table borderless className="w-100 m-0">
                            <tbody>
                              <tr>
                                <td>{item.orderCode}</td>
                                <td>
                                  {item.createdAt.includes("0001")
                                    ? "N/A"
                                    : item.createdAt
                                        .substring(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("-")}
                                </td>
                                <td>
                                  {item.estimatesCollectionDate
                                    ? item.estimatesCollectionDate
                                        .substring(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("-")
                                    : "N/A"}
                                </td>
                                <td>
                                  {item.collectionDate
                                    ? item.collectionDate
                                        .substring(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("-")
                                    : "N/A"}
                                </td>
                                <td>
                                  {item.isPriceRequest
                                    ? `Preis unter ${item.emailOrNumber} anfragen.`
                                    : item.price
                                    ? item.price
                                    : "N/A"}
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </Table>
                        </Accordion.Header>
                        <Accordion.Body>
                          <>
                            <div className="accordion-details-card">
                              <Row>
                                <Col lg={12}>
                                  {!loading ? (
                                    orderItems?.map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="left-side bg-white"
                                        >
                                          <div className="head" />
                                          <div className="body">
                                            <InfoCard
                                              {...item?.job}
                                              handleSelectItem={
                                                handleSelectItem
                                              }
                                              tabId={tabId}
                                            />
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div
                                      className="d-flex justify-content-center align-items-center"
                                      style={{
                                        height: "35vh",
                                      }}
                                    >
                                      <Spinner animation="border" />
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              height: "60vh",
            }}
          >
            <Spinner animation="border" />
          </div>
        )}
      </Col>
    </>
  );
};
JobsCollection.propTypes = {
  tabId: PropTypes.any,
};
export default JobsCollection;
