import React, { useState } from "react";
import { S3_BASE_URL } from "../common/utils/Utilities";
import "./components.scss";
import PropTypes from "prop-types";
import { environment } from "../Environments/Environment";
import { useTranslation } from "react-i18next";
import { GET_JOB_Image_URL } from "../constants/ApiUrls";

const InfoCard = (props) => {
  const {
    jobPicture,
    companyName,
    dropDate,
    amountOfBags,
    materialName,
    distributorCompanyNameLocation,
  } = props.cardItem;
  const [loaded, setLoaded] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="info-card-container row m-0 gx-0">
      <div className="info-card-image-container col-lg-4 col-md-12">
        <img
          src={environment.BASE_URL + `${GET_JOB_Image_URL}?Key=${jobPicture}`}
          loading="lazy"
          alt="info"
          className="info-card-image"
        />
      </div>
      <div className="info-card-subcontainer row col gx-0">
        <div className="info-card-item-row row">
          <div className="info-card-item col-lg-4 col-md-6 col-sm-12">
            <p className="info-card-item-label">{t("company")}</p>
            <p className="fw-bold info-card-item-property">{companyName}</p>
          </div>
          <div className="info-card-item col-lg-4 col-md-6 col-sm-12">
            <p className="info-card-item-label">{t("dropdate")}</p>
            <p className="fw-bold info-card-item-property">
              {dropDate?.substring(0, 10).split("-").reverse().join(".")}
            </p>
          </div>
        </div>
        <div className="info-card-item-row row">
          <div className="info-card-item col-lg-4 col-md-6 col-sm-12">
            <p className="info-card-item-label">{t("amount")}</p>
            <p className="info-card-item-property">
              {amountOfBags + " "}
              {amountOfBags > 1 ? t("bags") : t("bag")}
            </p>
          </div>
          <div className="info-card-item col-lg-6 col-sm-12">
            <p className="info-card-item-label">{t("material")}</p>
            <p className="info-card-item-property">{materialName}</p>
          </div>
        </div>
        <div className="info-card-item-row row gx-0">
          <div className="info-card-item col-lg-12">
            <p className="info-card-item-label">{t("dropoff-location")}</p>
            <p className="info-card-item-property">
              {distributorCompanyNameLocation}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
InfoCard.propTypes = {
  cardItem: PropTypes.any,
};
export default InfoCard;
