import React, { useState, useEffect } from "react";
import { DeactiveButton, StyledButton } from "../common/Button.style";
import "./components.scss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const QuantityCard = (props) => {
  const { selectionList, handleCollection, ApiLoading, handleSubmit } = props;
  const { t } = useTranslation();
  const [totalNumOfBags, setTotalNumOfBags] = useState(0);

  useEffect(() => {
    let temp = 0;
    selectionList?.map((item) => {
      return (temp += item.amountOfBags);
    });
    setTotalNumOfBags(temp);
  }, [selectionList]);

  return (
    <div className="quantiy-card-container">
      <div className="quantity mb-5">
        <p>
          {selectionList?.length} {t("selected-jobs")}
        </p>
      </div>
      {selectionList?.map((item, index) => {
        return (
          <div
            className="selected-item-row d-flex justify-content-between"
            key={index}
          >
            <p className="fw-bold">{item?.companyName}</p>
            <p>
              {item?.amountOfBags}{" "}
              {item?.amountOfBags > 1 ? t("bags") : t("bag")}
            </p>
            <p>{item?.materialName}</p>
          </div>
        );
      })}
      <div className="divider"></div>
      <div className="quantity-card-footer">
        <p className="footer-guides">
          {t("min-job")}
          <br />
          {t("max-job")}
          <br />
          {/* {t("job-cost")} */}
        </p>
        <div className="d-flex flex-column align-items-end">
          <h5>{t("distributor-quantity-heading")}</h5>
          <h2 className="fw-bold mb-4">
            {totalNumOfBags} {totalNumOfBags == 1 ? t("bag") : t("bags")}
          </h2>
          {selectionList.length === 0 ||
          totalNumOfBags < 10 ||
          totalNumOfBags > 80 ||
          ApiLoading ? (
            <DeactiveButton
              disabled
              borderRadius={"0"}
              color={"#fff"}
              padding={"0.5rem 2.5rem"}
            >
              {t("collection-button-label")}
            </DeactiveButton>
          ) : (
            <StyledButton
              onClick={() => handleCollection()}
              borderRadius={"0"}
              color={"#fff"}
              padding={"0.5rem 2.5rem"}
            >
              {t("collection-button-label")}
            </StyledButton>
          )}
        </div>
      </div>
    </div>
  );
};
QuantityCard.propTypes = {
  selectionList: PropTypes.any,
  handleCollection: PropTypes.any,
  ApiLoading: PropTypes.any,
};
export default QuantityCard;
