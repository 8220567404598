import React, { useState } from "react";

const DropoffDropDown = (props) => {
  const {
    width,
    list,
    onClickFun,
    prompt,
    value,
    showNameOnly = false,
  } = props;

  const [open, setOpen] = useState(false);

  const handleOption = (ele) => {
    onClickFun(ele);
    setOpen(false);
  };
  const listFormatter = (element) => {
    if (element?.roleName) {
      return element?.roleName;
    } else if (element?.name) {
      return showNameOnly
        ? element?.name
        : element?.name +
            (element.city !== "" && element.city !== null
              ? " / " + element.city
              : "");
    }
  };
  const listOption = () => {
    return list?.map((item, index) => (
      <div
        className="options"
        key={index}
        onClick={() => {
          handleOption(item);
        }}
      >
        <p className="m-0">{listFormatter(item)}</p>
      </div>
    ));
  };
  return (
    <div className={"custom-dropdown-1 d-flex position-relative"}>
      <div
        className="position-relative"
        style={{
          width: width ? width : "75%",
        }}
      >
        <div
          className="dropdown-control"
          onClick={() => setOpen((prev) => !prev)}
        >
          <div className="selected-value">
            <p className="m-0">{value ? value : prompt}</p>
          </div>
        </div>
        <div className={`${open ? "d-block" : "d-none"} option-container`}>
          {listOption()}
        </div>
      </div>
    </div>
  );
};

export default DropoffDropDown;
