import React, { useState, useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DeactiveButton, SubmitButton } from "../common/Button.style";
import { PasswordInputGroup } from "../common/InputGroup.style";
import "./components.scss";
import { useNavigate } from "react-router-dom";
import { renewPassword, getTranslations } from "../services/AuthService";
import { useTranslation } from "react-i18next";
import { getTranslatedMessage } from "../common/utils/Utilities";
import { Spinner } from "react-bootstrap";
const ResetPasswordCard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [btn_loading, setbtn_loading] = useState(false);
  const id = localStorage.getItem("ResetPasswordId");
  useEffect(() => {
    fetchTranslation();
  }, []);

  const fetchTranslation = async () => {
    let translations = await getTranslations("de");
    localStorage.setItem("translations", JSON.stringify(translations));
  };
  const formik = useFormik({
    initialValues: {
      password: "",
      repeatpassword: "",
    },

    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required(t("required-err"))
        .max(35, t("password-field-err"))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          t("password-field-err")
        ),
      repeatpassword: Yup.string()
        .required(t("required-err"))
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            t("password-mismatch")
          ),
        }),
    }),

    onSubmit: async (values) => {
      setbtn_loading(true);
      const payload = {
        newPassword: values.password,
        forgetPasswordId: id,
      };
      let result = await renewPassword(payload);
      if (result?.data?.success) {
        toast.success(getTranslatedMessage(result.data.message));
        navigate("/");
      } else {
        toast.error(getTranslatedMessage(result.data.message));
      }
      setbtn_loading(false);
    },
  });
  return (
    <>
      <div className="resetpassword">
        <h5>{t("create-password")}</h5>
        <form
          onSubmit={formik.handleSubmit}
          className="forgotpassword-form d-flex flex-column align-items-center"
        >
          <div>
            <PasswordInputGroup
              onBlur={formik.handleBlur}
              className="input-group"
              label={t("create-password")}
              labelWeight={500}
              id="password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            {formik.errors.password && formik.touched.password ? (
              <div className="Error-message">{formik.errors.password} </div>
            ) : null}
          </div>
          <div>
            <PasswordInputGroup
              onBlur={formik.handleBlur}
              className="input-group"
              label={t("repeat-password")}
              labelWeight={500}
              id="repeatpassword"
              name="repeatpassword"
              onChange={formik.handleChange}
              value={formik.values.repeatpassword}
            />
            {formik.errors.repeatpassword && formik.touched.repeatpassword ? (
              <div className="Error-message">
                {formik.errors.repeatpassword}{" "}
              </div>
            ) : null}
          </div>
          <div className="submit-btn">
            {formik.isValid && formik.dirty ? (
              !btn_loading ? (
                <SubmitButton color={"#fff"}>
                  {t("continue-button-label")}
                </SubmitButton>
              ) : (
                <SubmitButton color={"#fff"}>
                  <Spinner animation="border" />
                </SubmitButton>
              )
            ) : (
              <DeactiveButton disabled color={"#fff"}>
                {t("continue-button-label")}
              </DeactiveButton>
            )}
          </div>
        </form>
      </div>
      <Toaster />
    </>
  );
};
export default ResetPasswordCard;
