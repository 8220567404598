import React, { useEffect, useState } from "react";
import MinusIcon from "../../assets/icons/MinusIcon";
import PulseIcon from "../../assets/icons/PulseIcon";
import { Modal, Spinner } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
import { DeactiveButton, SubmitButton } from "../Button.style";
import DatePicker from "../ui/DatePicker";
import JobsCollectionPage from "../../pages/jobscollection/JobsCollectionPage";

import {
  getMaterialList,
  getDistributorList,
  createJob,
  getAllCompanies,
} from "../../services/JobService";
import sample1 from "../../assets/images/sample1.png";
import sample2 from "../../assets/images/sample2.png";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextField from "../ui/TextField";
import MaterialDropDown from "../ui/MaterialDropDown";
import DropoffDropDown from "../ui/DropoffDropDown";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ItemQuantity = (props) => {
  const {
    dropOffItem,
    quantity,
    show,
    distributorShow,
    materialSelectionList,
    setQuantity,
    setDropOffShow,
    setThanksNoteShow,
    setDistributorShow,
    setMaterialSelectionList,
  } = props;

  const { t } = useTranslation();

  const [materialUsageList, setMaterialUsageList] = useState([]);
  const [distributorList, setDistributorList] = useState([]);
  const [materialUsageId, setMaterialUsageId] = useState("");
  const [distributorCompanyId, setDistributorCompanyId] = useState("");
  const [selectedDistributorLocation, setSelectedDistributorLocation] =
    useState("");
  const [materialError, setMaterialError] = useState("");
  const [distributorError, setDistributorError] = useState("");
  const [logisticsError, setLogisticsError] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [materialUsageValue, setMaterialUsageValue] = useState("");
  const [distributerCompanyValue, setDistributorCompanyValue] = useState("");
  const [logisticsCompanyValue, setLogisticsCompanyValue] = useState("");
  const [logisticsCompanyId, setLogisticsCompanyId] = useState("");
  const [selectedLogisticsCompany, setSelectedLogisticsCompany] = useState({});

  const hiddenFileInput = React.useRef(null);
  const [distributorInfo, setdistributorInfo] = useState({});
  const [screenNumber, setscreenNumber] = useState(1);
  const [imageUploading, setimageUploading] = useState(false);
  const [logisticCompany, setLogisticCompany] = useState([]);
  const [disable, setDisable] = useState(false);

  const containsNumber = (str) => {
    return /\d/.test(str);
  };
  const containSpecialChar = (str) => {
    return /[-!$%^&*()£_+|~=`{}\[\]:\/;<>?,.@#]/.test(str);
  };
  const validate = Yup.object().shape({
    dropDate: Yup.date().required(t("required-err")),
    city: Yup.string()
      .required(t("required-err"))
      .matches(/[A-Za-zÄäÆæß ]+/, t("city-field-err1"))
      .max(40, t("logistic-profile-city-limit")),
    // .min(5, t("city-field-err2"))
    // .max(10, t("city-field-err2"))
    // .test(
    //   "City",
    //   t("invalid-city"),
    //   (value) => !containsNumber(value) && !containSpecialChar(value)
    // ),
    zipCode: Yup.string()
      .label("Zip code")
      .required(t("zip-code-rq"))
      .max(20, t("city-field-err2logistic-profile-zipcode-limit")),

    street: Yup.string()
      .required(t("required-err"))
      .max(40, t("logistic-profile-street-limit")),
    //  .matches(/[A-Za-zÄäÆæß ]+/, "Street contains characters only.")
    // .test(
    //   "Street",
    //   "Invaild street",
    //   (value) => !containsNumber(value) && !containSpecialChar(value)
    // )
    streetNr: Yup.string()
      .required(t("required-err"))
      .matches(/^[-_a-zA-Z0-9.]+$/, t("st-no-err"))
      .max(40, t("logistic-profile-streetNo-limit")),
    contactPerson: Yup.string()
      .required(t("required-err"))
      .matches(/[A-Za-zÄäÆæß ]+/, t("contact-person-field-err"))
      .max(60, t("logistic-profile-contactperson-limit")),
    // .test(
    //   "Contact Person",
    //   t("invalid-contact-person"),
    //   (value) => !containsNumber(value) && !containSpecialChar(value)
    // )
    telNumber: Yup.string()
      .required(t("required-err"))
      .matches(/^[+]*[]{0,1}[0-9]{1,4}[]{0,1}[\s\./0-9]*$/, t("invalid-tel-no"))
      .max(40, t("logistic-profile-phoneNo-limit")),
    note: Yup.string().max(200, t("logistic-profile-note-limit")),
  });
  const handleIncrement = () => {
    setQuantity(quantity < 80 ? (prev) => prev + 1 : 80);
    setQuantityError("");
  };
  const handleDecrement = () => {
    setQuantity(quantity > 0 ? (prev) => prev - 1 : 0);
  };
  useEffect(() => {
    MaterialList();
    DistributorList();
    getLogisticCompanies();
  }, [show]);

  useEffect(() => {
    if (distributorShow && !logisticsCompanyId) {
      setDisable(true);
    } else if (distributorShow && logisticsCompanyId && quantity < 10) {
      setDisable(true);
    } else if (
      distributorShow &&
      logisticsCompanyValue &&
      quantity >= 10 &&
      quantity <= 80
    ) {
      setDisable(false);
    } else if (!distributorShow) {
      setDisable(false);
    }
  }, [quantity, distributorShow, logisticsCompanyId]);

  const getLogisticCompanies = async () => {
    const companies = await getAllCompanies();
    setLogisticCompany(companies.payLoad?.items);
  };

  const DistributorList = async () => {
    const result = await getDistributorList();
    setDistributorList(result?.payLoad?.items);
  };
  const MaterialList = async () => {
    const result = await getMaterialList();
    setMaterialUsageList(result?.payLoad?.items);
  };
  const handleSelectedMaterial = (ele) => {
    const obj = materialUsageList.find((elem) => {
      return elem.name === ele;
    });
    setMaterialUsageId(obj.materialUsageId);
    setMaterialUsageValue(ele);
    setMaterialError("");
  };
  const handleSelectedDistributor = (ele) => {
    debugger;
    if (ele.name === "Other" || ele.name === "Abholung") {
      setDistributorShow(true);
      setDistributorCompanyId(ele.distributorCompanyId);
      setDistributorCompanyValue(
        ele.name +
          (ele.city !== "" && ele.city !== null ? " / " + ele.city : "")
      );
      setDistributorError("");
    } else {
      setDistributorShow(false);
      setDistributorCompanyValue(
        ele.name +
          (ele.city !== "" && ele.city !== null ? " / " + ele.city : "")
      );
      setSelectedDistributorLocation(ele.dropOffLocation);
      setDistributorCompanyId(ele.distributorCompanyId);
      setDistributorError("");
    }
  };

  const handleSelectedLogistics = (ele) => {
    setLogisticsCompanyId(ele.companyId);
    setLogisticsCompanyValue(ele.name);
    setSelectedLogisticsCompany(ele);
    setLogisticsError("");
  };
  const renderDistributorLoaction = () => {
    if (!distributorShow && selectedDistributorLocation.length) {
      return <h5>{selectedDistributorLocation}</h5>;
    } else {
      return <h5 style={{ color: "#bababa" }}>{t("Select distributor")}</h5>;
    }
  };

  const handleSubmit = (e, formik) => {
    setdistributorInfo({
      materialId: dropOffItem.materialId,
      amountOfBags: quantity,
      dropDate: formik.values.dropDate.toString(),
      companyId: localStorage.getItem("companyId"),
      materialUsageId,
      distributorCompanyId,
      dropOffLocation: JSON.stringify({
        city: formik.values.city,
        zipCode: formik.values.zipCode,
        street: formik.values.street,
        streetNo: formik.values.streetNr.toString(),
        contactPerson: formik.values.contactPerson.toString(),
        phoneNo: formik.values.telNumber.toString(),
        note: formik.values.note,
        logisticsCompanyId,
        type: selectedLogisticsCompany.type,
      }),
    });

    if (!materialUsageId && !distributorCompanyId && quantity === 0) {
      setMaterialError(t("material-rq"));
      setDistributorError(t("distributor-location-rq"));
      setQuantityError(t("quantity-err"));
    }
    if (!materialUsageId) {
      setMaterialError(t("material-rq"));
    }
    if (!distributorCompanyId) {
      setDistributorError(t("distributor-location-rq"));
    }
    if (!logisticsCompanyId) {
      setLogisticsError(t("lg-company-rq"));
    }
    if (quantity === 0) {
      setQuantityError(t("quantity-err"));
    }
    if (
      materialUsageId &&
      distributorCompanyId &&
      quantity !== 0 &&
      !distributorShow
    ) {
      nextPage();
    }
    if (
      materialUsageId &&
      distributorCompanyId &&
      quantity !== 0 &&
      distributorShow &&
      formik.isValid &&
      formik.dirty
    ) {
      nextPage();
    }
  };

  const handleUploadImage = (e, formik) => {
    const fileUploaded = e.target.files[0];
    setdistributorInfo({ ...distributorInfo, jobPicture: fileUploaded });
    fileUploaded && CreateJob({ ...distributorInfo, jobPicture: fileUploaded });
  };
  const CreateJob = async (payLoad) => {
    const formData = new FormData();
    for (let key in payLoad) {
      formData.append(key, payLoad[key]);
    }
    setimageUploading(true);
    const result = await createJob(formData);
    if (result?.success) {
      const updatedList = materialSelectionList.filter(
        (item) => item.materialId !== dropOffItem.materialId
      );
      setMaterialSelectionList(updatedList);
      setThanksNoteShow(true);
      setQuantity(1);
      handleReset();
    } else {
      toast.error(result?.statusText);
    }
    setimageUploading(false);
  };
  const handleReset = () => {
    setDropOffShow(false);
    setSelectedDistributorLocation("");
    setDistributorCompanyId("");
    setMaterialUsageId("");
    setDistributorCompanyValue("");
    setMaterialUsageValue("");
    setLogisticsCompanyId("");
    setLogisticsCompanyValue("");
    setQuantity(1);
    setscreenNumber(1);
    setDisable(false);
    setQuantityError("");
    setDistributorShow(false);
  };

  const modalHide = () => {
    setDistributorError("");
    setMaterialError("");
    setLogisticsError("");
    handleReset();
    setSelectedLogisticsCompany("");
  };
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const nextPage = () => {
    setscreenNumber(2);
  };

  const temp = () => {
    let a;

    if (selectedLogisticsCompany?.type == "Abholung") {
      if (selectedLogisticsCompany?.pickUpPrice) {
        a = `${t("fixed-cost-per-pickup")}: ${
          selectedLogisticsCompany?.pickUpPrice
        }-€`;
      } else if (selectedLogisticsCompany.isPickUpPriceRequest) {
        a = `Preis unter ${selectedLogisticsCompany.pickUpEmailOrNumber} anfragen.`;
      }
    } else if (selectedLogisticsCompany.type == "Anlieferung") {
      if (selectedLogisticsCompany?.dropOffPrice) {
        a = `${t("fixed-cost-per-dropoff")}: ${
          selectedLogisticsCompany?.dropOffPrice
        }-€`;
      } else if (selectedLogisticsCompany.isDropOffPriceRequest) {
        a = `Preis unter ${selectedLogisticsCompany.dropOffEmailOrNumber} anfragen.`;
      } else {
        a = `${t("fixed-cost-per")}-€`;
      }
    }
    return a;
  };

  return (
    <>
      <Modal show={show} centered size="lg" onHide={modalHide}>
        {screenNumber === 1 ? (
          <Formik
            initialValues={{
              dropDate: "",
              city: "",
              zipCode: "",
              street: "",
              streetNr: "",
              contactPerson: "",
              telNumber: "",
              note: "",
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <div className="item-quantity-container ">
                  <div className="d-flex justify-content-end">
                    <img
                      alt="crossicon"
                      src={"/assets/icons/cross-icon1.png"}
                      onClick={modalHide}
                    />
                  </div>
                  <h2 className="item-quantity-h">{dropOffItem?.name}</h2>
                  <div className="item-quantity-container-info">
                    <div className="item-material-info">
                      <h5 className="fw-bold">{t("material-properties")}</h5>
                      <h4>{dropOffItem?.properties}</h4>
                    </div>
                    <div className="no-of-items">
                      <h4 className="fw-bold">{t("bags-amount")}</h4>
                      <div className="quantity-controller">
                        <div className="minus-icon" onClick={handleDecrement}>
                          <MinusIcon
                            style={{ width: "16px", fill: "#626C83" }}
                          />
                        </div>
                        <div className="quantity-figure">
                          <h1 className="fw-bold">{quantity}</h1>
                          <div className="hr-line"></div>
                        </div>
                        <div className="pulse-icon" onClick={handleIncrement}>
                          <PulseIcon
                            style={{ width: "16px", fill: "#626C83" }}
                          />
                        </div>
                      </div>
                      {quantityError && (
                        <div className="Error-message">{quantityError}</div>
                      )}
                    </div>
                    <div className="delivery-date">
                      {/* <TextField
                        border={true}
                        label={t("date")}
                        type="date"
                        name="dropDate"
                      /> */}

                      <DatePicker
                        inputClass="custom-calendar-input"
                        isError={
                          formik.touched.dropDate && formik.errors.dropDate
                        }
                        errorMessage={formik.errors.dropDate}
                        name="dropDate"
                        onChange={(v) => {
                          formik.setFieldValue(
                            "dropDate",
                            `${v.year}-${
                              parseInt(v.monthIndex) + 1 < 10
                                ? "0" + (v.monthIndex + 1)
                                : v.monthIndex + 1
                            }-${v.day < 10 ? "0" + v.day : v.day}`
                          );
                        }}
                      />
                    </div>
                    <div className="company-name">
                      <h4 className="fw-bold">{t("company-name")}</h4>
                      <h4>{localStorage.getItem("companyName")}</h4>
                    </div>
                    <div className="material-usage">
                      <h4 className="fw-bold">{t("material-used")}</h4>
                      <MaterialDropDown
                        width="100%"
                        name="Material"
                        list={materialUsageList}
                        onClickFun={handleSelectedMaterial}
                        prompt={t("select-button-label")}
                        value={materialUsageValue}
                      />
                      {materialError && (
                        <div className="Error-message">{materialError}</div>
                      )}
                    </div>
                    <div className="drop-off-distributor">
                      <h4 className="fw-bold">{t("dropoff-distributor")}</h4>
                      <DropoffDropDown
                        width="100%"
                        list={distributorList}
                        onClickFun={handleSelectedDistributor}
                        prompt={t("select-button-label")}
                        value={distributerCompanyValue}
                      />
                      {distributorError && (
                        <div className="Error-message">{distributorError}</div>
                      )}
                      {distributorShow ? (
                        <>
                          <div className="bg-white">
                            <div
                              className="d-flex flex-column pt-3"
                              style={{ width: "100%" }}
                            >
                              <div className="pb-2">
                                <TextField
                                  border={true}
                                  label={t("street")}
                                  maxLength="25"
                                  type="text"
                                  name="street"
                                  placeholder="e.g. Hauptstraße"
                                />
                              </div>
                              <div className="pb-2">
                                <TextField
                                  border={true}
                                  label={t("street-nr")}
                                  type="text"
                                  maxLength="10"
                                  name="streetNr"
                                  placeholder={t("enter-street-nr")}
                                />
                              </div>

                              <div className="pb-2">
                                <TextField
                                  border={true}
                                  label={t("zip-code")}
                                  type="text"
                                  name="zipCode"
                                  maxLength="10"
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    const currentValue = value.replace(
                                      /[^\d]/g,
                                      ""
                                    );
                                    formik.setFieldValue(
                                      "zipCode",
                                      currentValue
                                    );
                                  }}
                                />
                              </div>
                              <div className="pb-2">
                                <TextField
                                  border={true}
                                  label={t("city")}
                                  maxLength="60"
                                  type="text"
                                  name="city"
                                  placeholder="e.g. Karlsruhe"
                                />
                              </div>
                              <div className="pb-2">
                                <TextField
                                  border={true}
                                  label={t("contact-person")}
                                  type="text"
                                  maxLength="60"
                                  name="contactPerson"
                                  placeholder="e.g. Max Mustermann"
                                />
                              </div>
                              <div className="pb-2">
                                <TextField
                                  border={true}
                                  label={t("tel-number")}
                                  type="text"
                                  maxLength="15"
                                  name="telNumber"
                                  placeholder=""
                                />
                              </div>
                              <div className="pb-2">
                                <TextField
                                  border={true}
                                  label={t("note")}
                                  type="text"
                                  maxLength="90"
                                  as="textarea"
                                  name="note"
                                />
                              </div>
                              <div className="pb-2">
                                <h4 className="fw-bold">
                                  {t("select-logistic")}
                                </h4>
                                <DropoffDropDown
                                  width="100%"
                                  list={logisticCompany}
                                  onClickFun={handleSelectedLogistics}
                                  prompt={t("select-button-label")}
                                  value={logisticsCompanyValue}
                                  showNameOnly={true}
                                />
                                {logisticsError && (
                                  <div className="Error-message">
                                    {logisticsError}
                                  </div>
                                )}
                                <p
                                  style={{
                                    textAlign: "end",
                                    fontSize: "12px",
                                    marginBottom: "0.2rem",
                                  }}
                                >
                                  {t("min-job")}
                                </p>
                                <p
                                  style={{
                                    textAlign: "end",
                                    fontSize: "12px",
                                    marginBottom: "0.2rem",
                                  }}
                                >
                                  {t("max-job")}
                                </p>
                                <p
                                  style={{
                                    textAlign: "end",
                                    fontSize: "12px",
                                    marginBottom: "0.2rem",
                                  }}
                                >
                                  {temp()}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div>
                      <h4 className="fw-bold">{t("dropoff-location")}</h4>
                      {distributorShow ? (
                        <h5>
                          {selectedLogisticsCompany.type == "Anlieferung"
                            ? selectedLogisticsCompany.street +
                              " " +
                              selectedLogisticsCompany.streetNo +
                              ", " +
                              selectedLogisticsCompany.zipCode +
                              " " +
                              selectedLogisticsCompany.city +
                              ""
                            : formik.values.street +
                              " " +
                              "" +
                              formik.values.streetNr +
                              ", " +
                              formik.values.zipCode +
                              " " +
                              formik.values.city}
                        </h5>
                      ) : (
                        renderDistributorLoaction()
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-5">
                    <div className="submit-btn-container">
                      {disable ? (
                        <DeactiveButton padding="0.5rem 2.5rem">
                          {t("continue-button-label")}
                        </DeactiveButton>
                      ) : (
                        <SubmitButton
                          padding="0.5rem 2.5rem"
                          className="submit-btn"
                          onClick={(e) => handleSubmit(e, formik)}
                        >
                          {t("continue-button-label")}
                        </SubmitButton>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : screenNumber === 2 ? (
          <Formik
            initialValues={{
              dropDate: "",
              city: "",
              street: "",
              streetNr: "",
              contactPerson: "",
              telNumber: "",
              note: "",
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <div className="item-quantity-container ">
                  <div className="d-flex justify-content-end">
                    {!imageUploading ? (
                      <img
                        alt="crossicon"
                        src={"/assets/icons/cross-icon1.png"}
                        onClick={modalHide}
                      />
                    ) : (
                      <img
                        alt="crossicon"
                        src={"/assets/icons/cross-icon1.png"}
                      />
                    )}
                  </div>
                  <h2 className="item-quantity-h">{t("heading-modal4")}</h2>
                  <h5>{t("sub-heading1-modal4")}</h5>
                  <img
                    className="sampleimage"
                    src={sample1}
                    alt="rigthSample"
                  />
                  <h5>{t("sub-heading2-modal4")}</h5>
                  <img
                    className="sampleimage"
                    src={sample2}
                    alt="wrongeSample"
                  />
                  <div className="d-flex justify-content-center mt-4">
                    <div className="submit-btn-container">
                      {!imageUploading ? (
                        <SubmitButton
                          padding="0.5rem 2.5rem"
                          className="submit-btn"
                          onClick={handleClick}
                        >
                          <input
                            onChange={(e) => handleUploadImage(e, formik)}
                            type="file"
                            ref={hiddenFileInput}
                            style={{ display: "none" }}
                            accept="image/*"
                          />
                          {t("upload-picture-button-label")}
                        </SubmitButton>
                      ) : (
                        <SubmitButton
                          padding="0.5rem 2.5rem"
                          className="submit-btn"
                          onClick={handleClick}
                        >
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </SubmitButton>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : null}
      </Modal>
      <Toaster />
    </>
  );
};
ItemQuantity.propTypes = {
  dropOffItem: PropTypes.any,
  quantity: PropTypes.any,
  show: PropTypes.any,
  distributorShow: PropTypes.any,
  materialSelectionList: PropTypes.any,
  setQuantity: PropTypes.any,
  setDropOffShow: PropTypes.any,
  setThanksNoteShow: PropTypes.any,
  setDistributorShow: PropTypes.any,
  setMaterialSelectionList: PropTypes.any,
};
export default ItemQuantity;
