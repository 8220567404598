import React from "react";
import RegisterCard from "../../components/RegisterCard";
import "./createacount.scss";

const RegisterPage = () => {
  return (
    <div className="register-page">
      <img
        src="assets/icons/mcyclo-logo.png"
        alt="brand"
      />
      <RegisterCard />
    </div>
  );
};

export default RegisterPage;
