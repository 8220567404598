import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import "./modal1.scss";
import { useTranslation } from "react-i18next";

const OrderInfo = (props) => {
  const { show, close, data } = props;
  const { t } = useTranslation();
  return (
    <Modal
      contentClassName="working-hours-custom-modal"
      backdropClassName="working-hours-custom-modal-backdrop"
      show={show}
      centered
      size="md"
    >
      <div className="close-icon-div d-flex justify-content-end">
        <img
          style={{ marginBottom: "-7px", marginRight: "-5px" }}
          src="/assets/icons/cross-icon1.png"
          alt="cross-btn"
          onClick={close}
          className="custom-cursor"
        />
      </div>
      <div className="working-hours-modal custom-modal-body bg-white p-5">
        <h4>{t("heading-modal6")}</h4>
        <Row className="mb-3">
          <p className="mb-0">
            <b>{t("company")}</b> {data?.dropOffLocation?.name}
          </p>
        </Row>
        <Row className="mt-2">
          <Col lg={5}>
            {[
              {
                name: `${t("street")}: `,
                desc: data?.dropOffLocation?.street,
              },
              {
                name: `${t("zipcode")}: `,
                desc: data?.dropOffLocation?.zipCode,
              },
              { name: `${t("city")}: `, desc: data?.dropOffLocation?.city },
            ].map((item, index) => (
              <p className="mb-0 d-flex" key={index}>
                <b>{item.name}</b>{" "}
                <p className="ms-2" style={{ wordBreak: "break-all" }}>
                  {item.name === "Straße:" || item.name === "Stadt:"
                    ? item.desc?.slice(0, 15)
                    : item.desc}
                </p>
              </p>
            ))}
          </Col>
          {/* <Col lg={1} /> */}
          <Col lg={7}>
            {[
              { name: `${t("nr")}: `, desc: data?.dropOffLocation?.streetNo },
              { name: `${t("email")}: `, desc: data?.dropOffLocation?.email },
              {
                name: `${t("telefon")}: `,
                desc: data?.dropOffLocation?.phoneNo,
              },
            ].map((item, index) => (
              <p className="m-0 d-flex" key={index}>
                <b>{item.name}</b>{" "}
                <p className="ms-3">
                  {item.name === "Telefon alternative Kontaktperson:" ||
                  item.name === "Telefon der Kontaktperson:"
                    ? item.desc?.slice(0, 35)
                    : item.desc}
                </p>
              </p>
            ))}
          </Col>
        </Row>
      </div>
      <Toaster />
    </Modal>
  );
};

export default OrderInfo;
