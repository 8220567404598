import React from "react";
import {
  ADD_ORDER_PICKUP_URL,
  CREATE_ORDER_URL,
  GET_ALLORDERS_COLLECTION_URL,
  GET_ALLORDERS_URL,
  GET_COMPANIES_BYROLEID_URL,
  GET_ORDER_BYID_URL,
  GET_ORDER_ITEMS,
  GET_ROLES_LIST,
} from "../constants/ApiUrls";
import { toast, Toaster } from "react-hot-toast";

import { environment } from "../Environments/Environment";
import { getMethod, putMethod, postMethod } from "../Network/NetworkManager";

export const getAllOrders = async (requestPayload) => {
  let data;
  let url = GET_ALLORDERS_URL;
  let result = await getMethod(environment.BASE_URL + url);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};
export const addOrderPickUpDate = async (requestPayload) => {
  let data;
  let url = ADD_ORDER_PICKUP_URL + requestPayload.orderId;
  let result = await putMethod(url, {
    estimatesCollectionDate: requestPayload.estimatesCollectionDate,
    collectionDate: requestPayload.collectionDate,
  });
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};
export const getOrderbyId = async (requestPayload) => {
  let data;
  let url = GET_ORDER_BYID_URL + requestPayload;
  let result = await getMethod(environment.BASE_URL + url);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};
export const getRoleslist = async (requestPayload) => {
  let data;
  let url = GET_ROLES_LIST;
  let result = await getMethod(environment.BASE_URL + url);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};
export const getCompaniesByRoleId = async (requestPayload) => {
  let data;
  let url = GET_COMPANIES_BYROLEID_URL + requestPayload;
  let result = await getMethod(environment.BASE_URL + url);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};

export const getAllOrdersCollection = async (requestPayload) => {
  let data;
  let url =
    GET_ALLORDERS_COLLECTION_URL +
    `PageNumber=${requestPayload.pageNumber}&PageSize=10&Search=collectionRequest&OrderBy=${requestPayload.OrderBy}`;
  let result = await getMethod(environment.BASE_URL + url);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};

export const getAllOrdersCollected = async (requestPayload) => {
  let data;
  let url =
    GET_ALLORDERS_COLLECTION_URL +
    `PageNumber=${requestPayload.pageNumber}&PageSize=10&Search=collected&OrderBy=${requestPayload.OrderBy}`;
  let result = await getMethod(environment.BASE_URL + url);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};
export const getOrderItems = async (id) => {
  let data;
  let url = GET_ORDER_ITEMS + id;
  let result = await getMethod(environment.BASE_URL + url);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};

export const CreateOrder = async (requestPayload) => {
  let data;
  let url = CREATE_ORDER_URL;
  let result = await postMethod(url, requestPayload);
  if (result.status === 200) {
    data = result.data;
  } else {
    data = {
      error: true,
      status: result.status,
      statusText: result.data?.message,
    };
  }
  return data;
};

<Toaster />;
