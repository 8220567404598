import React from "react";
import { Modal } from "react-bootstrap";
import "./modal1.scss";
import PropTypes from "prop-types";

const InfoOfDrop = (props) => {
  const { show, close, selectionList } = props;

  return (
    <Modal
      contentClassName="custom-modal"
      backdropClassName="custom-backdrop"
      show={show}
      centered
      size="md"
    >
      <div className="close-icon-div d-flex justify-content-end">
        <img
          src="/assets/icons/cross-icon.png"
          alt="cross-btn"
          onClick={close}
          className="custom-cursor"
        />
      </div>
      <div className="info-drop-modal custom-modal-body bg-white p-5">
        <h5 className="fw-bold">All Materials from this drop</h5>
        {selectionList.map((item, index) => {
          return (
            <div key={index} className="row drop-modal-row">
              <div className="col drop-modal-col">
                <h7 className="fw-bold">{item.material}</h7>
                <p className="drop-modal-p">300 kPA, PolyFR</p>
              </div>
              <div className="col drop-modal-col mt-1">
                <p className="drop-modal-h m-0">Amount</p>
                <p className="drop-modal-p">{item.amount} Bags</p>
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
InfoOfDrop.propTypes = {
  show: PropTypes.any,
  close: PropTypes.any,
  selectionList: PropTypes.any,
};
export default InfoOfDrop;
