import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { useTranslation } from "react-i18next";
import { StyledButton } from "../common/Button.style";
import TextField from "../common/ui/TextField";
import CheckBox from "../common/ui/CheckBox";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import { TailSpin } from "react-loader-spinner";
import Col from "react-bootstrap/Col";
import { Toaster, toast } from "react-hot-toast";
import { Form, Formik } from "formik";
import { Container } from "react-bootstrap";
import { getUserProfile, updateLogisticProfile } from "../services/AuthService";
import { Logistic_User_Role } from "../constants/Constants";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { AnchorButton, SubmitButton } from "../common/Button.style";
import Row from "react-bootstrap/Row";
import "../common/common.scss";
import TextFieldWithUnit from "../common/ui/TextFieldWithUnit";

const LogisticProfile = () => {
  const { t } = useTranslation();
  const [userProfileData, setUserProfileData] = useState({
    name: "",
    surname: "",
    email: "",
    company: "",
    street: "",
    streetNr: "",
    zipCode: "",
    city: "",
    isPickUp: false,
    pickUpPrice: "",
    pickUpEmailOrNumber: "",
    isPickUpPriceRequest: false,
    isPickUpPriceRequestPrice: false,
    isDropOff: false,
    dropOffPrice: "",
    dropOffEmailOrNumber: "",
    isDropOffPriceRequest: false,
    isDropOffPriceRequestPrice: false,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const digitsOnly = (value) => {
    let a = /^[1-9]\d*(\.\d+)?$/.test(value);
    return a;
  };
  const emailFormat = (value) => {
    let a = /\S+@\S+\.\S+/.test(value);
    return a;
  };
  const phoneFormat = (value) => {
    let a = /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/.test(value);
    return a;
  };
  const getUserProfileData = async () => {
    setIsFetching(true);
    const result = await getUserProfile();
    const userData = result?.payLoad;
    const userCompany = userData?.company;
    setCompanyId(userCompany.companyId);

    const profileData = {
      name: userData?.name,
      surname: userData?.surname,
      email: userCompany?.email,
      company: userCompany?.name,
      street: userCompany?.street,
      streetNr: userCompany?.streetNo,
      zipCode: userCompany?.zipCode,
      city: userCompany?.city,
      isPickUp: userCompany?.isPickUp,
      pickUpPrice: userCompany?.pickUpPrice ? userCompany?.pickUpPrice : "",
      pickUpEmailOrNumber: userCompany?.pickUpEmailOrNumber,
      isPickUpPriceRequest: userCompany?.isPickUpPriceRequest,
      isPickUpPriceRequestPrice: userCompany?.pickUpPrice ? true : false,
      isDropOff: userCompany?.isDropOff,
      dropOffPrice: userCompany?.dropOffPrice ? userCompany?.dropOffPrice : "",
      dropOffEmailOrNumber: userCompany?.dropOffEmailOrNumber,
      isDropOffPriceRequest: userCompany?.isDropOffPriceRequest,
      isDropOffPriceRequestPrice: userCompany?.dropOffPrice ? true : false,
    };
    setUserProfileData(profileData);
    setIsFetching(false);
  };
  const updateLogisticProfileData = async (data) => {
    setIsUpdating(true);
    try {
      const response = await updateLogisticProfile(companyId, data);
      if (response?.success) {
        toast.success(t("record-updated"));
        localStorage.setItem("companyName", response.payLoad.name);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  const containsNumber = (str) => {
    return /\d/.test(str);
  };
  const containSpecialChar = (str) => {
    return /[-!$%^&*()£_+|~=`{}\[\]:\/;<>?,.@#]/.test(str);
  };
  const validate = Yup.object().shape(
    {
      name: Yup.string()
        .required(t(t("required-err")))
        .max(15, t("name-field-err-logistic"))
        .matches(/[A-Za-zÄäÆæß ]+/, t("ft-name-field-err4"))
        .test(
          "Firstname",
          t("invalid-ft-name"),
          (value) => !containsNumber(value) && !containSpecialChar(value)
        ),
      surname: Yup.string()
        .required(t(t("required-err")))
        .max(15, t("surnamename-field-err"))
        .matches(/[A-Za-zÄäÆæß ]+/, t("lt-name-field-err"))
        .test(
          "Lastname",
          t("invalid-lt-name"),
          (value) => !containsNumber(value) && !containSpecialChar(value)
        ),
      email: Yup.string().email().label("Email"),
      city: Yup.string()
        .required(t("required-err"))
        .max(40, t("city-field-err"))
        .matches(/[A-Za-zÄäÆæß ]+/, t("city-field-err1")),
      // .min(5, t("city-field-err2"))
      // .max(10, t("city-field-err2"))
      // .test(
      //   "City",
      //   t("invalid-city"),
      //   (value) => !containsNumber(value) && !containSpecialChar(value)
      // ),
      company: Yup.string()
        .required(t("required-err"))
        .max(60, t("company-field-err-logistic"))
        .matches(/[A-Za-zÄäÆæß ]+/, t("company-field-err")),

      zipCode: Yup.string()
        .label("Zip code")
        .matches(/^[0-9]/, t("logistic-profile-zipcode-error"))
        .required(t("zip-code-rq"))
        .min(5, t("city-field-err2"))
        .max(10, t("city-field-err2")),

      street: Yup.string()
        .required(t("required-err"))
        .max(25, t("logistic-profile-street-limit")),
      //  .matches(/[A-Za-zÄäÆæß ]+/, "Street contains characters only.")
      // .test(
      //   "Street",
      //   "Invaild street",
      //   (value) => !containsNumber(value) && !containSpecialChar(value)
      // )
      streetNr: Yup.string()
        .required(t("required-err"))
        .max(25, t("streetnr-field-err"))
        .matches(/^[-_a-zA-Z0-9.]+$/, t("st-no-err")),

      isPickUp: Yup.bool().when("isDropOff", {
        is: (val) => {
          return !val;
        },
        then: Yup.bool()
          .required(t("required-err"))
          .test("true check", t("required-err"), (value) => {
            return value;
          }),
        otherwise: Yup.bool().notRequired(),
      }),
      isDropOff: Yup.bool().when("isPickUp", {
        is: (val) => {
          return !val;
        },
        then: Yup.bool()
          .required(t("required-err"))
          .test("true check", t("required-err"), (value) => {
            return value;
          }),
        otherwise: Yup.bool().notRequired(),
      }),
      isPickUpPriceRequest: Yup.bool().when("isPickUp", {
        is: true,
        then: Yup.bool().when("isPickUpPriceRequestPrice", {
          is: (val) => {
            return val;
          },
          then: Yup.bool(),
          otherwise: Yup.bool().oneOf([true], "required"),
        }),
      }),
      isDropOffPriceRequest: Yup.bool().when("isDropOff", {
        is: true,
        then: Yup.bool().when("isDropOffPriceRequestPrice", {
          is: (val) => {
            return val;
          },
          then: Yup.bool(),
          otherwise: Yup.bool().oneOf([true], "required"),
        }),
      }),
      isPickUpPriceRequestPrice: Yup.bool().when("isPickUp", {
        is: true,
        then: Yup.bool().when("isPickUpPriceRequest", {
          is: (val) => {
            return val;
          },
          then: Yup.bool(),
          otherwise: Yup.bool().oneOf([true], "required"),
        }),
      }),
      isDropOffPriceRequestPrice: Yup.bool().when("isDropOff", {
        is: true,
        then: Yup.bool().when("isDropOffPriceRequest", {
          is: (val) => {
            return val;
          },
          then: Yup.bool(),
          otherwise: Yup.bool().oneOf([true], "required"),
        }),
      }),
      pickUpPrice: Yup.string()
        .nullable()
        .when("isPickUp", {
          is: (val) => {
            return val;
          },
          then: Yup.string().when("isPickUpPriceRequest", {
            is: (val) => {
              return val;
            },
            then: Yup.string().notRequired(),
            otherwise: Yup.string()
              .required(t("logistic-profile-form-pickup"))
              .test(
                "Digits only",
                t("logistic-profile-pickup-error"),
                digitsOnly
              )
              .min(0, t("logistic-profile-form-error"))
              .max(4, t("logistic-profile-form-error"))
              .nullable(),
          }),
        }),
      pickUpEmailOrNumber: Yup.string()
        .nullable()
        .when("isPickUpPriceRequest", {
          is: true,
          then: Yup.string()
            .required(t("logistic-profile-form-pickup-email-or-number"))
            .min(0, t("logistic-profile-form-min-max"))
            .max(50, t("logistic-profile-form-min-max"))
            .test(
              "Valid input",
              t("logistic-profile-form-valid-email-or-number"),
              (value) => emailFormat(value) || phoneFormat(value)
            )
            .nullable(true),
        }),
      dropOffPrice: Yup.string()
        .nullable()
        .when("isDropOff", {
          is: (val) => {
            return val;
          },
          then: Yup.string().when("isDropOffPriceRequest", {
            is: (val) => {
              return val;
            },
            then: Yup.string().notRequired(),
            otherwise: Yup.string()
              .required(t("logistic-profile-form-dropoff"))
              .test(
                "Digits only",
                t("logistic-profile-dropoff-error"),
                digitsOnly
              )
              .min(0, t("logistic-profile-form-error"))
              .max(4, t("logistic-profile-form-error"))
              .nullable(),
          }),
        }),

      dropOffEmailOrNumber: Yup.string()
        .nullable()
        .when("isDropOffPriceRequest", {
          is: true,
          then: Yup.string()
            .required(t("logistic-profile-form-dropoff-email-or-number"))
            .min(0, t("logistic-profile-form-min-max"))
            .max(50, t("logistic-profile-form-min-max"))
            .test(
              "Valid input",
              t("logistic-profile-form-valid-email-or-number"),
              (value) => emailFormat(value) || phoneFormat(value)
            )
            .nullable(true),
        }),
    },
    [
      ["isPickUp", "isDropOff"],
      ["isPickUpPriceRequest", "isPickUpPriceRequestPrice"],
      ["isDropOffPriceRequest", "isDropOffPriceRequestPrice"],
    ]
  );

  const handleSubmit = async (values) => {
    const payLoad = {
      company: values.company,
      city: values.city,
      street: values.street,
      streetNo: values.streetNr,
      zipCode: values.zipCode,
      isPickUp: values.isPickUp,
      pickUpPrice: values.pickUpPrice,
      isDropOff: values.isDropOff,
      dropOffPrice: values.dropOffPrice,
      userName: values.name,
      userSurname: values.surname,
      email: values?.email,
      pickUpEmailOrNumber: values?.pickUpEmailOrNumber,
      isPickUpPriceRequest: values?.isPickUpPriceRequest,
      dropOffEmailOrNumber: values?.dropOffEmailOrNumber,
      isDropOffPriceRequest: values?.isDropOffPriceRequest,
    };
    await updateLogisticProfileData(payLoad);
  };
  useEffect(() => {
    getUserProfileData();
  }, []);

  const currentUserRole = localStorage.getItem("role");
  const checkUser = () => {
    if (Logistic_User_Role !== currentUserRole) {
      window.location = "/";
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  const handleMouseEnter = () => {
    setShowInfo(true);
  };

  const handleMouseLeave = () => {
    setShowInfo(false);
  };

  return (
    <Container className="logistic-profile-container  mt-5  pt-5">
      <Toaster />
      <h1 className="my-5 fw-bold">Profil</h1>
      {!isFetching ? (
        <Formik
          initialValues={userProfileData}
          validationSchema={validate}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <Row>
                <Col className="general-info-col-1" lg={3}>
                  <p className="fw-bold ">
                    {t("logistic-profile-general-information")}
                  </p>
                  <TextField
                    border={true}
                    label={t("logistic-profile-name")}
                    type="text"
                    name="name"
                    marginTop="20px"
                  />
                  <TextField
                    border={true}
                    label={t("logistic-profile-surname")}
                    type="text"
                    name="surname"
                    marginTop="20px"
                  />
                  <TextField
                    border={true}
                    label={t("logistic-profile-email")}
                    type="email"
                    name="email"
                    disabled
                    marginTop="20px"
                  />
                  <div className="mt-5">
                    <Link
                      to="/forgetpassword"
                      // onClick={() => tracking({ value: "/forgetpassword" })}
                    >
                      <AnchorButton as="a">
                        {t("login-forgot-password-link")}
                      </AnchorButton>
                    </Link>
                  </div>
                </Col>
                <Col className="general-info-col-2" lg={4}>
                  <TextField
                    border={true}
                    label={t("logistic-profile-company")}
                    type="text"
                    name="company"
                    marginTop="44px"
                  />
                  <div className="d-flex justify-content-between w-100 general-info-input-wrapper-container">
                    <div
                      className="general-info-input-wrapper"
                      style={{ width: "60%" }}
                    >
                      <TextField
                        border={true}
                        label={t("logistic-profile-street")}
                        type="text"
                        name="street"
                        width="100%"
                        marginTop="20px"
                      />
                    </div>
                    <div
                      className="general-info-input-wrapper"
                      style={{ width: "35%" }}
                    >
                      <TextField
                        border={true}
                        label={t("logistic-profile-number")}
                        type="text"
                        name="streetNr"
                        width="100%"
                        marginTop="20px"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-100 general-info-input-wrapper-container">
                    <div
                      className="general-info-input-wrapper"
                      style={{ width: "28%" }}
                    >
                      <TextField
                        border={true}
                        label={t("logistic-profile-zip")}
                        type="text"
                        name="zipCode"
                        width="100%"
                        marginTop="20px"
                      />
                    </div>
                    <div
                      className="general-info-input-wrapper"
                      style={{ width: "67%" }}
                    >
                      <TextField
                        border={true}
                        label={t("logistic-profile-city")}
                        type="text"
                        name="city"
                        width="100%"
                        marginTop="20px"
                      />
                    </div>
                  </div>
                </Col>
                <Col></Col>
                <Col lg={4} className="logistic-info-column">
                  <div
                    className="d-flex align-items-center"
                    style={{ position: "relative", maxWidth: "fit-content" }}
                  >
                    <p className="fw-bold m-0">
                      {t("logistic-profile-information")}
                    </p>
                    <img
                      src="/assets/icons/info.png"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />
                    {showInfo && (
                      <div className="info">
                        Wenn Sie Abholung oder Anlieferung anwählen, erscheint
                        Ihr Firmenname mit (Abholung) oder (Anlieferung) für den
                        Händler. Wenn Sie beides anwählen, erscheint Ihr
                        Firmenname jeweils mit (Abholung) und (Anlieferung) für
                        den Händler. Wenn Sie Preis auf Anfrage wählen,
                        erscheint für den Händler das Feld “Preis unter
                        angegebener E-Mail/Telefonnummer anfragen”. Wenn Sie
                        einen Preis eingeben, erscheint für den Händler der
                        eingegebene Preis in €.
                      </div>
                    )}
                  </div>
                  <CheckBox
                    name="isPickUp"
                    onChange={(e) => {
                      formik.setFieldValue("isPickUp", e.target.checked);
                      if (!e.target.checked) {
                        formik.setFieldValue("isPickUpPriceRequest", false);
                        formik.setFieldValue(
                          "isPickUpPriceRequestPrice",
                          false
                        );
                      }
                    }}
                    checked={formik.values.isPickUp}
                    label={t("logistic-profile-logistic-information-pickup")}
                    marginTop="25px"
                    inputClassname="logistic-profile-checkbox "
                    inputLabelClassname={`logistic-profile-checkbox-label ${
                      formik.errors.isDropOff || formik.errors.isPickUp
                        ? "border-bottom-red"
                        : null
                    }`}
                  />

                  <CheckBox
                    name="isPickUpPriceRequest"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "isPickUpPriceRequest",
                        e.target.checked
                      );
                      formik.setFieldValue(
                        "isPickUpPriceRequestPrice",
                        !e.target.checked
                      );
                    }}
                    checked={formik.values.isPickUpPriceRequest}
                    label={t("price-on-request")}
                    marginTop="25px"
                    inputClassname="logistic-profile-checkbox "
                    inputLabelClassname={`logistic-profile-checkbox-label ${
                      formik.errors.isPickUpPriceRequest
                        ? "border-bottom-red"
                        : null
                    }`}
                    marginLeft="10%"
                    disabled={!formik.values.isPickUp}
                  />
                  <TextField
                    border={true}
                    type="text"
                    name="pickUpEmailOrNumber"
                    width="100%"
                    marginLeft="10%"
                    placeholder={t(
                      "logistic-profile-logistic-email-placeholder"
                    )}
                    disabled={!formik.values.isPickUpPriceRequest}
                  />
                  <CheckBox
                    name="isPickUpPriceRequestPrice"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "isPickUpPriceRequestPrice",
                        e.target.checked
                      );
                      formik.setFieldValue(
                        "isPickUpPriceRequest",
                        !e.target.checked
                      );
                    }}
                    checked={formik.values.isPickUpPriceRequestPrice}
                    label={t("enter-price")}
                    marginTop="25px"
                    inputClassname="logistic-profile-checkbox "
                    inputLabelClassname={`logistic-profile-checkbox-label ${
                      formik.errors.isPickUpPriceRequestPrice
                        ? "border-bottom-red"
                        : null
                    }`}
                    marginLeft="10%"
                    disabled={!formik.values.isPickUp}
                  />
                  <TextFieldWithUnit
                    showLabel={false}
                    type="text"
                    name="pickUpPrice"
                    marginTop="0px"
                    placeholder={t(
                      "logistic-profile-logistic-information-placeholder"
                    )}
                    marginLeft="10%"
                    disabled={!formik.values.isPickUpPriceRequestPrice}
                    width={
                      formik.values?.pickUpPrice
                        ? `${formik.values?.pickUpPrice.toString().length}ch
                          `
                        : "100%"
                    }
                    padding="0"
                  />
                  <CheckBox
                    name="isDropOff"
                    onChange={(e) => {
                      formik.setFieldValue("isDropOff", e.target.checked);
                      if (!e.target.checked) {
                        formik.setFieldValue("isDropOffPriceRequest", false);
                        formik.setFieldValue(
                          "isDropOffPriceRequestPrice",
                          false
                        );
                      }
                    }}
                    checked={formik.values.isDropOff}
                    //   isDisabled={active < 4 || active === 5}
                    label={t("logistic-profile-logistic-information-delivery")}
                    // marginLeft={"15px"}
                    marginTop="25px"
                    inputClassname="logistic-profile-checkbox"
                    inputLabelClassname={`logistic-profile-checkbox-label ${
                      formik.errors.isDropOff || formik.errors.isPickUp
                        ? "border-bottom-red"
                        : null
                    }`}
                  />
                  <CheckBox
                    name="isDropOffPriceRequest"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "isDropOffPriceRequest",
                        e.target.checked
                      );
                      formik.setFieldValue(
                        "isDropOffPriceRequestPrice",
                        !e.target.checked
                      );
                    }}
                    checked={formik.values.isDropOffPriceRequest}
                    label={t("price-on-request")}
                    marginTop="25px"
                    inputClassname="logistic-profile-checkbox "
                    inputLabelClassname={`logistic-profile-checkbox-label ${
                      formik.errors.isDropOffPriceRequest
                        ? "border-bottom-red"
                        : null
                    }`}
                    marginLeft="10%"
                    disabled={!formik.values.isDropOff}
                  />
                  <TextField
                    border={true}
                    type="text"
                    name="dropOffEmailOrNumber"
                    width="100%"
                    marginLeft="10%"
                    placeholder={t(
                      "logistic-profile-logistic-email-placeholder"
                    )}
                    disabled={!formik.values.isDropOffPriceRequest}
                  />
                  <CheckBox
                    name="isDropOffPriceRequestPrice"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "isDropOffPriceRequestPrice",
                        e.target.checked
                      );
                      formik.setFieldValue(
                        "isDropOffPriceRequest",
                        !e.target.checked
                      );
                    }}
                    checked={formik.values.isDropOffPriceRequestPrice}
                    label={t("enter-price")}
                    marginTop="25px"
                    inputClassname="logistic-profile-checkbox "
                    inputLabelClassname={`logistic-profile-checkbox-label ${
                      formik.errors.isDropOffPriceRequestPrice
                        ? "border-bottom-red"
                        : null
                    }`}
                    marginLeft="10%"
                    disabled={!formik.values.isDropOff}
                  />
                  <TextFieldWithUnit
                    showLabel={false}
                    type="text"
                    name="dropOffPrice"
                    marginTop="0px"
                    marginLeft="10%"
                    placeholder={t(
                      "logistic-profile-logistic-information-placeholder"
                    )}
                    width={
                      formik.values?.dropOffPrice
                        ? `${formik.values?.dropOffPrice.toString().length}ch
                          `
                        : "100%"
                    }
                    padding="0"
                    disabled={!formik.values.isDropOffPriceRequestPrice}
                  />
                  {formik.errors.isDropOff || formik.errors.isPickUp ? (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      {t("logistic-profile-selection-error")}
                    </p>
                  ) : null}
                  <div className="d-flex justify-content-md-end justify-content-center  w-100 ">
                    <StyledButton
                      className="save-profile-button mt-5"
                      borderRadius={"0"}
                      color={"#fff"}
                      padding={"0.5rem 2.5rem"}
                      type="submit"
                      minWidth="277px"
                      textAlign="center"
                      disabled={isUpdating}
                    >
                      {isUpdating ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "center",
                          }}
                        >
                          <TailSpin height="25px" color="#000000" />
                        </div>
                      ) : (
                        t("logistic-profile-submit-button")
                      )}
                    </StyledButton>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      ) : (
        <div
          className="d-flex justify-content-center"
          style={{
            justifyContent: "center !important",
            alignItems: "center",
            minHeight: "calc(100vh - 400px)",
          }}
        >
          <TailSpin color="#000000" height="100px" />
        </div>
      )}
    </Container>
  );
};

export default LogisticProfile;
