import React from "react";
import { useTranslation } from "react-i18next";
const CustomDropdown = (props) => {
  const { width, list, onChange, label, name, padding, onKeyDown } = props;
  const { t } = useTranslation();
  return (
    <div className="custom-dropdown-2 d-flex position-relative">
      <div
        className="position-relative"
        style={{
          width: width ? width : "75%",
        }}
      >
        {label && <label style={{ fontWeight: "bold" }}>{label}</label>}
        <select
          name={name}
          style={{ padding: padding || "0.5rem" }}
          onChange={onChange}
          onKeyDown={onKeyDown}
        >
          <option disabled defaultValue selected value="">
            {t("Select")}
          </option>
          {list?.map((item, index) => (
            <option
              key={index}
              value={JSON.stringify(
                item.roleId
                  ? item.roleId
                  : item.companyId
                  ? item.companyId
                  : item
              )}
            >
              {t("" + item.roleName + "")}
              {/* {item.roleName
                ? item.roleName
                : item.companyName
                ? item.companyName
                : item.name} */}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default CustomDropdown;
