import React from "react";
import styled from "styled-components";

const InputGroup = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  margin: 0 0 10px 0;
`;
const InputGroupTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
const InputLabel = styled.label`
  color: black;
  top: 27px;
  background: #ffffff;
  font-weight: ${(props) => (props.labelWeight ? props.labelWeight : 600)};
  transition: 300ms;
`;

const InputField = styled.input`
  outline: none;
  padding: 5px 0px 0 0px;
  border: none;
  border-bottom: ${(props) =>
    props.error ? "2px solid red" : "2px solid #ffb100"};
  font-size: 18px;
  width: ${(props) => (props.width ? props.width : "20ch")};
  @media (max-width: 480px) {
    width: 100% !important;
  }

  &:focus {
    border-bottom: 2px solid black;
  }

  &:valid + ${InputLabel} {
    top: -1px;
    padding: 0 3px;
    font-size: 14px;
    color: #ffb100;
  }

  &:focus + ${InputLabel} {
    padding: 0 3px;
    font-size: 14px;
    color: #ffb100;
    transition: 300ms;
  }
`;
const NumberInput = styled(InputField).attrs({
  type: "number",
})`
  color: black;
  width: ${(props) => (props.width ? props.width : "20ch")};
  @media (max-width: 480px) {
    /* width: 15ch !important; */
    width: 100% !important;
  }
`;
const EmailInput = styled(InputField).attrs({
  type: "email",
})`
  color: black;
  width: ${(props) => (props.width ? props.width : "20ch")};
  @media (max-width: 480px) {
    width: 100% !important;
  }
`;
const TimeInput = styled(InputField).attrs({
  type: "time",
})`
  color: black;
  display: inline-flex;
  @media (max-width: 480px) {
    width: 15ch !important;
  }
`;
const PasswordInput = styled(InputField).attrs({
  type: "password",
})`
  color: black;
  display: inline-flex;

  @media (max-width: 480px) {
    width: 100% !important;
  }
`;

export const TextInputGroup = ({ id, label, labelWeight, width, ...rest }) => {
  return (
    <InputGroup>
      <InputLabel htmlFor={id} labelWeight={labelWeight}>
        {label}
      </InputLabel>

      <InputField id={id} width={width} {...rest} />
    </InputGroup>
  );
};
export const EmailInputGroup = ({ id, label, labelWeight, ...rest }) => {
  return (
    <InputGroup>
      <InputLabel htmlFor={id} labelWeight={labelWeight}>
        {label}
      </InputLabel>

      <EmailInput id={id} {...rest} />
    </InputGroup>
  );
};
export const PasswordInputGroup = ({ id, label, labelWeight, ...rest }) => {
  return (
    <InputGroup>
      <InputLabel htmlFor={id} labelWeight={labelWeight}>
        {label}
      </InputLabel>

      <PasswordInput id={id} {...rest} />
    </InputGroup>
  );
};
export const NumberInputGroup = ({ id, label, width, ...rest }) => {
  return (
    <InputGroup>
      <InputLabel htmlFor={id}>{label}</InputLabel>

      <NumberInput
        onKeyDown={(evt) =>
          (evt.key === "e" && evt.preventDefault()) ||
          (evt.key === "-" && evt.preventDefault()) ||
          (evt.key === "." && evt.preventDefault())
        }
        min="1"
        id={id}
        {...rest}
        width={width}
      />
    </InputGroup>
  );
};
export const TimeInputGroup = ({ id, label, ...rest }) => {
  return (
    <InputGroup>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <InputGroupTime>
        <TimeInput id={id} value="start" {...rest} />
      </InputGroupTime>
    </InputGroup>
  );
};
