import React from "react";
import { Row, Col } from "react-bootstrap";
import NavHome from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import Footer from "../../common/ui/Footer";
import { Trans } from "react-i18next";

const TermsOfUse = () => {
  const { t } = useTranslation();
  function boldString(str, substr) {
    var strRegExp = new RegExp(substr, "g");
    return str.replace(strRegExp, "<b>" + substr + "</b>");
  }
  return (
    <div className="terms-section">
      <NavHome />
      <div className="main-div container">
        <div className="d-flex link">
          <p>Start </p>
          <p>&nbsp;/ {t("terms-link")}</p>
        </div>
        <div className="d-flex align-items-center w-100">
          <h1 className="fw-bold me-5">{t("terms-heading")}</h1>
          <img src={"/assets/images/basf-logo2.png"} />
        </div>
        <div className="py-5">
          <Row>
            <Col lg={6} sm={12}>
              <b>{t("top-heading-tou")}</b>
              <br />
              <br />
              <p>
                <b>{t("p1-tou")}</b>
                <br />
                <Trans i18nKey="p2-tou" />
                <br />
                <br />
                <b>{t("p3-tou")}</b>
                <br />
                <Trans i18nKey="p4-tou" />
                <br />
                <br />
                {t("p5-tou")}
                <br />
                <br />
                {t("p6-tou")}
                <br />
                <br />
                <Trans i18nKey="p7-tou" />
                <br />
                <br />
                {t("p8-tou")}
                <br />
                <br />
                <b>{t("p9-tou")}</b>
                <br />
                <br />
                <Trans i18nKey="p10-tou" />
                <br />
                <br />
                {t("p11-tou")}
                <br />
                <br />
                {t("p12-tou")}
                <br />
                <br />
                {t("p13-tou")}
                <br />
                <br />
                {t("p14-tou")}
                <br />
                <br />
                <b>{t("p15-tou")}</b>
                <br />
                <br />
                {t("p16-tou")}
                <br />
                <br />
                {t("p17-tou")}
                <br />
                <br />
                {t("p18-tou")}
                <br />
                <br />
                {t("p19-tou")}
                <br />
                <br />
                <b>{t("p20-tou")}</b>
                <br />
                <br />
                {t("p21-tou")}
                <br />
                <br />
                {t("p22-tou")}
                <br />
                <br />
                {t("p23-tou")}
                <br />
                <br />
                {t("p24-tou")}
                <br />
                <br />
                {t("p25-tou")}
                <br />
                <br />
                {t("p26-tou")}
                <br />
                <br />
                {t("p27-tou")}
                <br />
                <br />
                {t("p28-tou")}
                <br />
                <br />
                {t("p29-tou")}
                <br />
                <br />
                {t("p30-tou")}
                <br />
                <br />
                {t("p31-tou")}
                <br />
                <br />
                {t("p32-tou")}
                <br />
                <br />
                {t("p33-tou")}
                <br />
                <br />
                {t("p34-tou")}
                <br />
                <br />
                <b>{t("p35-tou")}</b>
                <br />
                <br />
                {t("p36-tou")}
                <br />
                <br />
                {t("p37-tou")}
                <br />
                <br />
                {t("p38-tou")}
                <br />
                <br />
                {t("p39-tou")}
                <br />
                <br />
                <b>{t("p40-tou")}</b>
                <br />
                <br />
                {t("p41-tou")}
                <br />
                <br />
                {t("p42-tou")}
                <br />
                <br />
                {t("p43-tou")}
                <br />
                <br />
                {t("p44-tou")}
                <br />
                <br />
                <b>{t("p45-tou")}</b>
                <br />
                <br />
                {t("p46-tou")}
                <br />
                <br />
                {t("p47-tou")}
                <br />
                <br />
                {t("p48-tou")}
                <br />
                <br />
                {t("p49-tou")}
                <br />
                <br />
                {t("p50-tou")}
                <br />
                <br />
                <b>{t("p51-tou")}</b>
                <br />
                <br />
                <Trans i18nKey="p52-tou" />
                <br />
                <br />
                <b>{t("p53-tou")}</b>
                <br />
                <br />
                {t("p54-tou")}
                <br />
                <br />
                {t("p55-tou")}
                <br />
                <br />
                {t("p56-tou")}
                <br />
                <br />
                {t("p57-tou")}
                <br />
                <br />
                {t("p58-tou")}
                <br />
                <br />
                {t("p59-tou")}
                <br />
                <br />
                {t("p60-tou")}
                <br />
                <br />
                {t("p61-tou")}
                <br />
                <br />
                {t("p62-tou")}
                <br />
                <br />
                {t("p63-tou")}
                <br />
                <br />
                <b>{t("p64-tou")}</b>
                <br />
                <br />
                {t("p65-tou")}
                <br />
                <br />
                <b>{t("p66-tou")}</b>
                <br />
                <br />
                {t("p67-tou")}
                <br />
                <br />
                {t("p68-tou")}
                <br />
                <br />
                {t("p69-tou")}
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfUse;
