import styled from "styled-components";
import { max_content } from "../constants/Constants";

export const StyledButton = styled.button`
  border: 2px solid #ffb100;
  background-color: ${(props) =>
    props.variant === "outline" ? "#fff" : "#ffb100"};
  color: ${(props) => (props.color ? props.color : "#000000")};
  padding: ${(props) => (props.padding ? props.padding : "10px 70px")};
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: ${(props) => (props.margin ? props.margin : "")};
  font-size: ${(props) => (props.fs ? props.fs : "16px")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "1rem"};
  width: ${(props) => (props.width ? props.width : max_content)};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "max-content")};

  cursor: pointer;
  transition: 0.5s all ease-out;
  &:hover {
    background-color: ${(props) =>
      props.variant !== "outline" ? "#fff" : "#ffb100"};
    color: ${(props) => (props.variant !== "outline" ? "#ffb100" : "#000000")};
  }
  @media (max-width: 780px) and (min-width: 500px) {
    padding: ${(props) => (props.padding ? props.padding : "10px 24px")};
    width: ${(props) => (props.tWidth ? props.tWidth : "")};
  }
  @media (max-width: 480px) {
    padding: ${(props) => (props.padding ? props.padding : "8px 16px")};
    min-width: ${(props) => (props.minWidth ? props.minWidth : "max-content")};
    width: ${(props) => (props.mWidth ? props.mWidth : "")};
  }
`;

export const SubmitButton = styled(StyledButton).attrs({
  type: "submit",
})`
  color: ${(props) => (props.color ? props.color : "black")};
`;

export const AnchorButton = styled.button`
  border: none;
  color: ${(props) => (props.color ? props.color : "#ffb100")};
  background: transparent;
  &:hover {
    color: blue;
    cursor: pointer;
  }
`;
export const DeactiveButton = styled(StyledButton)`
  opacity: 0.6;
  pointer-events: ${(props) => (props.pointerEvent ? props.pointerEvent : "")};
  cursor: not-allowed;
  &:hover {
    background-color: ${(props) =>
      props.variant === "outline" ? "#fff" : "#ffb100"};
    color: ${(props) => (props.variant === "outline" ? "#ffb100" : "#fff")};
  }
`;
