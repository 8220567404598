import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { StyledButton } from "../Button.style";
import { Row, Col, Modal, Spinner } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
import "./modal1.scss";
import PropTypes from "prop-types";
import TextField from "../ui/TextField";
import { addOrderPickUpDate } from "../../services/OrderServices";
import { DAY, YEAR } from "../../constants/Constants";
import { useTranslation } from "react-i18next";
import { getTranslatedMessage } from "../../common/utils/Utilities";
const OpeningHours = (props) => {
  const [btn_loading, setbtn_loading] = useState(false);
  const { show, close, data, setCheckChangesTrue, orderId } = props;

  const { t } = useTranslation();
  let currentDate = new Date();
  const validate = Yup.object().shape({
    month: Yup.string()
      .matches(/^([0-9]{1,2})$/, t("invalid-month"))
      .test("month", t("invalid-month"), (value) => {
        if (value < 1 || value > 12) {
          return false;
        }
        return true;
      })
      .min(1, "Invalid")
      .max(2, "Invalid")
      .required(t("required-err")),
    year: Yup.string()
      .test(YEAR, t("enter-current-year"), (value) => {
        const today = new Date();
        const adultYear = today.getFullYear();
        if (value >= adultYear) {
          return true;
        }
        return false;
      })
      .matches(/^([0-9]{4})$/, t("enter-current-year"))
      .min(4, "Must be 4 digits")
      .max(4, "Must be 4 digits")
      .required(t("required-err")),
    day: Yup.string()
      .test(DAY, t("invalid-day"), function (day) {
        const month = this.options.parent.month;
        const year = this.options.parent.year;
        // February
        if (month === 2) {
          const isLeapYear =
            year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

          if (day > 29 || (day === 29 && !isLeapYear)) {
            return false;
          }
        }
        return true;
      })
      .test(DAY, t("invalid-day"), function (day) {
        const month = this.options.parent.month;
        if (
          (month === 4 || month === 6 || month === 9 || month === 11) &&
          day === 31
        ) {
          return false;
        }
        return true;
      })
      .test(DAY, t("invalid-day"), (day) => {
        if (day < 1 || day > 31) {
          return false;
        }
        return true;
      })
      .matches(/^([0-9]{1,2})$/, "Digits Only")
      .min(1, t("invalid-day"))
      .max(2, t("invalid-day"))
      .required(t("required-err")),
  });
  const handleSubmit = async (formik) => {
    let day = formik.values.day;
    let month = formik.values.month;
    let year = formik.values.year;

    let currentYear = new Date().getFullYear();
    if (year >= currentYear) {
      if (Number(day) < 10) {
        day = "0" + Number(day);
      }
      if (Number(month) < 10) {
        month = "0" + Number(month);
      }
      let date = month + "-" + day + "-" + year;
      setbtn_loading(true);
      let result = await addOrderPickUpDate({
        orderId: orderId,
        estimatesCollectionDate: date,
        collectionDate: null,
      });
      if (result?.success) {
        toast.success(getTranslatedMessage(result?.message));
        setCheckChangesTrue();
        close();
      } else {
        toast.error(getTranslatedMessage(result?.statusText));
      }
      setbtn_loading(false);
    } else {
      toast.error("Year must be greater than or equal to current year.");
    }
  };
  return (
    <Modal
      contentClassName="working-hours-custom-modal"
      backdropClassName="working-hours-custom-modal-backdrop"
      show={show}
      centered
      size="md"
    >
      <div className="close-icon-div d-flex justify-content-end">
        <img
          style={{ marginBottom: "-7px", marginRight: "-5px" }}
          src="/assets/icons/cross-icon1.png"
          alt="cross-btn"
          onClick={close}
          className="custom-cursor"
        />
      </div>{" "}
      <Formik
        initialValues={{
          day: data?.estimatesCollectionDate
            ? data?.estimatesCollectionDate.substring(0, 2)
            : currentDate.getDate(),
          month: data?.estimatesCollectionDate
            ? data?.estimatesCollectionDate.substring(3, 5)
            : currentDate.getMonth() + 1,
          year: data?.estimatesCollectionDate
            ? data?.estimatesCollectionDate.substring(6)
            : currentDate.getFullYear(),
        }}
        validationSchema={validate}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formik) => (
          <>
            <div className="working-hours-modal custom-modal-body bg-white p-5">
              <h4>{t("heading-modal2")}</h4>
              <div className="d-flex mb-4 mt-3">
                <p className="mb-0">
                  <b>{t("from-time")} </b>
                  {data?.dropOffLocation?.openingHours}
                </p>
                <p className="mb-0 ms-5">
                  <b>{t("until-time") + " "}</b>
                  {data?.dropOffLocation?.closingHours}
                </p>
              </div>
              <Row className="mb-3">
                <p className="mb-0">
                  <b>{t("company")}</b> {data?.dropOffLocation?.name}
                </p>
              </Row>
              <Row className="mt-2">
                <Col lg={5}>
                  {[
                    {
                      name: `${t("street")}`,
                      desc: data?.dropOffLocation?.street,
                    },
                    {
                      name: `${t("zipcode")}: `,
                      desc: data?.dropOffLocation?.zipCode,
                    },
                    {
                      name: `${t("city")}: `,
                      desc: data?.dropOffLocation?.city,
                    },
                  ].map((item, index) => (
                    <p className="mb-0 d-flex" key={index}>
                      <b>{item.name}</b>{" "}
                      <p className="ms-2" style={{ wordBreak: "break-all" }}>
                        {item.name === "Straße:" || item.name === "Stadt:"
                          ? item.desc?.slice(0, 15)
                          : item.desc}
                      </p>
                    </p>
                  ))}
                </Col>
                {/* <Col lg={1} /> */}
                <Col lg={7}>
                  {[
                    {
                      name: `${t("nr")}: `,
                      desc: data?.dropOffLocation?.streetNo,
                    },
                    {
                      name: `${t("email")}: `,
                      desc: data?.dropOffLocation?.email,
                    },
                  ].map((item, index) => (
                    <p className="m-0 d-flex" key={index}>
                      <b>{item.name}</b>{" "}
                      <p className="ms-3">
                        {item.name === "Telefon alternative Kontaktperson:" ||
                        item.name === "Telefon der Kontaktperson:"
                          ? item.desc?.slice(0, 35)
                          : item.desc}
                      </p>
                    </p>
                  ))}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col lg={12} className="mt-4">
                  <h5>{t("sub-heading-modal2")}</h5>
                  <div className="d-flex date-section">
                    <div>
                      <TextField
                        className="numberField"
                        type="text"
                        name="day"
                        placeholder="Day"
                        value={formik.values.day}
                        maxLength={2}
                        max={31}
                        min={1}
                        onChange={(e) => {
                          let value = e.target.value;
                          const currentValue = value.replace(/[^\d]/g, "");
                          formik.setFieldValue("day", currentValue);
                        }}
                        width="55px"
                        padding="0.375rem 0.3rem"
                      />
                    </div>
                    <div>
                      <TextField
                        className="numberField"
                        type="text"
                        name="month"
                        placeholder="Month"
                        maxLength={2}
                        value={formik.values.month}
                        max={12}
                        min={1}
                        width="75px"
                        padding="0.375rem 0.3rem"
                        onChange={(e) => {
                          let value = e.target.value;
                          const currentValue = value.replace(/[^\d]/g, "");
                          formik.setFieldValue("month", currentValue);
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        className="numberField"
                        type="text"
                        name="year"
                        placeholder="Year"
                        value={formik.values.year}
                        maxLength={4}
                        min={2022}
                        width="75px"
                        padding="0.375rem 0.3rem"
                        onChange={(e) => {
                          let value = e.target.value;
                          const currentValue = value.replace(/[^\d]/g, "");
                          formik.setFieldValue("year", currentValue);
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              {formik.touched && formik.isValid ? (
                !btn_loading ? (
                  <StyledButton
                    style={{ border: "2px solid #ffb100" }}
                    type="submit"
                    className="submit-btn mt-4"
                    onClick={() => handleSubmit(formik)}
                  >
                    <span className="fw-bold">{t("confirm-button-label")}</span>
                  </StyledButton>
                ) : (
                  <button disabled className="submit-btn mt-4">
                    <span className="fw-bold">
                      <Spinner animation="border" />
                    </span>
                  </button>
                )
              ) : (
                <button
                  disabled
                  type="submit"
                  className="submit-btn mt-4 btn-disabled"
                >
                  <span className="fw-bold">{t("confirm-button-label")}</span>
                </button>
              )}
            </div>
          </>
        )}
      </Formik>
      <Toaster />
    </Modal>
  );
};
OpeningHours.propTypes = {
  show: PropTypes.any,
  close: PropTypes.any,
  data: PropTypes.any,
  setCheckChangesTrue: PropTypes.any,
  orderId: PropTypes.any,
};
export default OpeningHours;
