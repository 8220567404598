export const getTrackingParams = (item) => {
  let params;
  switch (item.value) {
    case "/collection":
      params = {
        page_name: "collection",
        page_category: "collection",
        page_subcategory: "collection",
        page_type: item.value,
        // product_prd: "30530121",
        // product_name: CEGESOFTHF52,
      };
      break;
    case "/jobs":
      params = {
        page_name: "jobs",
        page_category: "jobs",
        page_subcategory: "jobs",
        page_type: item.value,
        // product_prd: "30530121",
        // product_name: CEGESOFTHF52,
      };
      break;
    case "/profile":
      params = {
        page_name: "profile",
        page_category: "profile",
        page_subcategory: "profile",
        page_type: item.value,
        // product_prd: "30530121",
        // product_name: CEGESOFTHF52,
      };
      break;
    case "/create-account":
      params = {
        page_name: "create-account",
        page_category: "create-account",
        page_subcategory: "create-account",
        page_type: item.value,
        // product_prd: "30530121",
        // product_name: CEGESOFTHF52,
      };
      break;
    case "/forgetpassword":
      params = {
        page_name: "forgetpassword",
        page_category: "forgetpassword",
        page_subcategory: "forgetpassword",
        page_type: item.value,
        // product_prd: "30530121",
        // product_name: CEGESOFTHF52,
      };
      break;
    case "/login":
      params = {
        page_name: "login",
        page_category: "login",
        page_subcategory: "login",
        page_type: item.value,
        // product_prd: "30530121",
        // product_name: CEGESOFTHF52,
      };
      break;
    default:
  }
  return params;
};
