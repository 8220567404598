import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavHome from "../../components/Navbar";
import "./homepage.scss";
import Cookies from "../../components/Cookies";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Navigation, Scrollbar, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Footer from "../../common/ui/Footer";
import { MCYCLO_APP_URL } from "../../constants/Constants";

const Homepage = () => {
  const [slide, setSilde] = useState(0);
  const { t } = useTranslation();
  let token = localStorage.getItem("token");
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("./create-account");
  };

  const Slide1 = () => {
    const onScroll = () => {
      // const divElement = document.getElementById("content");
      // divElement.scrollIntoView({ behavior: "smooth" });
      window.open(MCYCLO_APP_URL, "_blank");
    };
    return (
      <div className="slide-one-background">
        <div className="gesture">
          <div style={{ paddingTop: "2rem" }}>
            <img src={"/assets/images/img2.png"} />
          </div>
          <div style={{ paddingTop: "1rem" }}>
            <img src={"/assets/images/img1.png"} className="ms-2" />
          </div>
        </div>
        <div className=" logo-container">
          <div className="mcyclo-logo">
            <img src={"/assets/images/mcyclo-logo.png"} />
          </div>
          <div>
            <div>
              <button className="home-btn" onClick={onScroll}>
                {t("Explore")}
                <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
              </button>
            </div>
            <div className="basf-logo">
              <p className="fw-bold">a solution by</p>
              <img src={"/assets/images/basf-logo.png"} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const steps = [
    {
      heading: t("step-one-h"),
      paragraphs: [t("step-one-p-one")],
      info: t("step-one-info"),
      images: ["/assets/images/img4.png"],
    },
    {
      heading: t("step-two-h"),
      paragraphs: [t("step-two-p-one")],
      info: "",
      images: ["/assets/images/img5.png", "/assets/images/img6.png"],
    },
    {
      heading: t("step-three-h"),
      paragraphs: [t("step-three-p-one")],
      info: t("step-three-info"),
      images: ["/assets/images/img7.png"],
    },
    {
      heading: t("step-four-h"),
      paragraphs: [t("step-four-p-one")],
      info: "",
      images: ["/assets/images/img8-1.png", "/assets/images/img8.png"],
    },
    {
      heading: t("step-five-h"),
      paragraphs: [t("step-five-p-one")],
      info: "",
      images: ["/assets/images/img9.png"],
    },
    {
      heading: t("step-six-h"),
      paragraphs: [t("step-six-p-one")],
      info: t("step-six-p-info"),
      images: ["/assets/images/img10-1.png", "/assets/images/img10.png"],
    },
  ];

  const steps2 = [
    {
      heading: t("step-seven-h"),
      paragraphs: [t("step-seven-p-one")],
      info: "",
      images: ["/assets/images/img11.png"],
    },
    {
      heading: t("step-eight-h"),
      paragraphs: [t("step-eight-p-one"), t("step-eight-p-two")],
      info: "",
      images: ["/assets/images/img12.png", "/assets/images/img20.png"],
    },
    {
      heading: t("step-nine-h"),
      paragraphs: [t("step-nine-p-one")],
      info: "",
      images: ["/assets/images/img14.png"],
    },
    {
      heading: t("step-ten-h"),
      paragraphs: [t("step-ten-p-one")],
      info: t("step-ten-info"),
      images: ["/assets/images/img13.png"],
    },
    {
      heading: t("step-eleven-h"),
      paragraphs: [t("step-eleven-p-one")],
      info: "",
      images: ["/assets/images/img15.png", "/assets/images/img18.png"],
    },
    {
      heading: t("step-twelve-h"),
      paragraphs: [t("step-twelve-p-one")],
      info: "",
      images: ["/assets/images/img19.png"],
    },
  ];
  const slidebtnOne = () => {
    const divElement = document.getElementById("Handyman");
    divElement.scrollIntoView({ behavior: "smooth" });
  };
  const slidebtnTwo = () => {
    const divElement = document.getElementById("builders_merchant");
    divElement.scrollIntoView({ behavior: "smooth" });
  };

  const Slide2 = () => {
    return (
      <div className="slide-two-background">
        <Row className="row">
          <h2 className="fw-bold">{t("slide-two-heading")}</h2>
          <Col lg={6} md={12}>
            <div className="slide-two-content">
              <div>
                <p>{t("slide-two-p")}</p>
                <div>
                  <button className="slide-two-btn" onClick={slidebtnOne}>
                    {t("slide-two-btn-one")}
                  </button>
                  <br />
                  <button className="slide-two-btn" onClick={slidebtnTwo}>
                    {t("slide-two-btn-two")}
                  </button>
                </div>
              </div>
              <div className="section-two">
                <img src={"/assets/images/img21.png"} />
              </div>
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="img-container">
              <img src={"/assets/images/img3.png"} />
            </div>
            <div className="qr-code-image">
              <img src={"/assets/images/QR-Code.png"} />
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const components = [<Slide1 />, <Slide2 />];

  let str1 = t("content-one-h").split(" ");
  let lastWord1 = str1.pop();
  let str2 = t("content-two-h").split(" ");
  let lastWord2 = str2.pop();

  return (
    // <div className="homepage">
    //   <NavHome />
    //   <div className="banner">
    //     {/* <Cookies /> */}
    //     <div className="bg-image">
    //       <div className="banner-text">
    //         <h1 className="text-uppercase">{t("home-heading")}</h1>

    //         {t("home-description")
    //           .split("\n")
    //           .map((c) => {
    //             return <h2 className="text-uppercase"> {c} </h2>;
    //           })}

    //         {token ? (
    //           ""
    //         ) : (
    //           <button
    //             className="create-account-button"
    //             onClick={() => handleClick()}
    //           >
    //             {t("create-account-button-label")}
    //           </button>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    //   {/* <Footer /> */}
    // </div>
    <div className="parent-container">
      <NavHome />
      <Swiper
        style={{
          "--swiper-navigation-size": "15px",
          "--swiper-theme-color": "#F4B43E",
        }}
        modules={[Navigation, Scrollbar, Pagination]}
        draggable
        navigation
        pagination
        slidesPerView={1}
        onSlideChange={(swiper) => {
          setSilde(swiper.activeIndex);
          if (swiper.activeIndex === 0) {
            document.getElementsByClassName(
              "swiper-button-prev"
            )[0].style.color = "#F4B43E";
            document.getElementsByClassName(
              "swiper-button-next"
            )[0].style.color = "#F4B43E";
          }
          if (swiper.activeIndex === 1) {
            document.getElementsByClassName(
              "swiper-button-prev"
            )[0].style.color = "#E3E3E3";
            document.getElementsByClassName(
              "swiper-button-next"
            )[0].style.color = "#E3E3E3";
          }
        }}
      >
        {components.map((elem, index) => {
          return <SwiperSlide key={`slide-${index}`}>{elem}</SwiperSlide>;
        })}
      </Swiper>

      <div className="content-zero" id="content">
        <div className="d-flex justify-content-center">
          <h4 className="fw-bold">
            mcyclo – Das Rücknahmesystem für saubere Styrodur®-Schnittreste
          </h4>
        </div>
        <div className="content-p">
          <p className="text-center">
            BASF bietet Verarbeitern des Dämmstoffs Styrodur® unter dem Namen
            mcyclo deutschlandweit ein Rücknahmesystem für saubere Styrodur®
            Baustellen- Schnittreste an. Diese werden in Zusammenarbeit mit den
            Vertriebspartnern BACHL und ISOVER gesammelt und so aufbereitet,
            dass das entstehende Regranulat wieder dem Produktionskreislauf
            zugeführt werden kann. Hierdurch werden Abfälle vermieden und
            fossile Ressourcen geschont.
          </p>
        </div>
      </div>
      <div className="mt-4 content-zero" id="content">
        <div className="d-flex justify-content-center">
          <h4 className="fw-bold">mcyclo in wenigen Minuten erklärt</h4>
        </div>
      </div>
      <div className="video-section">
        <video
          controls
          controlsList="nodownload"
          //autoPlay
          width="100%"
          height="100%"
        >
          <source
            src="assets/videos/2023-03-10-BASF-mcyclo_Video_V8.mp4"
            type="video/mp4"
          />
        </video>
      </div>

      <hr className="hr" />
      <div className="content-one" id="content">
        <div className="d-flex justify-content-center">
          <h4 className="fw-bold">{str1.join(" ")}</h4>
          <div className="ms-2">
            <h4 id="Handyman" className="fw-bold mb-0">
              {lastWord1}
            </h4>
            <div
              style={{
                width: "100% !important",
                height: "5px",
                backgroundColor: "#F4B43E",
                borderRadius: "10px",
              }}
            />
          </div>
        </div>
        <div className="content-p">
          <p className="text-center">{t("content-one-p")}</p>
        </div>
      </div>
      <div className="content-two">
        {steps.map((el, index) => {
          return (
            <div className="position-relative main-div">
              <Row key={index}>
                <Col lg={6} sm={12} className="col-left position-relative py-0">
                  <div
                    key={index}
                    className="step position-absolute d-lg-block d-none"
                  >
                    <div
                      className="circle position-absolute"
                      style={{
                        top: "6rem",
                      }}
                    />
                    <div className="bar" />
                  </div>
                  <div className="ms-5">
                    <p className="fw-bold mt-5">{el.heading}</p>
                    {el.paragraphs.map((elem, index) => {
                      return <p style={{ color: "#000000" }}>{elem}</p>;
                    })}
                    <p className="info">{el.info}</p>
                  </div>
                  <div className="arrow-right" />
                </Col>
                <Col lg={6} sm={12} className="col-right">
                  {el.images.map((elem, index) => {
                    return <img src={elem} className="me-4 step-img1" />;
                  })}
                </Col>
              </Row>
            </div>
          );
        })}
        <div className="d-flex align-items-center py-5 px-4 final-step">
          <div className="position-relative">
            <img src={"/assets/icons/round.png"} />
            <img
              src={"/assets/icons/tick.png"}
              className="position-absolute"
              style={{ left: "1rem" }}
            />
          </div>
          <h4
            style={{
              marginLeft: "4rem",
              fontWeight: "bold",
            }}
          >
            {t("content-footer-one")}
          </h4>
        </div>
      </div>
      <div className="content-one" style={{ margin: "4rem 0" }}>
        <div className="d-flex">
          <h4 className="fw-bold">{str2.join(" ")}</h4>
          <div className="ms-2">
            <h4 id="builders_merchant" className="fw-bold mb-0">
              {lastWord2}
            </h4>
            <div
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#F4B43E",
                borderRadius: "10px",
              }}
            />
          </div>
        </div>
        <div className="content-p">
          <p className="text-center">{t("content-two-p")}</p>
        </div>
      </div>
      <div className="content-two">
        {steps2.map((el, index) => {
          return (
            <div className="position-relative main-div">
              <Row key={index}>
                <Col lg={6} sm={12} className="col-left position-relative py-0">
                  <div
                    key={index}
                    className="step position-absolute d-lg-block d-none"
                  >
                    <div
                      className="circle position-absolute"
                      style={{
                        top: "6rem",
                      }}
                    />
                    <div className="bar" />
                  </div>
                  <div className="ms-5">
                    <p className="fw-bold mt-5">{el.heading}</p>
                    {el.paragraphs.map((elem, index) => {
                      return <p style={{ color: "#000000" }}>{elem}</p>;
                    })}
                    <p className="info">{el.info}</p>
                  </div>
                  <div className="arrow-right" />
                </Col>
                <Col lg={6} sm={12} className="col-right ">
                  {el.images.map((elem, index) => {
                    return <img src={elem} className="me-4 step-img2" />;
                  })}
                </Col>
              </Row>
            </div>
          );
        })}
        <div className="d-flex align-items-center py-5 px-4 final-step">
          <div className="position-relative">
            <img src={"/assets/icons/round.png"} />
            <img
              src={"/assets/icons/tick.png"}
              className="position-absolute"
              style={{ left: "1rem" }}
            />
          </div>
          <h4
            style={{
              marginLeft: "4rem",
              fontWeight: "bold",
            }}
          >
            {t("content-footer-two")}
          </h4>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
