import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const PrivateRoute = ({ children, role, path }) => {
  let location = useLocation();
  const isAuthenticated = localStorage.getItem("token");
  role = localStorage.getItem("role");
  const roles = ["Distributor", "Construction Site", "Logistic"];
  const userHasRequiredRole = role && roles.includes(role) ? true : false;

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />;
  } else if (isAuthenticated && !userHasRequiredRole) {
    return <Navigate to="/" state={{ from: location }} />; // build your won access denied page (sth like 404)
  } else if (role === "Construction Site" && path === "/profile") {
    return children;
  } else if (role === "Distributor" && path === "/collection") {
    return children;
  } else if (role === "Logistic" && path === "/jobs") {
    return children;
  }
  return null;
};
PrivateRoute.propTypes = {
  children: PropTypes.any,
  role: PropTypes.any,
  path: PropTypes.any,
};
export default PrivateRoute;
