import React from "react";
import LoginCard from "../../components/LoginCard";
import "./loginPage.scss";

const LogIn = () => {
  return (
    <div className="login-page">
        <img
          src="assets/icons/mcyclo-logo.png"
          alt="brand"
        />
      <LoginCard />
    </div>
  );
};

export default LogIn;
